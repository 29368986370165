import React, { useContext, useEffect, useState } from "react";
import "../Css/Login.css";
import { global } from "../../Context/Context";
import mastiLive_logo from "../../assets/img/mastiLive_logo.png";
import { Link } from 'react-router-dom';
const BdLogin = () => {
  const { bdPanel } = useContext(global);

  const [credentials, setCredentials] = useState({
    email: "",
    password: "",
  });

  useEffect(() => {
    if (sessionStorage.getItem("bd")) window.location.href = "/bd/dashboard";
  }, []);
  const handleInputChange = (e) => {
    setCredentials({...credentials,[e.target.name]: e.target.value});
  };

  return (
    <>
      <div className="login-box">
        <div className="login-logo">
          <a href>
            <img style={{ width: "180px" }} src={mastiLive_logo} alt="mastiLive_logo" />
          </a>
        </div>
        <div className="login-box-body">
          <p className="login-box-msg">Sign in to Enter your BD panel</p>
          <div className="form-error"></div>
          <form
            action=""
            method=""
            onSubmit={(e) => {
              e.preventDefault();
              // console.log(credentials)
              bdPanel(credentials);
              // toast.success('testing')
            }}
          >
            <div className="form-group has-feedback">
              <input
                type="text"
                name="email"
                className="form-control"
                placeholder="UniqueId"
                value={credentials.email}
                onChange={handleInputChange}
              />
              <span className="glyphicon glyphicon-phone form-control-feedback" />
              <div className="form-error1" />
            </div>
            <div className="form-group has-feedback">
              <input
                type="password"
                name="password"
                className="form-control"
                placeholder="Password"
                value={credentials.password}
                onChange={handleInputChange}
              />
              <span className="glyphicon glyphicon-lock form-control-feedback" />
              <div className="form-error1" />
            </div>
            <div className="row">
              <div className="col-xs-8">
                <label>
                  <input type="checkbox" /> Remember Me
                </label>
              </div>

              <div className="col-xs-4">
                <button
                  type="submit"
                  className="btn btn-primary btn-block btn-flat"
                >
                  Sign In
                </button>
              </div>
              <div className="col-xs-8">
              <Link to="/bd/otpsent">Forget Password</Link>
              </div>
            </div>
          </form>
        </div>
      </div>
    </>
  );
};

export default BdLogin;
