import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";

const FamilyWalletWithdrawalBD = () => {
    const currentYear = new Date().getFullYear();
    const currentMonth = new Date().getMonth() + 1; // JavaScript months are 0-based

    const [month, setMonth] = useState(() => localStorage.getItem("selectedMonth") || currentMonth);
    const [year, setYear] = useState(() => localStorage.getItem("selectedYear") || currentYear);
    const [withdrawalData, setWithdrawalData] = useState(null);
    const [loading, setLoading] = useState(false);
    let navigate = useNavigate();

    const fetchData = async () => {
        setLoading(true);
        try {
            const agency = sessionStorage.getItem("bd");
            if (!agency) {
                navigate("/bd");
            }

            const newAgency = JSON.parse(agency);
            let id = newAgency.uniqueId;
            const bdCode = id;
            const response = await fetch(
                `${process.env.REACT_APP_BASEURL5}/admin/withdrawal/familyWalletBD?month=${(month%12 + 1)}&year=${year}&bd=${bdCode}`
            );
            const data = await response.json();
            setWithdrawalData(data);
        } catch (error) {
            console.error("Error fetching data", error);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        fetchData();
    }, [month, year]);

    const handleMonthChange = (e) => {
        setMonth(e.target.value);
        localStorage.setItem("selectedMonth", e.target.value);
    };

    const handleYearChange = (e) => {
        setYear(e.target.value);
        localStorage.setItem("selectedYear", e.target.value);
    };

    const getMonthRange = (m) => {
        const startMonth = new Date(0, m - 1).toLocaleString("default", { month: "short" });
        const nextMonth = new Date(0, m).toLocaleString("default", { month: "short" });
        return `${startMonth} - 5 ${nextMonth}`;
    };

    const totalAmount = withdrawalData?.totalAmount || 0;

    return (
        <div>
            <section className="content-header">
                <h1>Family Wallet Withdrawal (BD)</h1>
            </section>

            <section className="content">
                <div className="row">
                    <div className="col-xs-12">
                        <div className="box">
                            <div className="box-body">
                                <div style={{ marginBottom: "20px", display: "flex", gap: "10px" }}>
                                    <div>
                                        <label htmlFor="month">Month:</label>
                                        <select id="month" value={month} onChange={handleMonthChange}>
                                            {Array.from({ length: 12 }, (_, m) => (
                                                <option key={m + 1} value={m + 1}>
                                                    {getMonthRange(m + 1)}
                                                </option>
                                            ))}
                                        </select>
                                    </div>
                                    <div>
                                        <label htmlFor="year">Year:</label>
                                        <select id="year" value={year} onChange={handleYearChange}>
                                            {[...Array(5).keys()].map((y) => (
                                                <option key={currentYear - y} value={currentYear - y}>
                                                    {currentYear - y}
                                                </option>
                                            ))}
                                        </select>
                                    </div>
                                </div>

                                {loading ? (
                                    <p>Loading...</p>
                                ) : (
                                    <>
                                        <table className="table table-bordered table-striped">
                                            <thead>
                                                <tr>
                                                    <th>SNo</th>
                                                    <th>Date Time</th>
                                                    <th>Img</th>
                                                    <th>Username</th>
                                                    <th>Number</th>
                                                    <th>Family Img</th>
                                                    <th>Family Name</th>
                                                    <th>INR</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {withdrawalData?.data?.map((entry, index) => (
                                                    <tr key={entry._id}>
                                                        <td>{index + 1}</td>
                                                        <td>{new Date(entry.createdAt).toLocaleString()}</td>
                                                        <td>
                                                            <img
                                                                src={`${process.env.REACT_APP_BASEURL1}${entry.picture}`}
                                                                alt={entry.username}
                                                                style={{ width: "30px", height: "30px", borderRadius: "50%" }}
                                                            />
                                                        </td>
                                                        <td>{entry.username}</td>
                                                        <td>{entry.number}</td>
                                                        <td>
                                                            <img
                                                                src={`${process.env.REACT_APP_BASEURL1}${entry.familyImage}`}
                                                                alt={entry.familyName}
                                                                style={{ width: "30px", height: "30px", borderRadius: "50%" }}
                                                            />
                                                        </td>
                                                        <td>{entry.familyName}</td>
                                                        <td>{entry.amount}</td>
                                                    </tr>
                                                ))}
                                                {/* Total Amount Row */}
                                                <tr style={{ fontWeight: "bold" }}>
                                                    <td>Total</td>
                                                    <td colSpan={6} style={{ textAlign: "right" }}></td>
                                                    <td>{totalAmount}</td>
                                                    <td></td>
                                                    <td></td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </>
                                )}
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    );
};

export default FamilyWalletWithdrawalBD;
