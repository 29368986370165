import React, { useEffect, useState } from "react";
// import "bootstrap/dist/css/bootstrap.min.css";

const PricingPlans = () => {
  const [plans, setPlans] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    fetch(`${process.env.REACT_APP_BASEURL4}/admin/plan/getPlan`) // Replace with actual API endpoint
      .then((response) => response.json())
      .then((data) => {
        if (data.success) {
          setPlans(data.plans);
        }
      })
      .catch((error) => console.error("Error fetching plans:", error))
      .finally(() => setLoading(false));
  }, []);

  return (
    <div className="container py-5 bg-white">
      <h1 className="text-center mb-4" style={{fontWeight:"bolder"}}>Welcome to the MASTISHORTS TECHNOLOGY PRIVATE LIMITED</h1>
      <h2 className="text-center mb-4">Our Plans And Pricing</h2>
      <h4 className="text-center mb-4">To access the plan 
        <a target="_blank" href="https://play.google.com/store/apps/details?id=com.mastiindia&pcampaignid=web_share"> Click here </a> to install app.
      </h4>
      {loading ? (
        <p className="text-center">Loading...</p>
      ) : (
        <div className="row">
          {plans.map((plan,index) => (
            <div key={plan._id} className="col-md-4  column-gap-3" style={{marginBottom:"12px"}}>
              <div className="border rounded-3 shadow-sm text-center bg-info" style={{padding:"8px", border:"2px solid black", borderRadius:"20px",cursor:"pointer"}}>
                <h2 className="">For ₹{plan.rs}</h2>
                <h3 className="">Get {plan.coins} coins</h3>
                {/* <button className="btn btn-primary w-100">Buy Now</button> */}
              </div>
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default PricingPlans;
