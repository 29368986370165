import React, { useState, useEffect } from "react";
import axios from "axios";
import axiosInstance from '../../config/axiosInterceptor'

const GamesData = () => {
    const [type, setType] = useState(undefined); // Type: 0, 1, or undefined
    const [transactions, setTransactions] = useState([]);
    const [totalAmount, setTotalAmount] = useState(0);
    const [page, setPage] = useState(1);
    const [limit, setLimit] = useState(10); // Default limit value
    const [loading, setLoading] = useState(false);
    const [totalPages, setTotalPages] = useState(0);

    // Date filters
    const today = new Date().toISOString().split("T")[0]; // YYYY-MM-DD
    const [startDate, setStartDate] = useState(today);
    const [endDate, setEndDate] = useState(today);

    const fetchTransactions = async () => {
        setLoading(true);
        try {
            const response = await axiosInstance.get(`${process.env.REACT_APP_BASEURL5}/admin/withdrawal/gamesData`, {
                params: {
                    type,
                    page,
                    limit, // Sending the selected limit to backend
                    startDate,
                    endDate,
                },
            });
            const { data, totalAmount, pagination } = response.data;
            setTransactions(data);
            setTotalAmount(totalAmount);
            setTotalPages(Math.ceil(pagination.total / limit));
        } catch (error) {
            console.error("Error fetching transactions:", error);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        fetchTransactions();
    }, [type, page, startDate, endDate, limit]); // Adding limit to dependency array

    const handleLimitChange = (e) => {
        setLimit(Number(e.target.value)); // Update the limit when the dropdown value changes
        setPage(1); // Reset to the first page when limit changes
    };

    return (
        <div className="container mt-4">
            {/* Filters */}
            <div className="mb-4">
                <div className="d-flex justify-content-start mb-3">
                    <button
                        onClick={() => setType("0")}
                        className={`btn ${type === "0" ? "btn-primary" : "btn-secondary"} mx-2`}
                    >
                        Bet
                    </button>
                    <button
                        onClick={() => setType("1")}
                        className={`btn ${type === "1" ? "btn-primary" : "btn-secondary"} mx-2`}
                    >
                        Result
                    </button>
                    <button
                        onClick={() => setType(undefined)}
                        className={`btn ${type === undefined ? "btn-primary" : "btn-secondary"} mx-2`}
                    >
                        All
                    </button>
                </div>

                <div className="d-flex justify-content-start mb-4">
                    <div className="form-group mx-2">
                        <label className="form-label" htmlFor="startDate">Start Date</label>
                        <input
                            type="date"
                            id="startDate"
                            value={startDate}
                            onChange={(e) => setStartDate(e.target.value)}
                            className="form-control"
                        />
                    </div>
                    <div className="form-group mx-2">
                        <label className="form-label" htmlFor="endDate">End Date</label>
                        <input
                            type="date"
                            id="endDate"
                            value={endDate}
                            onChange={(e) => setEndDate(e.target.value)}
                            className="form-control"
                        />
                    </div>
                    <div className="form-group mx-2">
                        <label className="form-label" htmlFor="limit">Entries per page</label>
                        <select
                            id="limit"
                            value={limit}
                            onChange={handleLimitChange}
                            className="form-control"
                        >
                            <option value={10}>10</option>
                            <option value={50}>50</option>
                            <option value={100}>100</option>
                            <option value={500}>500</option>
                        </select>
                    </div>
                </div>
            </div>
            {/* Pagination */}
            <div className="d-flex justify-content-center mt-4">
                <button
                    disabled={page === 1}
                    onClick={() => setPage((prev) => prev - 1)}
                    className="btn btn-secondary mx-2"
                >
                    Previous
                </button>
                <span className="align-self-center">{`Page ${page} of ${totalPages}`}</span>
                <button
                    disabled={page === totalPages}
                    onClick={() => setPage((prev) => prev + 1)}
                    className="btn btn-secondary mx-2"
                >
                    Next
                </button>
            </div>
            {/* Transactions Table */}
            {loading ? (
                <div className="text-center"><p>Loading...</p></div>
            ) : (
                <div>
                    <h2 className="h5 font-weight-bold mb-2">Total Amount: {totalAmount}</h2>
                    <table className="table table-bordered">
                        <thead className="thead-light">
                            <tr>
                                <th className="text-center">SNo</th>
                                <th>Date</th>
                                <th>Type</th>
                                <th>UserName</th>
                                <th>Amount</th>
                                <th>Av. Balance</th>
                            </tr>
                        </thead>
                        <tbody>
                            {transactions?.map((transaction, index) => (
                                <tr key={transaction._id}>
                                    <td className="text-center">{(page - 1) * limit + index + 1}</td>
                                    <td>{new Date(transaction.createdAt).toLocaleString()}</td>
                                    <td>{transaction.coinPanel}</td>
                                    <td>{transaction?.userId?.username}</td>
                                    <td>{transaction.amount}</td>
                                    <td>{transaction.availabeBalance}</td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
            )}


        </div>
    );
};

export default GamesData;
