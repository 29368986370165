import React, { useContext, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { global } from "../../Context/Context";
import axiosInstance from "../../config/axiosInterceptor";
import { toast } from "react-toastify";

const AgencyRanking = () => {
  const { users, user } = useContext(global);
  const [currentPage, setCurrentPage] = useState(1);
  const [entriesPerPage, setEntriesPerPage] = useState(5);
  const [withdrawalList, setWithdrawalLists] = useState([]);
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [loading, setLoading] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const [totalPages, setTotalPages] = useState(1);

  // Format Date
  const formatDate = (date) => date.toISOString().split("T")[0];

  useEffect(() => {
    const currentDate = new Date();
    const lastMonday = new Date(currentDate);
    lastMonday.setDate(currentDate.getDate() - ((currentDate.getDay() + 6) % 7));

    if (!startDate) setStartDate(formatDate(lastMonday));
    if (!endDate) setEndDate(formatDate(currentDate));
  }, []);

  const getBdLists = async (page = 1, search = "", limit = entriesPerPage) => {
    try {
      setLoading(true);
      const { data } = await axiosInstance.get(
        `${process.env.REACT_APP_BASEURL5}/admin/withdrawal/agencyRanking?start=${startDate}&end=${endDate}&page=${page}&limit=${limit}&search=${search}`
      );

      if (data.success === 1) {
        setWithdrawalLists(data.details);
        setTotalPages(data.totalPages);
        setCurrentPage(page);
      } else {
        toast.error("No data found.");
      }
    } catch (error) {
      console.error(error);
      toast.error("Error fetching data.");
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getBdLists(currentPage, searchTerm, entriesPerPage);
  }, [startDate, endDate, entriesPerPage]);

  const handlePageChange = (newPage) => {
    if (newPage >= 1 && newPage <= totalPages) {
      getBdLists(newPage, searchTerm, entriesPerPage);
    }
  };

  return (
    <>
      <section className="content-header">
        <h1>Agency Ranking</h1>
        <ol className="breadcrumb">
          <li>
            <Link to="/admin">
              <i className="fa fa-dashboard"></i> Home
            </Link>
          </li>
        </ol>
      </section>

      <section className="content">
        <div className="row">
          <div className="col-xs-12">
            <div className="box">
              <div className="box-body">
                <div className="row" style={{ marginBottom: "20px" }}>
                  <div className="col-md-3">
                    <label>Start Date:</label>
                    <input
                      type="date"
                      className="form-control"
                      value={startDate}
                      onChange={(e) => setStartDate(e.target.value)}
                    />
                  </div>
                  <div className="col-md-3">
                    <label>End Date:</label>
                    <input
                      type="date"
                      className="form-control"
                      value={endDate}
                      onChange={(e) => setEndDate(e.target.value)}
                    />
                  </div>
                  <div className="col-md-3">
                    <label>Search by Agency ID:</label>
                    <div className="input-group">
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Enter Agency ID"
                        value={searchTerm}
                        onChange={(e) => setSearchTerm(e.target.value)}
                      />
                      <span className="input-group-btn">
                        <button
                          className="btn btn-primary"
                          onClick={() => getBdLists(1, searchTerm, entriesPerPage)}
                        >
                          Go
                        </button>
                      </span>
                    </div>
                  </div>
                  <div className="col-md-3">
                    <label>Entries per page:</label>
                    <select
                      className="form-control"
                      value={entriesPerPage}
                      onChange={(e) => {
                        setEntriesPerPage(Number(e.target.value));
                        getBdLists(1, searchTerm, Number(e.target.value));
                      }}
                    >
                      <option value="5">5</option>
                      <option value="10">10</option>
                      <option value="25">25</option>
                      <option value="50">50</option>
                    </select>
                  </div>
                </div>

                <table className="table table-bordered table-striped">
                  <thead>
                    <tr>
                      <th>Rank</th>
                      <th>Image</th>
                      <th>Agency ID</th>
                      <th>Agency Name</th>
                      <th>BD Name</th>
                      <th>Admin Name</th>
                      <th>Total Coins</th>
                    </tr>
                  </thead>
                  <tbody>
                    {loading ? (
                      <tr>
                        <td colSpan={7} className="text-center">
                          Loading...
                        </td>
                      </tr>
                    ) : withdrawalList.length > 0 ? (
                      withdrawalList.map((item, index) => (
                        <tr key={index}>
                          <td>{index + 1 + (currentPage - 1) * entriesPerPage}</td>
                          <td>
                            <img
                              src={item?.image}
                              style={{ width: "30px", height: "30px", borderRadius: "50%" }}
                              alt="user"
                            />
                          </td>
                          <td>{item?.uniqueId}</td>
                          <td>{item?.name}</td>
                          <td>{item?.bd}</td>
                          <td>{item?.admin}</td>
                          <td>{item?.totalCoins}</td>
                        </tr>
                      ))
                    ) : (
                      <tr>
                        <td colSpan={7} className="text-center">
                          No records found.
                        </td>
                      </tr>
                    )}
                  </tbody>
                </table>

                {/* Pagination */}
                <div className="pagination-container">
                  <ul className="pagination">
                    <li className={currentPage === 1 ? "disabled" : ""}>
                      <button onClick={() => handlePageChange(currentPage - 1)}>Previous</button>
                    </li>
                    {Array.from({ length: totalPages }, (_, i) => (
                      <li key={i} className={currentPage === i + 1 ? "active" : ""}>
                        <button onClick={() => handlePageChange(i + 1)}>{i + 1}</button>
                      </li>
                    ))}
                    <li className={currentPage === totalPages ? "disabled" : ""}>
                      <button onClick={() => handlePageChange(currentPage + 1)}>Next</button>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default AgencyRanking;
