import React, { useState, useEffect } from "react";

const HomePage = () => {
  const [menuOpen, setMenuOpen] = useState(false);
  const [isMobileOrTablet, setIsMobileOrTablet] = useState(false);

  const toggleMenu = () => {
    setMenuOpen(!menuOpen);
  };

  // Detect screen size change
  const handleResize = () => {
    setIsMobileOrTablet(window.innerWidth <= 768); // You can adjust the value based on your needs
  };

  useEffect(() => {
    // Initial check for screen size
    handleResize();
    
    // Listen to resize event
    window.addEventListener("resize", handleResize);

    // Cleanup the event listener on component unmount
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const styles = {
    body: {
      backgroundColor: "#f8f9fa",
      fontFamily: "Arial, sans-serif",
      margin: 0,
      padding: 0,
    },
    navbar: {
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
      padding: "10px 20px",
      backgroundColor: "#f8f9fa",
      borderBottom: "1px solid #ddd",
      position: "relative",
    },
    navLinks: {
      listStyle: "none",
      display: "flex",
      gap: "15px",
      margin: 0,
      padding: 0,
    },
    navItem: {
      textDecoration: "none",
      color: "#007bff",
      fontWeight: "bold",
    },
    hamburger: {
      display: isMobileOrTablet ? "flex" : "none", // Show hamburger only on mobile/tablet
      flexDirection: "column",
      cursor: "pointer",
      padding: "10px",
    },
    bar: {
      backgroundColor: "#333",
      height: "3px",
      width: "25px",
      margin: "4px 0",
    },
    mobileMenu: {
      display: menuOpen ? "block" : "none",
      position: "absolute",
      top: "100%",
      left: "0",
      width: "100%",
      backgroundColor: "#fff",
      boxShadow: "0px 4px 6px rgba(0,0,0,0.1)",
      padding: "10px 0",
      textAlign: "center",
      zIndex: 10,
    },
    mobileNavItem: {
      padding: "10px",
      borderBottom: "1px solid #ddd",
    },
    container: {
      textAlign: "center",
      padding: "40px 20px",
    },
    heading: {
      fontSize: "28px",
      fontWeight: "bold",
      marginBottom: "20px",
      color: "#000",
    },
    paragraph: {
      fontSize: "16px",
      marginBottom: "30px",
      color: "#333",
    },
    row: {
      display: "flex",
      justifyContent: "center",
      gap: "20px",
      flexWrap: "wrap",
    },
    card: {
      width: "250px",
      padding: "20px",
      borderRadius: "15px",
      boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
      textAlign: "center",
      backgroundColor: "#fff",
    },
    cardTitle: {
      color: "#007bff",
      fontSize: "18px",
      marginBottom: "10px",
    },
    button: {
      backgroundColor: "#007bff",
      color: "#fff",
      padding: "10px 15px",
      border: "none",
      borderRadius: "5px",
      textDecoration: "none",
      display: "inline-block",
      fontWeight: "bold",
      cursor: "pointer",
    },
    footer: {
      backgroundColor: "#343a40",
      color: "white",
      padding: "20px",
      textAlign: "center",
      marginTop: "40px",
    },
    desktopNav: {
      display: "flex",
    },
    mobileNav: {
      display: "none",
    },
  };

  return (
    <div style={styles.body}>
      <nav style={styles.navbar}>
        <a href="#" style={{ fontSize: "20px", fontWeight: "bold"}}>
          MastiShorts
        </a>

        {/* Hamburger Icon for Mobile */}
        <div
          style={{ ...styles.hamburger, display: isMobileOrTablet ? "flex" : "none" }}
          onClick={toggleMenu}
        >
          <div style={styles.bar}></div>
          <div style={styles.bar}></div>
          <div style={styles.bar}></div>
        </div>

        {/* Desktop Navigation */}
        {!isMobileOrTablet && <ul style={{ ...styles.navLinks, ...styles.desktopNav }}>
          <li>
            <a
              href="https://mastishortss.com/pricing"
              target="_blank"
              rel="noopener noreferrer"
              style={styles.navItem}
            >
              Pricing
            </a>
          </li>
          <li>
            <a
              href="https://mastishortss.com/api/termsandconditions"
              target="_blank"
              rel="noopener noreferrer"
              style={styles.navItem}
            >
              Terms And Conditions
            </a>
          </li>
          <li>
            <a
              href="https://mastishortss.com/api/privacypolicy"
              target="_blank"
              rel="noopener noreferrer"
              style={styles.navItem}
            >
              Privacy Policy
            </a>
          </li>
          <li>
            <a
              href="https://mastishortss.com/api/cancellationandrefund"
              target="_blank"
              rel="noopener noreferrer"
              style={styles.navItem}
            >
              Refund Policy
            </a>
          </li>
          <li>
            <a
              href="https://mastishortss.com/api/contactus"
              target="_blank"
              rel="noopener noreferrer"
              style={styles.navItem}
            >
              Contact Us
            </a>
          </li>
        </ul>}

        {/* Mobile Menu */}
      {isMobileOrTablet &&  <ul style={styles.mobileMenu}>
          <li style={styles.mobileNavItem}>
            <a
              href="https://mastishortss.com/pricing"
              target="_blank"
              rel="noopener noreferrer"
              style={styles.navItem}
            >
              Pricing
            </a>
          </li>
          <li style={styles.mobileNavItem}>
            <a
              href="https://mastishortss.com/api/termsandconditions"
              target="_blank"
              rel="noopener noreferrer"
              style={styles.navItem}
            >
              Terms & Conditions
            </a>
          </li>
          <li style={styles.mobileNavItem}>
            <a
              href="https://mastishortss.com/api/privacypolicy"
              target="_blank"
              rel="noopener noreferrer"
              style={styles.navItem}
            >
              Privacy Policy
            </a>
          </li>
          <li style={styles.mobileNavItem}>
            <a
              href="https://mastishortss.com/api/cancellationandrefund"
              target="_blank"
              rel="noopener noreferrer"
              style={styles.navItem}
            >
              Refund Policy
            </a>
          </li>
          <li style={styles.mobileNavItem}>
            <a
              href="https://mastishortss.com/api/contactus"
              target="_blank"
              rel="noopener noreferrer"
              style={styles.navItem}
            >
              Contact Us
            </a>
          </li>
        </ul>}
      </nav>

      <div style={styles.container}>
        <h2 style={styles.heading}>
          Welcome to the MASTISHORTS TECHNOLOGY PRIVATE LIMITED
        </h2>
        <p style={styles.paragraph}>
          Mastii application is an Indian application. Mastii application has
          brought great features and policies for all broadcasters. Here you can
          earn a lot of money by doing live stream and also by playing games.
        </p>
        <div style={styles.row}>
          {[{ title: "Bd Panel", link: `${window.location.href}bd` }, { title: "Agency Admin Panel", link: `${window.location.href}agencyadmin` }, { title: "Agency Panel", link:`${window.location.href}agency` }, { title: "Coin Panel", link: `${window.location.href}coin` }].map((panel, index) => (
            <div key={index} style={styles.card}>
              <h5 style={styles.cardTitle}>{panel.title}</h5>
              <a href={panel.link} target="_blank" rel="noopener noreferrer" style={styles.button}>Go to {panel.title}</a>
            </div>
          ))}
        </div>
      </div>

      <footer style={styles.footer}>
        <p>© 2024-25 Your Company. All rights reserved.</p>
      </footer>
    </div>
  );
};

export default HomePage;
