import { useEffect, useState } from "react";
import axiosInstance from "../config/axiosInterceptor";
import { toast } from "react-toastify";

function LiveTimeUser() {
    const [data , setData] = useState([]);
    const [page, setPage] = useState(1);
    const [limit, setLimit] = useState(10);
    const [totalPages , setTotalPages] = useState(10);
    const fetchData = async (page , limit)=>{
        axiosInstance.get(`${process.env.REACT_APP_BASEURL5}/admin/livetime/livetimeUser`,{
            params:{
                page,
                limit,
            }
        }).then((res)=>{
            if(res.data.success === 1){
                toast.success(res.data.message)
                setData(res.data.userData)
                console.log(data)
            }else{
                toast.error(res.data.message)
            }
        }).catch((e)=>console.log(e.message))
    }
    useEffect(()=>{
        fetchData(page,limit)
    },[page , limit])

    const delLiveTimeUser = (id)=>{
        axiosInstance.delete(`${process.env.REACT_APP_BASEURL5}/admin/livetime/delLivetimeUser?id=${id}`).then((res)=>{
            if(res.data.success == 1){
                toast.success(res.data.message)
            }
        }).catch((e)=> toast.error(e.message))
    }
    const handlePrev = () => {
        if (page > 1) setPage(page - 1);
    };

    const handleNext = () => {
        if (page < totalPages) setPage(page + 1);
    };
    return ( 
        <div className="container">
            <table id="example1" className="table table-striped adHs-cont">
                <thead>
                <tr>
                    <th>no.</th>
                    <th>username</th>
                    <th>Coin Amount</th>
                    <th>Coin Time</th>
                    <th>Updated Time</th>
                </tr>
                </thead>
                {data?.map((data,idx)=>(
                    <tr key={data._id}>
                        <td>{(page - 1) * limit + idx + 1}</td>
                        <td>{data.userId.username}</td>
                        <td>{data.coinAmount}</td>
                        <td>{data.coinTime}</td>
                        <td>{new Date(data.updatedAt).toLocaleString()}</td>
                        <button 
                        onClick={()=>
                        {
                            const res = window.confirm("Are You sure you want to delete ?")
                            if (res == true){
                                delLiveTimeUser(data._id)
                            }
                        }
                        }
                            >Delete</button>
                    </tr>
                ))}
            </table>
            <div className="pagination">
                  <button
                    className="btn btn-default"
                    disabled={page === 1}
                    onClick={handlePrev}
                  >
                    Prev
                  </button>
                  <button
                    className="btn btn-default"
                    disabled={page === totalPages}
                    onClick={handleNext}
                  >
                    Next
                  </button>
                </div>
        </div>
    );
}

export default LiveTimeUser;