import React, { useContext, useEffect, useState , useRef } from 'react'
import "../Css/Login.css"
import { global } from '../../Context/Context'
import mastiLive_logo from '../../assets/img/mastiLive_logo.png'
import axiosInstance from '../../config/axiosInterceptor'
import { toast } from 'react-toastify'

// import {toast} from 'react-toastify'


const Login = ({ length = 4}) => {

  const {login} = useContext(global)
  const [isFilled ,  setIsFilled] = useState(false);
  const [otp, setOtp] = useState(new Array(length).fill(""));
  const inputRefs = useRef([]);
  
  const [credentials ,setCredentials] = useState({
    number : '',
    password : '',
    otp:"",
  })

  useEffect(()=>{
    if(inputRefs.current[0]){
      inputRefs.current[0].focus();
    }
    if (credentials.otp) {
      login(credentials);
    }
  },[credentials]);

//  useEffect(()=>{
//   if(sessionStorage.getItem('data'))
//       window.location.href='/admin/dashboard';
//  },[]);
  const handleInputChange = (e)=>{
    setCredentials({
      ...credentials , 
      [e.target.name ] : e.target.value
    })
  }

  // otp input functions 

  const handleChange = async (index, e) => {
    const value = e.target.value;
    if(isNaN(value)) return;

    const newOtp = [...otp];
    //allow only one inp 

    newOtp[index] = value.substring(value.length-1);
    setOtp(newOtp);

    //submit trigger 

    const combineOtp = newOtp.join("");
    if (combineOtp.length === length){
      console.log(combineOtp);
      setCredentials(prev => ({
        ...prev,
        otp:combineOtp
      }))
    }


    if(value && index<length-1 && inputRefs.current[index+1]){
      inputRefs.current[index+1].focus();
    }
  };

  const handleClick = (index) => {
    inputRefs.current[index].setSelectionRange(1, 1);

    if(index>0 && !otp[index-1]){
      inputRefs.current[otp.indexOf("")].focus();
    }
  };

  const handleKeydown = (index,e) => {
    if(e.key === "Backspace" && !otp[index] && index>0 && inputRefs.current[index -1] ){
      inputRefs.current[index-1].focus();
    }
  };

  const handleFormSubmit = async(e)=>{
    e.preventDefault()
    await axiosInstance.post(`${process.env.REACT_APP_BASEURL5}/admin/adminOtp`,credentials).then((res)=>{
      if(res.data.success === 1){
                  toast.success(res.data.message);
                  if(res.data.upAdmin.access === "1"){
                    login(credentials);
                  }else{
                    setIsFilled(true)
                  }
                }else{
                  toast.error(res.data.message);
                }
    })
  };

  return (
    <>
      <div className="login-box">
        <div className="login-logo"> 
          <a href><img style={{width: '180px'}} src={mastiLive_logo} alt='mastiLive logo'/></a>
        </div>
        <div className="login-box-body">
          {!isFilled ? (<p className="login-box-msg">Sign in to Enter your Admin panel</p>):(<p>Enter Otp</p>)}
          <div className="form-error">
          </div>
          {!isFilled ? (
          <form action="" method="" onSubmit={
            handleFormSubmit
            // console.log(credentials);
            // toast.success('testing')
          }>
            <div className="form-group has-feedback">
              <input type="number" name="number" className="form-control" placeholder="Phone" value={credentials.phonenumber} onChange={handleInputChange}/>
              <span className="glyphicon glyphicon-phone form-control-feedback" />
              <div className="form-error1" />
            </div>
            <div className="form-group has-feedback">
              <input type="password" name="password" className="form-control" placeholder="Password" value={credentials.password} onChange={handleInputChange}/>
              <span className="glyphicon glyphicon-lock form-control-feedback" />
              <div className="form-error1" />
            </div>
            <div className="row">
              <div className="col-xs-8">
                <label>
                  <input type="checkbox" /> Remember Me
                </label>
              </div>
              
              <div className="col-xs-4">
                <button type="submit" className="btn btn-primary btn-block btn-flat">Sign In</button>
              </div>
              
            </div>
          </form>
            ):(
          otp.map((value, index) => {
          return (
          <input
            key={index}
              type="text"
              ref={(input)=> {
                if(input) inputRefs.current[index] = input;
              }}
            value={value}
            onChange={(e) => handleChange(index, e)}
            onClick={() => handleClick(index)}
            onKeyDown={(e) => handleKeydown(index, e)}
            className="otpInput"
          />
          );
          }
          ))}
        </div>
      </div>
    </>
  )
}

export default Login