import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import axios from "axios";

const HostRecord = () => {
  const [lists, setLists] = useState([]);
  const baseUrl = process.env.REACT_APP_BASEURL2;
  const agency = sessionStorage.getItem("agency");
  const newAgency = JSON.parse(agency);
  const id = newAgency.uniqueId;
  const sqlId = newAgency.sqlId;

  const getHostsData = async () => {
    try {
      const { data } = await axios.get(`${baseUrl}/agency/host/hostrecord?id=${id}&sqlId=${sqlId}`);
      setLists(data.details);
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    getHostsData();
  }, []);

  return (
    <>
      <section className="content-header">
        <h1>Host Record</h1>
        <ol className="breadcrumb">
          <li>
            <Link to="/admin">
              <i className="fa fa-dashboard"></i> Home
            </Link>
          </li>
          <li className="active">Host Record</li>
        </ol>
      </section>
      <section className="content">
        <div className="row">
          <div className="col-xs-12">
            <div className="box">
              <div className="box-body">
                <div className="row" style={{ padding: "16px 0px" }}></div>
                <table id="example1" className="table table-bordered table-striped">
                  <thead>
                    <tr>
                      <th>Sr.</th>
                      <th>Image</th>
                      <th>Username</th>
                      {/* <th>M.coins</th> */}
                      <th>Minutes</th>
                      {/* <th>Diamonds</th> */}
                      <th>Join Date</th>
                      <th>Record</th>
                    </tr>
                  </thead>
                  <tbody id="ts">
                    {lists.length === 0 ? (
                      <tr>
                        <td colSpan="7">No Record Found</td>
                      </tr>
                    ) : (
                      lists.map((host, i) => (
                        <tr key={host._id}>
                          <td>{i + 1}</td>
                          <td>
                            <img
                              src={`${process.env.REACT_APP_BASEURL}${host.image}`}
                              alt=""
                              style={{ height: "3rem", width: "3rem" }}
                            />
                          </td>
                          <td>{host?.userId?.username}</td>
                          {/* <td>{host?.userId?.recievingCoins}</td> */}
                          <td>{host?.totalTime!="NaNs" ?host?.totalTime: "0s"}</td>
                          {/* <td>{host.totaldiamond || 0}</td> */}
                          <td>{host.createDate}</td>
                          <td>
                            <span className={`label ${host.status === "archive" ? "label-info" : "label-success"}`}>
                              {host.status === "archive" ? "archive" : "live"}
                            </span>
                          </td>
                        </tr>
                      ))
                    )}
                  </tbody>
                </table>
                {/* Pagination links */}
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default HostRecord;
