import React, { useContext, useEffect, useState, useCallback } from "react";
import { Link } from "react-router-dom";
import { global } from "../../Context/Context";
import { toast } from "react-toastify";
import axios from "axios";
import _ from "lodash";
import axiosInstance from "../../config/axiosInterceptor";
var BaseUrl = process.env.REACT_APP_BASEURL3;
 
const AppHost = (props) => {
  const { aprrovedHost } = useContext(global);
  const [currentPage, setCurrentPage] = useState(1);
  const [entriesPerPage, setEntriesPerPage] = useState(10);
  const [totalPages, setTotalPages] = useState(1);
  const [currentUsers, setCurrentUsers] = useState([]);
  const [search, setSearch] = useState("");
  // Fetch hosts by status and page
  const indexOfLastUser = currentPage * entriesPerPage;
  const indexOfFirstUser = indexOfLastUser - entriesPerPage;
  const handleSearchChange = useCallback(
    _.debounce((value) => {
      setSearch(value);
      if (value != "")
        searhUser(value);
      else fetchHostsByStatus(props.status, currentPage)
    }, 1000),
    []
  );
  const fetchHostsByStatus = async (status, page) => {
    try {
      setCurrentUsers([])
      await axiosInstance.get(`${BaseUrl}/agency/host/gethost?status=${status}&page=${page}&limit=${entriesPerPage}`).then((res)=>{
        if(res.data.success === 1){
          toast.success(res.data.message)
          setTotalPages(res.data.pages);
          setCurrentUsers(res.data.details);
        }else {
          toast.error(res.data.message);
        }
      }).catch((e)=>toast.message(e.message))
    } catch (err) {
      toast.error(err.message);
    }
  };

  // useEffect(() => {
  //   fetchHostsByStatus(props.status, currentPage);
  // }, [props.status]);
  useEffect(() => {
    fetchHostsByStatus(props?.status, currentPage);
  }, [props?.status, entriesPerPage, currentPage]);

  // Logic to paginate the users

  // Change page
  const paginate = async (pageNumber) => {
    setCurrentPage(pageNumber);
    await fetchHostsByStatus(props?.status, pageNumber);
  };



  const searhUser = async (value) => {
    try {
      setCurrentUsers([])
      // setSearchData(filterValue);
      const { data } = await axios.get(
        `${process.env.REACT_APP_BASEURL3}/agency/host/gethostsearch?status=${props.status}&search=${value}`
      );
      // setSearchData(data.details);
      setCurrentUsers(data.details);
      setTotalPages(data.pages);

    } catch (error) {
      console.log(error.message);
    }
  };
  const [selectAll, setSelectAll] = useState(false);
  const [selectedItems, setSelectedItems] = useState([]);
  const toggleSelectAll = () => {
    if (selectAll) {
      setSelectedItems([]);
    } else {
      setSelectedItems(currentUsers.map(item => ({ _id: item._id })));
    }
    setSelectAll(!selectAll);
  };

  const handleCheckboxChange = (item) => {
    const isSelected = selectedItems.some(selected => selected._id === item._id);
    if (isSelected) {
      setSelectedItems(selectedItems.filter(selected => selected._id !== item._id));
    } else {
      setSelectedItems([...selectedItems, { _id: item._id}]);
    }
  };
  return (
    <>
      <section className="content-header">
        <h1>Host List</h1>
        <ol className="breadcrumb">
          <li>
            <Link to="/admin">
              <i className="fa fa-dashboard"></i> Home
            </Link>
          </li>
          <li className="active">Host List</li>
        </ol>
      </section>
      <section className="content">
        <div className="row">
          <div className="col-xs-12">
            <div className="box">
              <div className="box-body">
                <div className="row " style={{ padding: "16px 0px" }}>
                  <div className="entries-per-page col-md-6 ">
                    <label htmlFor="entries">Entries per page:</label>
                    <select
                      id="entries"
                      value={entriesPerPage}
                      onChange={(e) => {
                        setCurrentUsers([])
                        setEntriesPerPage(parseInt(e.target.value))
                      }
                      }
                    >
                      <option value="10">10</option>
                      <option value="50">50</option>
                      <option value="100">100</option>
                      <option value="500">500</option>
                    </select>
                  </div>
                  <div
                    class="col-md-5"
                    style={{ display: "flex", justifyContent: "center" }}
                  >
                    <div
                      class="main-data-single-field"
                      style={{ width: "fit-content" }}
                    >
                      <span style={{ fontWeight: "bold", marginRight: "8px" }}>
                        Search
                      </span>
                      <span>
                        <input
                          type="text"
                          id="pname"
                          name="pname"
                          style={{
                            borderRadius: "4px",
                            borderStyle: "groove",
                            width: "150px",
                          }}
                          onChange={(e) => {
                            handleSearchChange(e.target.value);
                          }}
                          placeholder="Searh User"
                        />
                      </span>
                    </div>
                  </div>
                </div>
                <div className="col-md-2">
                  <button className="btn btn-info" style={{ outline: "none" }} onClick={toggleSelectAll}>
                    {selectAll ? "Deselect All" : "Select All"}
                  </button>
                </div>
                <div>
                  {selectedItems.length > 0 && <button type="submit" className="btn btn-success" style={{ margin: "1rem" }} onClick={() => {
                    const conf = window.confirm("Are you Sure, You want to perform action to all!")
                    if (conf) {
                      // setIsLoading(true)
                      for (let i = 0; i < selectedItems.length; i++) {
                        aprrovedHost({id:selectedItems[i]._id, status:(props.status == 0 || props.status==2) ? "1" : "2"})
                      }
                      // setIsLoading(false)
                    }
                  }}>
                    {(props.status == 0 || props.status==2) ? "Approve" : "Reject"}
                  </button>}
                </div>
                <table
                  id="example1"
                  className="table table-bordered table-striped"
                >
                  <thead>
                    <tr>
                      <th>
                        <input
                          type="checkbox"
                          onChange={toggleSelectAll}
                          checked={selectAll}
                        />
                      </th>
                      <th>Sr.</th>
                      <th>Date</th>
                      <th>Username</th>
                      <th>Real name</th>
                      <th>Phone</th>
                      <th>Agency Code</th>
                      <th>Agency Name</th>
                      <th>Status</th>
                      <th>Action</th>
                    </tr>
                  </thead>
                  <tbody id="ts">
                    {currentUsers.length == 0 ? <>Loading...</> : Array.isArray(currentUsers) &&
                      currentUsers.map((item, index) => {
                        let i = index;
                        return (
                          <tr>
                            <td>
                              <input
                                type="checkbox"
                                checked={selectedItems.some(selected => selected._id === item._id)}
                                onChange={() => handleCheckboxChange(item)}
                              />
                            </td>
                            <td>{((totalPages - (currentPage - 1) * entriesPerPage) - i)}</td>
                            <td>{new Date(item?.createdAt).toLocaleString()}</td>
                            <td>{item?.username}</td>
                            <td>{item?.realName}</td>
                            <td>{item?.number}</td>
                            <td>{item?.agencyCode}</td>
                            <td>{item?.agencyName}</td>
                            <td>
                              {item.status == "0" ? (
                                <td>
                                  <span className="label label-info">
                                    Pending
                                  </span>
                                </td>
                              ) : item.status == 1 ? (
                                <td>
                                  <span className="label label-success">
                                    Approved
                                  </span>
                                </td>
                              ) : (
                                <td>
                                  <span className="label label-danger">
                                    Rejected
                                  </span>
                                </td>
                              )}
                            </td>
                            <td>
                              <ul
                                className="admin-action btn btn-default"
                                style={{
                                  backgroundColor: "#f4f4f4",
                                  color: "#fff !important",
                                }}
                              >
                                <li className="dropdown">
                                  <a
                                    className="dropdown-toggle"
                                    style={{ color: "black" }}
                                    data-toggle="dropdown"
                                    href="#"
                                    aria-expanded="false"
                                  >
                                    Action <span className="caret" />
                                  </a>
                                  <ul className="dropdown-menu dropdown-menu-right">
                                    {item?.status == 0 ? (
                                      <>
                                        <li
                                          role="presentation"
                                          onClick={() => {
                                            aprrovedHost({
                                              id: item._id,
                                              status: "1",
                                            });
                                          }}
                                        >
                                          <Link
                                            role="menuitem"
                                            tabIndex={-1}
                                            to=""
                                          >
                                            Approve
                                          </Link>
                                        </li>
                                        <li
                                          role="presentation"
                                          onClick={() => {
                                            aprrovedHost({
                                              id: item._id,
                                              status: "2",
                                            });
                                          }}
                                        >
                                          <Link
                                            role="menuitem"
                                            tabIndex={-1}
                                            to=""
                                          >
                                            Reject
                                          </Link>
                                        </li>
                                      </>
                                    ) : item?.status == 1 ? (
                                      <>
                                        <li
                                          role="presentation"
                                          onClick={() => {
                                            aprrovedHost({
                                              id: item._id,
                                              status: "2",
                                            });
                                          }}
                                        >
                                          <Link
                                            role="menuitem"
                                            tabIndex={-1}
                                            to=""
                                          >
                                            Reject
                                          </Link>
                                        </li>
                                      </>
                                    ) : (
                                      <>
                                        <li
                                          role="presentation"
                                          onClick={() => {
                                            aprrovedHost({
                                              id: item._id,
                                              status: "1",
                                            });
                                          }}
                                        >
                                          <Link
                                            role="menuitem"
                                            tabIndex={-1}
                                            to=""
                                          >
                                            Approve
                                          </Link>
                                        </li>
                                      </>
                                    )}
                                  </ul>
                                </li>
                              </ul>
                            </td>
                          </tr>
                        );
                      })}

                    {/* Repeat this row structure for each data item */}
                  </tbody>
                </table>
                {/* Pagination links */}

                <div className="row">
                  {/* Pagination */}

                  <div className="col-md-5">
                    <ul className="pagination">
                      <li
                        className={`page-item ${currentPage === 1 ? "disabled" : ""
                          }`}
                      >
                        <button
                          onClick={() => paginate(currentPage - 1)}
                          className="page-link dfsdfasdf"
                          disabled={currentPage === 1}
                        >
                          Previous
                        </button>
                      </li>
                      {currentPage > 2 && (
                        <li className="page-item disabled"></li>
                      )}
                      {currentPage < totalPages - 1 && (
                        <li className="page-item disabled"></li>
                      )}
                      <li
                        className={`page-item ${currentPage === totalPages ? "disabled" : ""
                          }`}
                      >
                        <button
                          onClick={() => paginate(currentPage + 1)}
                          className="page-link dfsdfasdf"
                          disabled={currentPage === totalPages}
                        >
                          Next
                        </button>
                      </li>
                    </ul>
                  </div>
                  <div className="col-md-5">
                    <div
                      className="total-entries"
                      style={{ float: "right", marginTop: "20px" }}
                    >
                      Total entries: <b>{totalPages}</b>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default AppHost;
