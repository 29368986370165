import React, { useContext, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import $ from "jquery";
import { toast } from "react-toastify";
import { global } from "../../Context/Context";
import axiosInstance from "../../config/axiosInterceptor";

const EditAgency = () => {
  const { fetchagencyPanel, fetchagencyPanell } = useContext(global);

  useEffect(() => {
    const queryString = window.location.search;
    const params = new URLSearchParams(queryString);
    const userId = params.get("id");

    $("#form")[0].reset(); // Reset the form using jQuery
    fetchagencyPanel(userId);
  }, []);

  const navigate = useNavigate();
  const subbbb = (e) => {
    e.preventDefault();
    let formData = new FormData(e.target); // Use e.target to access the form element
    axiosInstance.post(`${process.env.REACT_APP_BASEURL5}/admin/agencyPanel/updateAgencyPanel`, formData, {
      headers: {
          "Content-Type": "multipart/form-data",
      },
  })
  .then((res) => {
      if (res.data.success === 1) {
          toast.success(res.data.message);
          setTimeout(() => {
              navigate("/admin/manageAgency");
          }, 1000);
      } else {
          toast.error(res.data.message);
      }
  })
  .catch((error) => {
      toast.error(error.message);
      console.error(error);
  });
  };

  return (
    <>
      <section className="content-header">
        <h1>Edit Agency</h1>
        <ol className="breadcrumb">
          <li>
            <Link to="/admin">
              <i className="fa fa-dashboard"></i> Home
            </Link>
          </li>
          <li>
            <Link to="/admin/manageAgency">Manage Agency</Link>
          </li>
          <li className="active">Edit Agency</li>
        </ol>
      </section>
      <section className="content">
        <div className="row">
          <div className="col-md-10">
            <form
              role="form"
              method="post"
              id="form"
              onSubmit={subbbb}
              encType="multipart/form-data"
            >
              <div className="box box-warning">
                <div className="box-header with-border">
                  <h3 className="box-title">Edit</h3>
                </div>
                <div className="box-body">
                  <input
                    type="hidden"
                    name="id"
                    defaultValue={fetchagencyPanell?._id}
                  />
                  <div className="form-group">
                    <label>Agency Name*</label>
                    <input
                      type="text"
                      className="form-control"
                      name="name"
                      placeholder="Agency Name"
                      defaultValue={fetchagencyPanell?.name}
                    />
                    <div className="form-error1" />
                  </div>
                  <div className="form-group">
                    <label>Agency Email*</label>
                    <input
                      type="text"
                      className="form-control"
                      name="email"
                      placeholder="Agency Email"
                      defaultValue={fetchagencyPanell?.email}
                    />
                    <div className="form-error1" />
                  </div>
                  <div className="form-group">
                    <label>Agency Mobile*</label>
                    <input
                      type="text"
                      className="form-control"
                      name="number"
                      placeholder="Agency Mobile"
                      defaultValue={fetchagencyPanell?.number}
                    />
                    <div className="form-error1" />
                  </div>
                  <div className="form-group">
                    <label>Agency Password*</label>
                    <input
                      type="text"
                      className="form-control"
                      name="password"
                      placeholder="Agency Password"
                      defaultValue={fetchagencyPanell?.password}
                    />
                    <div className="form-error1" />
                  </div>
                  <div className="form-group">
                    <label>BD</label>
                    <input
                      type="text"
                      className="form-control"
                      name="bd"
                      placeholder="Bd"
                      defaultValue={fetchagencyPanell?.bd}
                    />
                    <div className="form-error1" />
                  </div>
                  <div className="form-group">
                    <label>admin</label>
                    <input
                      type="text"
                      className="form-control"
                      name="admin"
                      placeholder="Admin"
                      defaultValue={fetchagencyPanell?.admin}
                    />
                    <div className="form-error1" />
                  </div>
                  <div className="form-group">
                    <label>Agency Image*</label>
                    <input type="file" className="form-control" name="image" />
                    <div className="form-error1" />
                  </div>
                  <div className="form-group">
                    <label>Old Icon*</label>
                    <br />
                    <img src={fetchagencyPanell?.image} alt="" height={"80px"} />
                  </div>
                  {/* Repeat other form fields as needed */}
                  <div className="form-group">
                    <button type="reset" className="btn btn-danger">
                      Cancel
                    </button>
                    <input
                      type="submit"
                      className="btn btn-success pull-right"
                      name="submit"
                      defaultValue="Submit"
                    />
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </section>
    </>
  );
};

export default EditAgency;
