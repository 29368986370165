import React, { useContext, useEffect, useState } from "react";
import { global } from "../../Context/Context";
import { Link } from "react-router-dom";

const Dashboard = () => {
  const baseUrl = process.env.REACT_APP_BASEURL1;
  const { users, user, hostCounts, viewhostCounts,tusers,settusers } = useContext(global);
  const [currentPage, setCurrentPage] = useState(1);
  const usersPerPage = 8;

  useEffect(() => {
    // console.log(sessionStorage.getItem('data'))
    if (!sessionStorage.getItem("data")) window.location.href = "/";

    users();
    viewhostCounts();
  }, []);

  // Logic to paginate the users
  const indexOfLastUser = currentPage * usersPerPage;
  const indexOfFirstUser = indexOfLastUser - usersPerPage;
  const currentUsers = user.length>0 && user

  return (
    <>
      <section className="content-header">
        <h1>Dashboard</h1>
        <ol className="breadcrumb">
          <li>
            <a href="#">Home</a>
          </li>
          <li className="active">Dashboard</li>
        </ol>
      </section>
      {/* Main content */}
      <section className="content">
        {/* Info boxes */}
        <div className="row">
          {/* First Info Box */}
          <div className="col-md-3 col-sm-6 col-xs-12">
            <div
              className="info-box green-bg"
              style={{ background: "#06690d", color: "#fff" }}
            >
              <span className="info-box-icon">
                <i className="fa fa-user" />
              </span>
              <div className="info-box-content">
                <span className="info-box-text">
                  <b>Total User</b>
                </span>
                <span className="info-box-number">{tusers}</span>
              </div>
            </div>
          </div>
          {/* Second Info Box */}
          <div className="col-md-3 col-sm-6 col-xs-12">
            <div
              className="info-box blue-bg"
              style={{ background: "#058d9e", color: "#fff" }}
            >
              <span className="info-box-icon">
                <i className="fa fa-user" />
              </span>
              <div className="info-box-content">
                <span className="info-box-text">
                  <b>Total Approved Host</b>
                </span>
                <span className="info-box-number">{hostCounts.acc}</span>
              </div>
            </div>
          </div>
          {/* Third Info Box */}
          <div className="col-md-3 col-sm-6 col-xs-12">
            <div
              className="info-box red-bg"
              style={{ background: "#c72a16", color: "#fff" }}
            >
              <span className="info-box-icon">
                <i className="fa fa-user" />
              </span>
              <div className="info-box-content">
                <span className="info-box-text">
                  <b>Total Pending Host</b>
                </span>
                <span className="info-box-number">{hostCounts.pen}</span>
              </div>
            </div>
          </div>
          {/* Fourth Info Box */}
          <div className="col-md-3 col-sm-6 col-xs-12">
            <div
              className="info-box yellow-bg"
              style={{ background: "#06690d", color: "#fff" }}
            >
              <span className="info-box-icon">
                <i className="fa fa-user" />
              </span>
              <div className="info-box-content">
                <span className="info-box-text">
                  <b>Total Rejected Host</b>
                </span>
                <span className="info-box-number">{hostCounts.rej}</span>
              </div>
            </div>
          </div>
        </div>

        <div className="row">
          <div className="col-md-12">
            <div className="box box-danger">
              <div className="box-header with-border">
                <h3 className="box-title">Latest Members</h3>
                <div className="box-tools pull-right">
                  <span className="label label-danger">8 New Members</span>
                  <button
                    type="button"
                    className="btn btn-box-tool"
                    data-widget="collapse"
                  >
                    <i className="fa fa-minus" />
                  </button>
                  <button
                    type="button"
                    className="btn btn-box-tool"
                    data-widget="remove"
                  >
                    <i className="fa fa-times" />
                  </button>
                </div>
              </div>
              <div className="box-body no-padding">
                <ul className="users-list clearfix">
                  {Array.isArray(currentUsers) &&
                    currentUsers.map((item, index) => {
                      let i = indexOfFirstUser + index + 1;
                      return (
                        <li>
                          <img
                            className="dummyImage"
                            src={
                              `${item.picture}` != ""
                                ? `${baseUrl}${item.picture}`
                                : "/mastiLive_logo.png"
                            }
                            alt="User Image"
                            style={{ height: "90px", width: "90px" }}
                          />
                          <a className="users-list-name">{item.username}</a>
                          <span className="users-list-date">
                            {item.createdAt}
                          </span>
                        </li>
                      );
                    })}

                  {/* Add more list items for each member */}
                </ul>
              </div>
              <div className="box-footer text-center">
                <Link to="/admin/manageUser" className="uppercase">
                  View All Users
                </Link>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Dashboard;
