import React from "react";
import { Link, useNavigate } from "react-router-dom";
import $ from "jquery";
import { toast } from "react-toastify";
import axios from "axios";
import axiosInstance from "../../config/axiosInterceptor";

const UpdateOld = () => {
  const navigate = useNavigate();

  const subbbb = async (e) => {
    e.preventDefault();

    var c = $("input[name='number']").val();
    var a = $("input[name='coin']").val();
    var b = $("input[name='level']").val();
    var d = $("input[name='experience']").val();
    // var e = $("input[name='image']")[0].files[0];

    /////////////////////

    let text = "This field cannot be empty";
    if (a === "") {
      $(".form-error1").html(text);
    }
    if (b === "") {
      $(".form-error2").html(text);
    }
    if (c === "") {
      $(".form-error3").html(text);
    }
    if (d === "") {
      $(".form-error4").html(text);
    }
    if (e === "") {
      $(".form-error5").html(text);
    } else {
      try {
        const response = await axiosInstance.post(
          `${process.env.REACT_APP_BASEURL5}/app/edit/update`,
          {
            number: c,
            coin: a,
            level: b,
            experience: d,
          }
        );

        if (response.data.success === 1) {
          toast.success(response.data.message);
          // console.log(formData.number);
          // setTimeout(() => {
          //   navigate("/admin/manageAgency");
          // }, 2000);
        } else {
          toast.error(response.data.message);
        }
      } catch (error) {
        console.error("Error:", error);
        toast.error("Failed to update user. Please try again later.");
      }
    }
  };

  return (
    <>
      <section className="content-header">
        <h1>Update User</h1>
      </section>
      <section className="content">
        <div className="row">
          <div className="col-md-10">
            <form
              role="form"
              method="post"
              onSubmit={subbbb}
              encType="multipart/form-data"
            >
              <div className="box box-warning">
                <div className="box-body">
                  <div className="form-group">
                    <label>Level</label>
                    <input
                      type="text"
                      className="form-control"
                      name="level"
                      placeholder="Enter level"
                      onChange={() => {
                        $(".form-error1").html("");
                      }}
                    />
                    <div className="form-error1 text-danger"></div>
                  </div>
                  <div className="form-group">
                    <label>Coin</label>
                    <input
                      type="text"
                      className="form-control"
                      name="coin"
                      placeholder="Enter coin"
                      onChange={() => {
                        $(".form-error2").html("");
                      }}
                    />
                    <div className="form-error2 text-danger"></div>
                  </div>
                  <div className="form-group">
                    <label>Experience</label>
                    <input
                      type="text"
                      className="form-control"
                      name="experience"
                      placeholder="Enter experience"
                      onChange={() => {
                        $(".form-error2").html("");
                      }}
                    />
                    <div className="form-error2 text-danger"></div>
                  </div>
                  <div className="form-group">
                    <label> Mobile Number*</label>
                    <input
                      type="text"
                      className="form-control"
                      name="number"
                      placeholder="Enter number "
                      onChange={() => {
                        $(".form-error3").html("");
                      }}
                    />
                    <div className="form-error3 text-danger"></div>
                  </div>
                  {/* <div className="form-group">
                    <label>Agency Password*</label>
                    <input
                      type="text"
                      className="form-control"
                      name="password"
                      placeholder="Agency Password"
                      onChange={() => {
                        $(".form-error4").html("");
                      }}
                    />
                    <div className="form-error4 text-danger"></div>
                  </div>
                  <div className="form-group">
                    <label>Agency Image*</label>
                    <input
                      type="file"
                      className="form-control"
                      name="image"
                      onChange={() => {
                        $(".form-error5").html("");
                      }}
                    />
                    <div className="form-error5  text-danger"></div>
                  </div> */}
                  {/* Repeat other form fields as needed */}
                  <div className="form-group">
                    <button type="reset" className="btn btn-danger">
                      Cancel
                    </button>
                    <input
                      type="submit"
                      className="btn btn-success pull-right"
                      name="submit"
                      defaultValue="Submit"
                    />
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </section>
    </>
  );
};

export default UpdateOld;
