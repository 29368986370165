import React, { useEffect, useState } from "react";
import { Link, useLocation,useNavigate } from "react-router-dom";
import axios from "axios";
import { toast } from "react-toastify";
import axiosInstance from "../../config/axiosInterceptor";

const EditBd = () => {
  let navigate = useNavigate()
  const [bddata, setBddata] = useState({
    name: "",
    email: "",
    number: "",
    password: "",
    image: "",
  });

  const baseUrl = process.env.REACT_APP_BASEURL1;
  const location = useLocation();
  const userId = location.search.split("?")[1];

  const edit = async (id) => {
    try {
      const { data } = await axios.get(`${baseUrl}/bdpanel/getone?${id}`);
      setBddata(data.data);
      toast.success(data.message);
    } catch (error) {
      console.error("Error edit BD:", error);
      toast.error("Failed to edit BD");
    }
  };

  useEffect(() => {
    edit(userId);
  }, []);

  const handleChange = (e) => {
    if (e.target.name === "image") {
      setBddata((prevState) => ({
        ...prevState,
        image: e.target.files[0],
      }));
    } else {
      const { name, value } = e.target;
      setBddata((prevState) => ({
        ...prevState,
        [name]: value,
      }));
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const formData = new FormData();
      formData.append("name", bddata.name);
      formData.append("email", bddata.email);
      formData.append("number", bddata.number);
      formData.append("password", bddata.password);
      formData.append("image", bddata.image);

      const editdata = await axiosInstance.put(
        `${baseUrl}/bdpanel/updatebd?id=${bddata._id}`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );
      console.log(editdata);
      if(editdata?.data?.success){
        toast.success("BD details updated successfully");
        navigate("/admin/manageBd")
      }
      else
      toast.error(editdata?.data?.message)
    } catch (error) {
      console.log(error);
      toast.error(error.message);
    }
  };

  return (
    <>
      <section className="content-header">
        <h1>Edit Bd</h1>
        <ol className="breadcrumb">
          <li>
            <Link to="/admin">
              <i className="fa fa-dashboard"></i> Home
            </Link>
          </li>
          <li>
            <Link to="/admin/manageAgency">Manage Bd</Link>
          </li>
          <li className="active">Edit Bd</li>
        </ol>
      </section>
      <section className="content">
        <div className="row">
          <div className="col-md-10">
            <form
              role="form"
              method="post"
              id="form"
              onSubmit={handleSubmit}
              encType="multipart/form-data"
            >
              <div className="box box-warning">
                <div className="box-header with-border">
                  <h3 className="box-title">Edit</h3>
                </div>
                <div className="box-body">
                  <input type="hidden" name="id" value={bddata._id} />
                  <div className="form-group">
                    <label>Bd Name*</label>
                    <input
                      type="text"
                      className="form-control"
                      name="name"
                      placeholder="Bd Name"
                      value={bddata.name}
                      onChange={handleChange}
                    />
                    <div className="form-error1" />
                  </div>
                  <div className="form-group">
                    <label>Bd Email*</label>
                    <input
                      type="text"
                      className="form-control"
                      name="email"
                      placeholder="Bd Email"
                      value={bddata.email}
                      onChange={handleChange}
                    />
                    <div className="form-error1" />
                  </div>
                  <div className="form-group">
                    <label>Bd Mobile*</label>
                    <input
                      type="text"
                      className="form-control"
                      name="number"
                      placeholder="Bd Mobile"
                      value={bddata.number}
                      onChange={handleChange}
                    />
                    <div className="form-error1" />
                  </div>
                  <div className="form-group">
                    <label>Bd Password*</label>
                    <input
                      type="text"
                      className="form-control"
                      name="password"
                      placeholder="Bd Password"
                      value={bddata.password}
                      onChange={handleChange}
                    />
                    <div className="form-error1" />
                  </div>
                  <div className="form-group">
                    <label>Bd Image*</label>
                    <input
                      type="file"
                      className="form-control"
                      name="image"
                      onChange={handleChange}
                    />
                    <div className="form-error1" />
                  </div>
                  <div className="form-group">
                    <label>Old Icon*</label>
                    <br />

                    <img
                      src={`${baseUrl}${bddata.image}`}
                      alt=""
                      height={"80px"}
                    />
                  </div>
                  <div className="form-group">
                    <button type="reset" className="btn btn-danger">
                      Cancel
                    </button>
                    <input
                      type="submit"
                      className="btn btn-success pull-right"
                      name="submit"
                      value="Submit"
                    />
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </section>
    </>
  );
};

export default EditBd;
