import React , { useContext, useEffect } from 'react'
import { Link ,useNavigate } from "react-router-dom";
import $ from 'jquery';
import {toast} from 'react-toastify' 
import { global } from '../../Context/Context';
import axiosInstance from '../../config/axiosInterceptor';

const EditGarage = () => {

    const { fetchentryeffect, fetchentryeffectS } = useContext(global)

    useEffect(() => {
        const queryString = window.location.search;
        const params = new URLSearchParams(queryString);
        const userId = params.get('id');

        $('#form')[0].reset(); // Reset the form using jQuery
        fetchentryeffect(userId);
    }, []);

    const navigate = useNavigate();

    const subbbb = async (e) => {
        e.preventDefault(); 
        var a = $("select[name='type']").val();
        var b = $("input[name='title']").val();
        var c = $("input[name='price']").val();
        var d = $("input[name='valid']").val();
        var id = $("input[name='id']").val();
        var e = $("input[name='svga']")[0].files[0]; // Access the file object
        var f = $("input[name='thumbnail']")[0].files[0]; // Access the file object
        let text = "This field cannot be empty";
        
        if(e != '' && f == '')
        {
            $('.form-error6').html(text);
            return
        }
        if(e == '' && f != '')
        {
            $('.form-error5').html(text);
            return
        }
       
            // let formData = new FormData(e.target); // Use e.target to access the form element
        let formData = new FormData();
        formData.append('type', a);
        formData.append('title', b);
        formData.append('price', c);
        formData.append('valid', d);
        formData.append('id', id);
        formData.append('svga', e);
        formData.append('thumbnail', f);
        // niru 27/2
        await axiosInstance.post(`${process.env.REACT_APP_BASEURL5}/admin/entryeffects/updateentryeffects`).then((res)=>{
            if(res.success===1){
                toast.success(res.message)
                setTimeout(()=>{
                    navigate('/admin/manageEntryEffect')
                },1000)
            }else{
                toast.error(res.message)
            }
        }).catch((e)=>toast.error(e.message));
        // $.ajax({
        //     url: `${process.env.REACT_APP_BASEURL}/admin/entryeffects/updateentryeffects`,
        //     type: "POST",
        //     data: formData,
        //     contentType: false,
        //     processData: false, 
        //     success: function(res) {
        //         if(res.success===1){
        //             toast.success(res.message)
        //             setTimeout(()=>{
        //                 navigate('/admin/manageEntryEffect')
        //             },1000)
        //         }else{
        //             toast.error(res.message)
        //         }
        //     }
        // });
    }


    return (
        <>
            <section className="content-header">
                <h1>Edit EntryEffects</h1>
                <ol className="breadcrumb">
                <li><Link to="/admin"><i className="fa fa-dashboard"></i> Home</Link></li>
                    <li><Link to="/admin/manageAgency">Manage EntryEffects</Link></li>
                    <li className="active">Edit EntryEffects</li>
                </ol>
            </section>
            <section className="content">
                <div className="row">
                    <div className="col-md-12">
                        <form role="form" method="post" id='form' onSubmit={subbbb} encType="multipart/form-data">
                            <div className="box box-warning">
                                <div className="box-header with-border">
                                    <h3 className="box-title">Title</h3>
                                </div>
                                <div className="box-body">
                                    <input type="hidden" name='id' defaultValue={fetchentryeffectS._id} />
                                    <div className="form-group">
                                        <label>Type*</label>
                                        <select type="text" className="form-control" name="type">
                                            <option value="">Select Type</option>
                                            <option value="ADMIN" selected={fetchentryeffectS.type === 'ADMIN'}>ADMIN</option>
                                            <option value="PURCHASED" selected={fetchentryeffectS.type === 'PURCHASED'}>PURCHASED</option>
                                        </select>
                                    </div>
                                    <div className="form-group">
                                        <label>Title*</label>
                                        <input type="text" className="form-control" name="title" placeholder="Title" defaultValue={fetchentryeffectS.title} />
                                    </div>
                                    <div className="form-group">
                                        <label>Price*</label>
                                        <input type="number" className="form-control" name="price" placeholder="price" defaultValue={fetchentryeffectS.price}/>
                                    </div>
                                    <div className="form-group">
                                        <label>Valid Days*</label>
                                        <input type="number" className="form-control" name="valid" placeholder="valid days" defaultValue={fetchentryeffectS.valid}/>
                                    </div>
                                    <div className="form-group">
                                        <label>Svga*</label>
                                        <input type="file" className="form-control" name="svga" onChange={()=>{
                                            $('.form-error5').html('');
                                        }} />
                                        <div className="form-error5 text-danger"></div>
                                    </div>
                                    <div className="form-group">
                                        <label>Thumbnail*</label>
                                        <input type="file" className="form-control" name="thumbnail" onChange={()=>{
                                            $('.form-error6').html('');
                                        }} />
                                        <div className="form-error6 text-danger"></div>
                                    </div>
                                    <div className="form-group">
                                        <label>Old Icon*</label><br />
                                        <img src={fetchentryeffectS.thumbnail} alt="" height={"80px"}/>
                                    </div>
                                    <div className="form-group">
                                        <button type="reset" className="btn btn-danger">Cancel</button>
                                        <input type="submit" className="btn btn-success pull-right" name="submit" placeholder="Submit" />
                                    </div>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </section>
        </>
    )
}

export default EditGarage