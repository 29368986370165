import React from "react";
import "../Css/Header.css";
import { Link } from "react-router-dom";
import logo from "../../assets/img/mastiLive_logo.png";

const Header = () => {
  let storedName = sessionStorage.getItem("data");
  let details = JSON.parse(storedName);
  let agencyDetail = sessionStorage.getItem("agency");
  let AgencySessiomDetails = JSON.parse(agencyDetail);
  let coinDetail = sessionStorage.getItem("coin");
  let coinDetails = JSON.parse(coinDetail);
  let agencyadminDetail = sessionStorage.getItem("agencyadmin");
  let agencyadminDetails = JSON.parse(agencyadminDetail);
  let bdDetail = sessionStorage.getItem("bd");
  let bdDetails = JSON.parse(bdDetail);
  let x = window.location.pathname.toString();
  let ans = x[0] + x[1] + x[2] + x[3] + x[4] + x[5];
  let agency = ans + x[6];
  let bd = x[0] + x[1] + x[2];
  let agencyadmin = ans + x[6] + x[7] + x[8] + x[9] + x[10] + x[11];
  console.log(ans);
  return (
    <>
      <header className="main-header">
        <a
          href="#"
          className="logo"
          style={{ display: "flex", justifyContent: "space-evenly" }}
        >
          <span className="logo-mini">
            <img style={{ width: "80%", height: "80%" }} src={logo} />
          </span>
          <img src={logo} alt="" style={{ width: "50px", height: "50px" }} />
          <span className="logo-lg">
            <b>Masti Live</b>
          </span>
        </a>
        <nav className="navbar navbar-static-top d-block p-0">
          <a
            href="#"
            className="sidebar-toggle"
            data-toggle="push-menu"
            role="button"
          >
            <span className="sr-only">Toggle navigation</span>
          </a>
          <div className="navbar-custom-menu">
            <ul className="nav navbar-nav">
              <li className="dropdown user user-menu">
                <a className="dropbtn" href="#" data-toggle="dropdown">
                  <img
                    src={
                      agencyadmin==='/agencyadmin'?`${process.env.REACT_APP_BASEURL}${agencyadminDetails?.image}`:
                      bd === "/bd"
                        ? `${process.env.REACT_APP_BASEURL}${bdDetails?.image}`:
                      agency === "/agency"
                        ? `${process.env.REACT_APP_BASEURL}${AgencySessiomDetails?.image}`
                        : ans === "/coin/"
                        ? `${process.env.REACT_APP_BASEURL}${coinDetails?.image}`
                        : `${process.env.REACT_APP_BASEURL}${details?.image}`
                    }
                    className="user-image"
                    alt="User Image"
                    style={{ objectFit: "cover" }}
                  />
                  <span className="hidden-xs">
                    {agencyadmin==='/agencyadmin'?agencyadminDetails.name: 
                     bd === "/bd"
                     ? bdDetails?.name:
                    agency === "/agency"
                      ? AgencySessiomDetails.name
                      : ans === "/coin/"
                      ? coinDetails?.name
                      : details?.name}
                  </span>
                </a>
                <ul id="myDropdown" className="dropdown-menu">
                  <li className="user-header">
                    <img
                      src={
                        agencyadmin==='/agencyadmin'?`${process.env.REACT_APP_BASEURL}${agencyadminDetails?.image}`:
                        bd === "/bd"
                        ? `${process.env.REACT_APP_BASEURL}${bdDetails?.image}`:
                        agency === "/agency"
                          ? `${process.env.REACT_APP_BASEURL}${AgencySessiomDetails?.image}`
                          : ans === "/coin/"
                          ? `${process.env.REACT_APP_BASEURL}${coinDetails?.image}`
                          : `${process.env.REACT_APP_BASEURL}${details?.image}`
                      }
                      className="img-circle"
                      alt="User Image"
                    />
                    <p>{details?.bio}</p>
                  </li>
                  <li className="user-footer">
                    <div className="pull-left">
                      <Link
                        to={`${
                          agencyadmin==='/agencyadmin'? "/agencyadmin/aaprofile":
                          bd === "/bd"
                          ? '/bd/profile':
                          agency === "/agency"
                            ? "/agency/profile"
                            : ans === "/coin/"
                            ? "/coin/profile"
                            : "/admin/profile"
                        } `}
                        className="btn btn-default btn-flat"
                      >
                        Profile
                      </Link>
                    </div>
                    <div className="pull-right">
                      {
                         bd === "/bd"
                         ?<Link
                         to="/bd"
                         className="btn btn-default btn-flat"
                         onClick={() => {
                           sessionStorage.removeItem("bd");
                           window.location.href = "/bd";
                         }}
                       >
                         Sign out
                       </Link> :
                      agencyadmin==='/agencyadmin'? 
                      <Link
                      to="/agencyadmin"
                      className="btn btn-default btn-flat"
                      onClick={() => {
                        sessionStorage.removeItem("agencyadmin");
                        window.location.href = "/agencyadmin";
                      }}
                    >
                      Sign out
                    </Link>:
                      agency === "/agency" ? (
                        <Link
                          to="/agency"
                          className="btn btn-default btn-flat"
                          onClick={() => {
                            sessionStorage.removeItem("agency");
                            window.location.href = "/agency";
                          }}
                        >
                          Sign out
                        </Link>
                      ) : ans === "/coin/" ? (
                        <Link
                          to="/logout"
                          className="btn btn-default btn-flat"
                          onClick={() => {
                            sessionStorage.removeItem("coin");
                            window.location.href = "/coin";
                          }}
                        >
                          Sign out
                        </Link>
                      ) : (
                        <Link
                          to="/logout"
                          className="btn btn-default btn-flat"
                          onClick={() => {
                            sessionStorage.removeItem("data");
                            window.location.href = "/";
                          }}
                        >
                          Sign out
                        </Link>
                      )}
                    </div>
                  </li>
                </ul>
              </li>
            </ul>
          </div>
        </nav>
      </header>
    </>
  );
};

export default Header;
