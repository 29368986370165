import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import axios from "axios";
import { toast } from "react-toastify";
import axiosInstance from '../../config/axiosInterceptor'


const FamilyCount = () => {
  const [editable, setEditable] = useState(false);
  const [Image, setImage] = useState("");
  const [ImageW, setImageW] = useState("");
  const [newImage, setNewImage] = useState(null);
  const [newImageW, setNewImageW] = useState(null);
  const [Id, setId] = useState("");
  const [count, setcount] = useState("");
  const [inputValue, setInputValue] = useState("");
  const [selectedMonth, setSelectedMonth] = useState(new Date().getMonth()); // Default month is June
  const [isOpen, setIsOpen] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [targetValue, setTargetValue] = useState(null);
  const [targetLists, setTargetLists] = useState([]);
  const [year, setYear] = useState(new Date().getFullYear());
  const baseUrl = process.env.REACT_APP_BASEURL1;
  const baseUrl2 = process.env.REACT_APP_BASEURL2;
  const baseUrl3 = process.env.REACT_APP_BASEURL3;
  const baseUrl5 = process.env.REACT_APP_BASEURL5;

  const openModal = () => {
    setIsOpen(true);
  };
  const closeModal = () => {
    setIsOpen(false);
  };

  const handleEditClick = () => {
    setEditable(true);
  };

  const handleCancelClick = () => {
    setEditable(false);
    setInputValue(""); // Reset input value on cancel
  };

  const handleSaveClick = () => {
    setEditable(false);
    // Perform save operation here with inputValue
    // For example, you can send it to an API or update state in parent component
  };

  const handleChange = (e) => {
    setInputValue(e.target.value);
  };

  const getTargetPoints = async () => {
    try {
      const { data } = await axiosInstance.get(
        `${baseUrl5}/admin/familyImage/targetall?q=${+selectedMonth + 1}`
      );
      if (data.success == 1) {
        setTargetLists(data.details);
      } else {
        toast.error(data.message);
      }
    } catch (error) {
      console.log(error.message);
    }
  };

  const [targetEdit, setTargetEdit] = useState("");
  const updateTargetPoints = async () => {
    try {
      if (/^\d*$/.test(targetEdit)) {
        const { data } = await axiosInstance.put(
          `${baseUrl5}/admin/familyImage/updatetarget?targetId=${targetLists[0]?._id}&targetPoints=${targetEdit}`
        );
        if (data.success == 1) {
          toast.success(data.message);
          getTargetPoints();
          setIsEdit(false);
        } else {
          toast.error(data.message);
        }
      } else {
        toast.error("Please enter a valid number");
      }
    } catch (error) {
      toast.error(error.message);
    }
  };

  const data1 = async () => {
    try {
      const data = await axiosInstance.get(`${baseUrl5}/admin/familyImage/image`);

      setcount(data.data.details[0].count);
      setImage(data.data.details[0].image);
      setImageW(data.data.details[0]?.weekImage);
      setId(data.data.details[0]._id);
    } catch (error) {
      console.log(error);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setEditable(false);

    try {
      const formData = new FormData();

      if (inputValue.trim() !== "") {
        formData.append("count", inputValue);
      } else {
        formData.append("count", count);
      }
      formData.append("image", newImage);
      formData.append("weekImage", newImageW);

      const editdata = await axiosInstance.post(
        `${baseUrl5}/admin/familyImage/update?id=${Id}`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );
      console.log(editdata);
      toast.success("Family image updated successfully");
    } catch (error) {
      console.log(error);
      toast.error(error.message);
    }
  };

  useEffect(() => {
    data1();
  }, []);

  const [families, setFamilies] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const [itemsPerPage, setItemsPerPage] = useState(10);

  const [cur, setCur] = useState(1);



  const handlePageChange = (page) => {
    fetchFamilies(page);
  };

  const handleItemsPerPageChange = (e) => {
    setItemsPerPage(parseInt(e.target.value));
    setCur(1);
  };

  const handleMonthChange = (e) => {
    setSelectedMonth(e.target.value);
  };

  const addTotalPointsOfFamily = async (valueD) => {
    try {
      const { data } = await axiosInstance.post(
        `${baseUrl5}/admin/familyImage/targetPoints`,
        {
          targetPoints: valueD,
        }
      );
      if (data.success == 1) {
        toast.success(data.message);
        closeModal();
      } else {
        toast.error(data.message);
      }
    } catch (error) {
      console.log(error.message);
      toast.error(error.message);
    }
  };
  const getWeekNumberFromJulyIST = (date) => {
    // Define the start date (July 1, 2024, 00:00 IST)
    const startDate = new Date(Date.UTC(2024, 6, 1, -5, -30)); // July 1, 2024, at 00:00 IST
    // Calculate the difference in days from the start date
    const diffInDays = Math.floor((date - startDate) / 86400000); // 86400000 ms per day

    // Calculate the week number, adding 1 to start from Week 1
    const weekNumber = Math.floor(diffInDays / 7) + 1;

    return weekNumber;
  };
  const generateWeeks = () => {
    const today = new Date();
    // Set today to the nearest Monday
    const dayOfWeek = today.getDay();
    const diffToMonday = (dayOfWeek === 0 ? -6 : 1) - dayOfWeek;
    const monday = new Date(today);
    monday.setDate(today.getDate() + diffToMonday);

    const weeks = [];
    for (let i = 0; i < 5; i++) {
      const startOfWeek = new Date(monday);
      startOfWeek.setDate(monday.getDate() - i * 7);

      const endOfWeek = new Date(startOfWeek);
      endOfWeek.setDate(startOfWeek.getDate() + 6);
      const weekNumber = getWeekNumberFromJulyIST(startOfWeek);

      weeks.push({
        label: `${startOfWeek.toLocaleDateString()} to ${endOfWeek.toLocaleDateString()}`,
        value: weekNumber,
      });
    }
    return weeks;
  };
  const [viewType, setViewType] = useState("month"); // Default to 'month'
  const [weeks, setWeeks] = React.useState(generateWeeks());
  const [selectedWeek, setSelectedWeek] = React.useState(weeks[0]?.value || "");

  useEffect(() => {
    if (viewType === "week") {
      generateWeeks();
    }
  }, [viewType]);



  const handleViewTypeChange = (e) => {
    setViewType(e.target.value);
  };

  const handleWeekChange = (e) => {
    const selectedIndex = e.target.value;
    setSelectedWeek(selectedIndex);
  };
  const fetchFamilies = async (page = 1) => {
    try {
      setFamilies([]);
      let url = `${baseUrl5}/admin/familyImage/familyrank?page=${page}&limit=${itemsPerPage}&month=${selectedMonth}&year=${year}`;
      if (viewType === "week") url = `${baseUrl5}/admin/familyImage/familyrank?page=${page}&limit=${itemsPerPage}&month=${selectedMonth}&week=${selectedWeek}`
      const { data } = await axiosInstance.get(
        url
      );
      setFamilies(data.details);
      // setCurrentPage(data.pagination.currentPage);
      // setTotalPages(data.pagination.totalPages);
    } catch (error) {
      console.error(error);
      toast.error("Failed to fetch families");
    }
  };

  useEffect(() => {
    fetchFamilies();
    getTargetPoints();
  }, [itemsPerPage, selectedMonth, selectedWeek, viewType,year]);
  return (
    <>
      {isEdit && (
        <div
          className="modal show d-block"
          id="staticBackdrop"
          data-bs-backdrop="static"
          data-bs-keyboard="false"
          tabIndex={-1}
          aria-labelledby="staticBackdropLabel"
          aria-hidden="true"
          style={{ zIndex: 1050 }}
        >
          <div className="modal-dialog" style={{ marginTop: "30rem" }}>
            <div className="modal-content">
              <div className="modal-header">
                <h2 className="modal-title" id="staticBackdropLabel">
                  Update Target Points
                </h2>
                <button
                  type="button"
                  className="btn-close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                />
              </div>

              <form
                className="modal-body "
                onSubmit={(e) => {
                  e.preventDefault();

                  updateTargetPoints();
                }}
              >
                <>
                  <div
                    className="form-floating mt-5"
                    style={{ marginBottom: "2rem" }}
                  >
                    <label htmlFor="floatingInput">Total Points</label>
                    <input
                      type="text"
                      className="form-control"
                      id="floatingInput"
                      placeholder="Total Points"
                      name="targetPoints"
                      onChange={(e) => {
                        const value = e.target.value;
                        if (/^\d*$/.test(value)) {
                          // Checks if the value contains only digits
                          setTargetEdit(value);
                        }
                      }}
                    />
                  </div>

                  <div>
                    <button
                      type="submit"
                      className="btn btn-success"
                      style={{ fontSize: "1.8rem" }}
                    >
                      Submit
                    </button>
                    <button
                      type="button"
                      onClick={() => {
                        setIsEdit(false);
                      }}
                      className="btn btn-danger"
                      data-bs-dismiss="modal"
                      style={{ fontSize: "1.8rem", marginLeft: "2rem" }}
                    >
                      Cancel
                    </button>
                  </div>
                </>
              </form>
            </div>
          </div>
        </div>
      )}
      {isOpen && (
        <div
          className="modal show d-block"
          id="staticBackdrop"
          data-bs-backdrop="static"
          data-bs-keyboard="false"
          tabIndex={-1}
          aria-labelledby="staticBackdropLabel"
          aria-hidden="true"
          style={{ zIndex: 1050 }}
        >
          <div className="modal-dialog" style={{ marginTop: "30rem" }}>
            <div className="modal-content">
              <div className="modal-header">
                <h2 className="modal-title" id="staticBackdropLabel">
                  Add Family Target
                </h2>
                <button
                  type="button"
                  className="btn-close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                />
              </div>

              <form
                className="modal-body "
                onSubmit={(e) => {
                  e.preventDefault();
                  addTotalPointsOfFamily(targetValue);
                }}
              >
                <>
                  <div
                    className="form-floating mt-5"
                    style={{ marginBottom: "2rem" }}
                  >
                    <label htmlFor="floatingInput">Total Points</label>
                    <input
                      type="text"
                      className="form-control"
                      id="floatingInput"
                      placeholder="Total Points"
                      name="targetPoints"
                      onChange={(e) => {
                        setTargetValue(e.target.value);
                      }}
                    />
                  </div>

                  <div>
                    <button
                      type="submit"
                      className="btn btn-success"
                      style={{ fontSize: "1.8rem" }}
                    >
                      Submit
                    </button>
                    <button
                      type="button"
                      onClick={closeModal}
                      className="btn btn-danger"
                      data-bs-dismiss="modal"
                      style={{ fontSize: "1.8rem", marginLeft: "2rem" }}
                    >
                      Cancel
                    </button>
                  </div>
                </>
              </form>
            </div>
          </div>
        </div>
      )}

      <section className="content-header">
        <h1>Manage Family Count</h1>
        <ol className="breadcrumb">
          <li>
            <Link to="/admin">
              <i className="fa fa-dashboard"></i> Home
            </Link>
          </li>
          <li>
            <Link to="/admin/manageTag">Manage Family</Link>
          </li>
        </ol>
      </section>
      <section className="content">
        <div className="row">
          <div className="col-md-12">
            <form
              role="form"
              method="post"
              encType="multipart/form-data"
              id="form"
            >
              <div className="box box-warning">
                <div className="box-header with-border">
                  <h3 className="box-title">Manage</h3>
                </div>
                <div className="box-body">
                  <div className="form-group">
                    <label>Family Count*</label>
                    {editable ? (
                      <>
                        <span style={{ display: "flex" }}>
                          <input
                            type="number"
                            className="form-control"
                            name="count"
                            placeholder="Count"
                            defaultValue={count}
                            value={inputValue}
                            onChange={handleChange}
                            style={{ width: "50%" }}
                          />
                          <div style={{ display: "flex", width: "100%", alignItems: "center" }}>
                            <label htmlFor="image">MonthImage</label>
                            <input
                              type="file"
                              className="form-control"
                              name="image"
                              placeholder="select Image"
                              onChange={(e) => {
                                setNewImage(e.target.files[0]);
                              }}
                              style={{ width: "60%" }}
                            />
                            <label htmlFor="image">WeekImage</label>
                            <input
                              type="file"
                              className="form-control"
                              name="weekImage"
                              placeholder="select Image"
                              onChange={(e) => {
                                setNewImageW(e.target.files[0]);
                              }}
                              style={{ width: "60%" }}
                            />
                          </div>
                          <button
                            type="button"
                            className="btn btn-danger "
                            style={{ marginLeft: "20px", marginRight: "10px" }}
                            onClick={handleCancelClick}
                          >
                            Cancel
                          </button>
                          <button
                            type="button"
                            className="btn btn-primary"
                            onClick={handleSubmit}
                          >
                            Save
                          </button>
                        </span>
                      </>
                    ) : (
                      <>
                        <span style={{ display: "flex" }}>
                          <input
                            type="text"
                            className="form-control"
                            name="title"
                            placeholder="Title"
                            value={count}
                            style={{ width: "60%" }}
                            readOnly
                          />

                          <button
                            type="button"
                            className="btn btn-primary"
                            style={{ marginLeft: "20px" }}
                            onClick={handleEditClick}
                          >
                            Edit
                          </button>
                        </span>
                      </>
                    )}
                  </div>
                  <div className="form-group">
                    <label>Old Icon Month*</label>
                    <br />

                    <img src={`${baseUrl}${Image}`} alt="" height={"80px"} />
                  </div>
                  <div className="form-group">
                    <label>Old Icon Week*</label>
                    <br />

                    <img src={`${baseUrl}${ImageW}`} alt="" height={"80px"} />
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </section>
      <section className="content">
        <div className="row">
          <div className="col-md-12">
            <div className="box box-warning">
              <div className="box-header with-border">
                <h3 className="box-title">Family Ranking</h3>
              </div>
              <div className="box-body text-dark" style={{ color: "black" }}>
                <div className="form-group">
                  <label htmlFor="itemsPerPage">Items per page:</label>
                  <select
                    id="itemsPerPage"
                    className="form-control"
                    style={{
                      width: "auto",
                      display: "inline-block",
                      marginLeft: "10px",
                    }}
                    value={itemsPerPage}
                    onChange={handleItemsPerPageChange}
                  >
                    <option value={10}>10</option>
                    <option value={50}>50</option>
                    <option value={100}>100</option>
                    <option value={500}>500</option>
                  </select>
                </div>
                <section className="content">
                  <div className="row">
                    <div className="col-md-12">
                      <div className="box box-warning">
                        <div className="box-header with-border">
                          <h3 className="box-title">Family Ranking</h3>
                        </div>
                        <div className="box-body text-dark" style={{ color: "black" }}>
                          <div className="form-group">
                            <label htmlFor="viewType">View by:</label>
                            <select
                              id="viewType"
                              className="form-control"
                              style={{ width: "auto", display: "inline-block", marginLeft: "10px" }}
                              value={viewType}
                              onChange={handleViewTypeChange}
                            >
                              <option value="month">Month</option>
                              <option value="week">Week</option>
                            </select>
                          </div>
                          {viewType === "month" ? (
                            <div className="form-group">
                              <label htmlFor="selectMonth">Select Month:</label>
                              <select
                                id="selectMonth"
                                className="form-control"
                                style={{ width: "auto", display: "inline-block", marginLeft: "10px" }}
                                value={selectedMonth}
                                onChange={handleMonthChange}
                              >
                                <option value={0}>January</option>
                                <option value={1}>February</option>
                                <option value={2}>March</option>
                                <option value={3}>April</option>
                                <option value={4}>May</option>
                                <option value={5}>June</option>
                                <option value={6}>July</option>
                                <option value={7}>August</option>
                                <option value={8}>September</option>
                                <option value={9}>October</option>
                                <option value={10}>November</option>
                                <option value={11}>December</option>
                              </select>
                              <select style={{ padding: "8px", marginLeft: "8px" }}
                                value={year}
                                onChange={(e) => setYear(Number(e.target.value))}
                                className="form-select d-inline w-auto"
                              >
                                {Array.from(
                                  { length: 10 },
                                  (_, i) => new Date().getFullYear() - 5 + i
                                ).map((yr) => (
                                  <option key={yr} value={yr}>
                                    {yr}
                                  </option>
                                ))}
                              </select>
                            </div>
                          ) : (
                            <div className="form-group">
                              <label htmlFor="selectWeek">Select Week:</label>
                              <select
                                id="selectWeek"
                                className="form-control"
                                style={{ width: "auto", display: "inline-block", marginLeft: "10px" }}
                                value={weeks.findIndex((w) => w === selectedWeek)}
                                onChange={handleWeekChange}
                              >
                                {weeks.map((week, index) => (
                                  <option key={index} value={week.value}>
                                    {week.label}
                                  </option>
                                ))}
                              </select>
                            </div>
                          )}
                          {/* Existing content for table and pagination */}
                        </div>
                      </div>
                    </div>
                  </div>
                </section>
                <div
                  className="form-group"
                  style={{ display: "flex", gap: "2rem", alignItems: "center" }}
                >
                  <button
                    onClick={openModal}
                    style={{
                      padding: ".5rem",
                      backgroundColor: "#789DBC",
                      border: "none",
                      color: "white",
                    }}
                  >
                    Add Family Target
                  </button>
                  <div
                    style={{
                      fontWeight: "bolder",
                      fontStyle: "italic",
                      display: "flex",
                      alignItems: "center",
                      gap: "2rem",
                    }}
                  >
                    Targets points:{" "}
                    {targetLists.length > 0 ? targetLists[0]?.targetPoints : 0}
                    <span
                      onClick={() => {
                        setIsEdit(true);
                      }}
                    >
                      {targetLists.length > 0 && (
                        <svg
                          version="1.1"
                          id="Layer_1"
                          xmlns="http://www.w3.org/2000/svg"
                          xmlnsXlink="http://www.w3.org/1999/xlink"
                          x="0px"
                          y="0px"
                          width="15px" // Adjust the width as needed
                          height="15px"
                          viewBox="0 0 121.48 122.88"
                          xmlSpace="preserve"
                          style={{ enableBackground: "new 0 0 121.48 122.88" }}
                        >
                          <style type="text/css">
                            {`.st0{fill-rule:evenodd;clip-rule:evenodd;}`}
                          </style>
                          <g>
                            <path
                              className="st0"
                              d="M96.84,2.22l22.42,22.42c2.96,2.96,2.96,7.8,0,10.76l-12.4,12.4L73.68,14.62l12.4-12.4 
           C89.04-0.74,93.88-0.74,96.84,2.22L96.84,2.22z M70.18,52.19L70.18,52.19l0,0.01c0.92,0.92,1.38,2.14,1.38,3.34 
           c0,1.2-0.46,2.41-1.38,3.34v0.01l-0.01,0.01L40.09,88.99l0,0h-0.01c-0.26,0.26-0.55,0.48-0.84,0.67h-0.01 
           c-0.3,0.19-0.61,0.34-0.93,0.45c-1.66,0.58-3.59,0.2-4.91-1.12h-0.01l0,0v-0.01c-0.26-0.26-0.48-0.55-0.67-0.84v-0.01 
           c-0.19-0.3-0.34-0.61-0.45-0.93c-0.58-1.66-0.2-3.59,1.11-4.91v-0.01l30.09-30.09l0,0h0.01c0.92-0.92,2.14-1.38,3.34-1.38 
           c1.2,0,2.41,0.46,3.34,1.38L70.18,52.19L70.18,52.19L70.18,52.19z M45.48,109.11c-8.98,2.78-17.95,5.55-26.93,8.33 
           C-2.55,123.97-2.46,128.32,3.3,108l9.07-32v0l-0.03-0.03L67.4,20.9l33.18,33.18l-55.07,55.07L45.48,109.11L45.48,109.11z 
           M18.03,81.66l21.79,21.79c-5.9,1.82-11.8,3.64-17.69,5.45c-13.86,4.27-13.8,7.13-10.03-6.22L18.03,81.66L18.03,81.66z"
                            />
                          </g>
                        </svg>
                      )}
                    </span>
                  </div>
                </div>
                <table className="table table-bordered">
                  <thead>
                    <tr>
                      <th>Rank</th>
                      <th>Leader Username</th>
                      <th>Family Name</th>
                      <th>Points</th>
                      <th>W. Balance</th>
                      <th>Action</th>
                    </tr>
                  </thead>
                  {families.length == 0 ? (
                    <>Loading...</>
                  ) : (
                    <>
                      <tbody>
                        {Array.isArray(families) &&
                          families.map((family, i) => (
                            <tr key={family._id}>
                              <td>{(cur - 1) * itemsPerPage + i + 1}</td>
                              <td>{family.username}</td>
                              <td>{family.name}</td>
                              <td>{family.experince}</td>
                              <td>{family.balance}</td>
                              <td>
                                <ul className="admin-action btn btn-default">
                                  <li className="dropdown">
                                    <a
                                      className="dropdown-toggle"
                                      style={{ color: "black" }}
                                      data-toggle="dropdown"
                                      href="#"
                                      aria-expanded="false"
                                    >
                                      Action <span className="caret" />
                                    </a>
                                    <ul className="dropdown-menu dropdown-menu-right">
                                      <li role="presentation">
                                        <Link
                                          role="menuitem"
                                          tabIndex={-1}
                                          to={`/admin/ViewTransactions?id=${family._id}`}
                                        >
                                          View
                                        </Link>
                                      </li>
                                    </ul>
                                  </li>
                                </ul>
                              </td>
                            </tr>
                          ))}
                      </tbody>
                    </>
                  )}
                </table>
                <div className="pagination">
                  <button
                    className="btn btn-default"
                    disabled={currentPage === 1}
                    onClick={() => {
                      setCur(cur - 1);
                      handlePageChange(cur - 1);
                    }}
                  >
                    Prev
                  </button>
                  <button
                    className="btn btn-default"
                    disabled={currentPage === totalPages}
                    onClick={() => {
                      setCur(cur + 1);
                      handlePageChange(cur + 1);
                    }}
                  >
                    Next
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default FamilyCount;
