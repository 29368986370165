import React, { useState , useContext, useEffect } from 'react'
import { Link } from "react-router-dom";
import { global } from '../../Context/Context';
import $ from 'jquery';
import {toast} from 'react-toastify'
import axiosInstance from '../../config/axiosInterceptor';

const ManageContent = () => {
    const {contentCounts , viewContent} = useContext(global)
    const [currentPage, setCurrentPage] = useState(1);
    const [entriesPerPage, setEntriesPerPage] = useState(5); 

    const [ de , setDe ] = useState();
    useEffect(()=>{
        viewContent()
    },[de])

    const [vesehi , setVesehi] = useState(0)

  // Logic to paginate the users
  const indexOfLastUser = currentPage * entriesPerPage;
  const indexOfFirstUser = indexOfLastUser - entriesPerPage;
  const currentUsers = contentCounts.slice(indexOfFirstUser, indexOfLastUser);

  // Change page
  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  // Pagination
  const totalPages = Math.ceil(contentCounts.length / entriesPerPage);

  const renderPageNumbers = () => {
    const pageNumbers = [];
    const startPage = Math.max(1, currentPage - 2);
    const endPage = Math.min(totalPages, currentPage + 2);

    for (let i = startPage; i <= endPage; i++) {
      pageNumbers.push(
        <li
          key={i}
          className={`page-item ${currentPage === i ? 'active' : ''}`}// Apply active style conditionally
        >
          <button onClick={() => paginate(i)} className="page-link">
            {i}
          </button>
        </li>
      );
    }

    return pageNumbers;
  };

  const handleConfirmDelete = async (id)=>{
    await axiosInstance.delete(`${process.env.REACT_APP_BASEURL1}/admin/content/deleteContent/${id}`).then((res)=>{ // niru - 277/2
      if(res.data.success===1){
        toast.success(res.data.message)
        setDe(1);
    }else{
        toast.error(res.data.message)
    }
    }).catch((e)=>toast.error(e.message));
  };


    return (
        <>
            {/* Content Header (Page header) */}
            <section className="content-header">
                <h1>
                    Title
                </h1>
                <ol className="breadcrumb">
                    <li><Link to="/admin"><i className="fa fa-dashboard"></i> Home</Link></li>
                    <li className="active">Manage Plan</li>
                </ol>
            </section>
            {/* Main content */}
            <section className="content">
                <div className="row">
                    <div className="col-xs-12">
                        <div className="box">
                            <div className="box-header">
                                <h3 className="box-title"><Link to="/admin/addContent" style={{ fontSize: '14px' }} className="btn btn-block btn-success btn-xs">Add Content</Link></h3>
                            </div>
                            <div className="box-body">
                                <div className="row " style={{ padding: '16px 0px' }}>  
                                    <div className="entries-per-page col-md-6 ">
                                    <label htmlFor="entries">Entries per page:</label>
                                    <select id="entries" value={entriesPerPage} onChange={(e) => setEntriesPerPage(parseInt(e.target.value))}>
                                        <option value="5">5</option>
                                        <option value="10">10</option>
                                        <option value="20">20</option>
                                        <option value="50">50</option>
                                    </select>
                                    </div>
                                </div>

                                <table id="example1" className="table table-bordered table-striped">
                                    <thead>
                                        <tr>
                                            <th className='text-center'>Sr.</th>
                                            <th className='text-center'>Title</th>
                                            <th className='text-center'>Action</th>
                                        </tr>
                                    </thead>
                                    <tbody id="ts">
                                        {/* Hard-coded data for demonstration */}
                                        {
                                            Array.isArray(currentUsers) && currentUsers.map((item, index)=>{
                                                let i = indexOfFirstUser + index + 1;
                                                return(
                                                    <tr>
                                                        <td className='text-center'>{i}</td>
                                                        <td className='text-center'>{item.content}</td>
                                                        <td className='text-center'>
                                                            <ul className="admin-action btn btn-default" style={{ backgroundColor: '#f4f4f4', color: '#fff !important' }}>
                                                                <li role="presentation">
                                                                    <Link role="menuitem" tabIndex={-1} onClick={()=>{
                                                                        const confirmDelete = window.confirm("Are you sure you want to delete this item?");
                                                                        if (confirmDelete) {
                                                                          handleConfirmDelete(item._id);
                                                                            // $.ajax({
                                                                            //     url: `${process.env.REACT_APP_BASEURL}/admin/content/deleteContent/${item._id}`,
                                                                            //     type: "delete",
                                                                            //     dataType: "json",
                                                                            //     success: function(res) {
                                                                            //     if(res.success===1){
                                                                            //         toast.success(res.message)
                                                                            //         setDe(1);
                                                                            //     }else{
                                                                            //         toast.error(res.message)
                                                                            //     }
                                                                            //     }
                                                                            // });
                                                                            setVesehi(vesehi+1)
                                                                        }
                                                                    }}>Delete</Link>
                                                                </li>
                                                            </ul>
                                                        </td>
                                                    </tr>
                                                )
                                            })
                                        }
                      
                                    </tbody>
                                </table>
                                {/* Pagination section can be added here (if applicable) */}
                                
                <div className="row">
                  {/* Pagination */}

                  <div className="col-md-5">
                  <ul className="pagination">
                    <li className={`page-item ${currentPage === 1 ? 'disabled' : ''}`}>
                      <button
                        onClick={() => paginate(currentPage - 1)}
                        className="page-link dfsdfasdf"
                        disabled={currentPage === 1}
                      >
                        Previous
                      </button>
                    </li>
                    {currentPage > 2 && <li className="page-item disabled"></li>}
                    {renderPageNumbers()}
                    {currentPage < totalPages - 1 && <li className="page-item disabled"></li>}
                    <li
                      className={`page-item ${currentPage === totalPages ? 'disabled' : ''
                        }`}
                    >
                      <button
                        onClick={() => paginate(currentPage + 1)}
                        className="page-link dfsdfasdf"
                        disabled={currentPage === totalPages}
                      >
                        Next
                      </button> 
                    </li>
                  </ul>
                  </div>
                  <div className="col-md-5" >
                    
                    <div className="total-entries" style={{ float: "right" , marginTop:'20px' }}>
                      Total entries: <b>{contentCounts.length}</b>
                    </div>
                  </div>
                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default ManageContent
