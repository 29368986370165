import React from 'react'
import { Link } from "react-router-dom";
import $ from 'jquery';
import {toast} from 'react-toastify'
import RechargeHistory from './RechargeHistory';
import axiosInstance from '../../config/axiosInterceptor';

const SendCoins = () => {

    const checkuser = async() => {
        var userId = $("input[name='userId']").val();

        $('#form-error1').remove();
        console.log(userId);
        await axiosInstance.get(
            `${process.env.REACT_APP_BASEURL5}/coin/recharge/sendCoinfetchuser?userId=${userId}`).then((res)=>{
              if (res.data.success === 1) {
                if(res.data.details.username != '')
                {
                  var div = `
                    <div className="form-error1" id="form-error1" style="display:flex; color:gray; margin-top: 15px;">
                      <img src=${process.env.REACT_APP_BASEURL}${res.data.details.picture} style='border-radius:50% ; height:50px; width:50px;' alt="User Profile" />
                      <span style='margin-left: 15px; '>${res.data.details.username} <br /> ${res.data.details.name}</span>
                    </div>
                  `;
                }
                $('.form-error4').html('');
                $('#user').append(div);
              } else {
                $('.form-error4').html('no user found');
              }}
            )
    }
    const checkagency = async () => {
        var userId = $("input[name='aGuserId']").val();
        
        $('#form-error1').remove();
        await axiosInstance.get(`${process.env.REACT_APP_BASEURL5}/coin/recharge/sendCoinfetchagency?userId=${userId}`).then((res)=>{
            if (res.data.success === 1) {
                if(res.data.details.username != '')
                {
                  var div = `
                    <div className="form-error1" id="form-error1" style="display:flex; color:gray; margin-top: 15px;">
                      <img src=${process.env.REACT_APP_BASEURL}${res.data.details.image} style='border-radius:50% ; height:50px; width:50px;' alt="User Profile" />
                      <span style='margin-left: 15px; '>${res.data.details.uniqueId} <br /> ${res.data.details.name}</span>
                    </div>
                  `;
                }
                $('.form-error4').html('');
                $('#agency').append(div);
              } else {
                $('.form-error4').html('no user found');
              }
        }).catch((e)=>toast.error(e.message));
    }

    const sendType = () => {
        var status = 	$("#sendType").val();
        if(status == 1){
            $('.form-error1').html('');
            $('.form-error2').html('');
            $('.form-error3').html('');
            $('#form-error1').remove();
              $("#subadmin").show(1000);
              $("#hide").hide(1000);
          }
          else if(status == 2){
            $('.form-error1').html('');
            $('.form-error4').html('');
            $('.form-error5').html('');
            $('#form-error1').remove();
              $("#subadmin").hide(1000);
              $("#hide").show(1000);
          }
          else{
              $("#subadmin").hide(1000);
              $("#hide").hide(1000); 
          }
          
    }

    const subbbb = async (e) => {
        e.preventDefault();

        var to = $("select[name='to']").val();
        
        let text = "This field cannot be empty";
        if(to == ''){
            $('.form-error1').html(text);
        }
        else if(to == 1){
        console.log('adasd')

            var userId = $("input[name='aGuserId']").val();
            var coin = $("input[name='aGcoin']").val();
            
            if(userId == ''){
                $('.form-error2').html(text);
            }
            if(coin == ''){
                $('.form-error3').html(text);
            }
        }
        else if(to == 2){
        console.log('first')

            var userId = $("input[name='userId']").val();
            var coin = $("input[name='coin']").val();
            // $('#form')[0].reset();
            
            if(userId == ''){
                $('.form-error4').html(text);
            }
            if(coin == ''){
                $('.form-error5').html(text);
            }
        }
        $('#form')[0].reset();
        
        if(to != '' && userId != '' && coin != '')
        {
            await axiosInstance.post(`${process.env.REACT_APP_BASEURL2}/admin/recharge/sendcoin`,{data: {to , userId , coin}}).then((res)=>{ // niru 27/2
                console.log(res);
                if(res.data.success===1){
                    toast.success(res.data.message)
                    setTimeout(() => {
                    }, 1000);
                }else{
                    toast.error(res.data.message)
                }
            }).catch((e)=>toast.error(e.message));
            // $.ajax({
            //     url: `${process.env.REACT_APP_BASEURL2}/admin/recharge/sendcoin`,
            //     type: "post",
            //     dataType: "json",
            //     data: {to , userId , coin},
            //     success: function(res) {
            //         if(res.success===1){
            //             toast.success(res.message)
            //             setTimeout(() => {
            //             }, 1000);
            //         }else{
            //             toast.error(res.message)
            //         }
            //     }
            // });
        }else{
            toast.error('something went wrong')
        }
    }

    return ( 
        <>
            <section className="content-header">
                <h1>Send Coins</h1>
                <ol className="breadcrumb">
                    <li><Link to="/admin"><i className="fa fa-dashboard"></i> Home</Link></li>
                    <li className="active">Send Coins</li>
                </ol>
            </section>
            <section className="content">
                <div className="row">
                    <div className="col-md-12">
                        <form role="form" method="post" onSubmit={subbbb} encType="multipart/form-data" id="form">
                            <div className="box box-warning">
                                <div className="box-header with-border">
                                    <h3 className="box-title">Send</h3>
                                </div>
                                <div className="box-body">
                                    <div className="form-group">
                                        <label>Send Coin To*</label>
                                        <select className="form-control" id="sendType" onChange={sendType} name="to">
                                            <option value="">Select</option>
                                            <option value={1}>Coin Agency</option>
                                            <option value={2}>User</option>
                                        </select>
                                        <div className="form-error1 text-danger"></div>
                                    </div>
                                    <div id="subadmin" style={{ display: 'none' }}>
                                        <div className="form-group" id='agency'>
                                            <label>Coin Agency Code*</label>
                                            <input type="text" className="form-control" name="aGuserId" placeholder="Coin Agency Code" onChange={()=>{
                                                $('.form-error2').html('');
                                                checkagency();
                                            }}/>
                                            <div className="form-error2 text-danger"></div>
                                        </div>
                                        <div className="form-group">
                                            <label>Coins*</label>
                                            <input type="number" className="form-control" name="aGcoin" placeholder="Coins" onChange={()=>{
                                                $('.form-error3').html('');
                                            }}/>
                                            <div className="form-error3 text-danger"></div>
                                        </div>
                                    </div>
                                    <div id="hide" style={{ display: 'none' }}>
                                        <div className="form-group" id='user'>
                                            <label>Username*</label>
                                            <input type="text" className="form-control" name="userId" placeholder="username" onChange={()=>{
                                                $('.form-error4').html('');
                                                checkuser()
                                            }}/>
                                            <div className="form-error4 text-danger"></div>
                                        </div>
                                        <div className="form-group">
                                            <label>Coins*</label>
                                            <input type="number" className="form-control" name="coin" placeholder="Coins" onChange={()=>{
                                                $('.form-error5').html('');
                                            }}/>
                                            <div className="form-error5 text-danger"></div>
                                        </div>
                                    </div>
                                    <div className="form-group">
                                        <button type="reset" className="btn btn-danger">Cancel</button>
                                        <input type="submit" className="btn btn-success pull-right" name="submit" defaultValue="Submit" />
                                    </div>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
                <RechargeHistory/>
            </section>

        </>
    )
}

export default SendCoins