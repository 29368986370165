import { toast } from "react-toastify";
import React, { useEffect, useState } from "react";
import { Link, useLocation,useNavigate } from "react-router-dom";
import axios from "axios";
import axiosInstance from "../../config/axiosInterceptor";

function EditWorkflowUser() {
    let navigate = useNavigate()
    const [data, setData] = useState({
        name: "",
        email: "",
        number: "",
        bio:"",
        password:""
      });
    
      const baseUrl = process.env.REACT_APP_BASEURL5;
      const location = useLocation();
      const userId = location.search.split("?")[1];
    
      const edit = async (id) => {
        try {
          const { data } = await axios.get(`${baseUrl}/admin/workflowUser/getone?${id}`);
          setData(data.data);
          toast.success(data.message);
        } catch (error) {
          toast.error("Failed to edit BD");
        }
      };
    
      useEffect(() => {
        edit(userId);
      }, []);
    
      const handleChange = (e) => {
          setData((prev)=>{
            return {...prev,[e.target.name]:e.target.value}
          })
        };
    
      const handleSubmit = async (e) => {
        e.preventDefault();
        try {
          const editdata = await axiosInstance.put(
            `${baseUrl}/admin/workflowUser/updateWorkflowUser?id=${data._id}`,
            data);
          console.log(editdata);
          if(editdata?.data?.success){
            toast.success(editdata?.data?.message);
            navigate("/admin/manageWorkflowUser")
          }
          else
          toast.error(editdata?.data?.message)
        } catch (error) {
          console.log(error);
          toast.error(error.message);
        }
      };

    return ( 
        <>
              <section className="content-header">
                <h1>Edit Bd</h1>
                <ol className="breadcrumb">
                  <li>
                    <Link to="/admin">
                      <i className="fa fa-dashboard"></i> Home
                    </Link>
                  </li>
                  <li>
                    <Link to="/admin/manageAgency">Manage workflow User</Link>
                  </li>
                  <li className="active">Edit workflow User</li>
                </ol>
              </section>
              <section className="content">
                <div className="row">
                  <div className="col-md-10">
                    <form
                      role="form"
                      method="post"
                      id="form"
                      onSubmit={handleSubmit}
                    >
                      <div className="box box-warning">
                        <div className="box-header with-border">
                          <h3 className="box-title">Edit</h3>
                        </div>
                        <div className="box-body">
                          <input type="hidden" name="id" value={data._id} />
                          <div className="form-group">
                            <label> Name*</label>
                            <input
                              type="text"
                              className="form-control"
                              name="name"
                              placeholder=" Name"
                              value={data.name}
                              onChange={handleChange}
                            />
                            <div className="form-error1" />
                          </div>
                          <div className="form-group">
                            <label> Email*</label>
                            <input
                              type="text"
                              className="form-control"
                              name="email"
                              placeholder=" Email"
                              value={data.email}
                              onChange={handleChange}
                            />
                            <div className="form-error1" />
                          </div>
                          <div className="form-group">
                            <label> Mobile*</label>
                            <input
                              type="text"
                              className="form-control"
                              name="number"
                              placeholder=" Mobile"
                              value={data.number}
                              onChange={handleChange}
                            />
                            <div className="form-error1" />
                          </div>
                          <div className="form-group">
                            <label> Bio*</label>
                            <input
                              type="text"
                              className="form-control"
                              name="bio"
                              placeholder="bio"
                              value={data.bio}
                              onChange={handleChange}
                            />
                            <div className="form-error1" />
                          </div>
                          <div className="form-group">
                            <label> New Password*</label>
                            <input
                              type="text"
                              className="form-control"
                              name="password"
                              placeholder="password"
                              onChange={handleChange}
                            />
                            <div className="form-error1" />
                          </div>
                          <div className="form-group">
                            <button type="reset" className="btn btn-danger">
                              Cancel
                            </button>
                            <button type="submit" className="btn btn-success">
                              Submit
                            </button>
                          </div>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </section>
            </>
    );
}

export default EditWorkflowUser;