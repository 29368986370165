import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import axiosInstance from "../config/axiosInterceptor";
import { toast } from 'react-toastify'

const BackgroundTheme = () => {
  const [backgroundData, setBackgroundData] = useState(null);
  const baseUrl = process.env.REACT_APP_BASEURL5;

  useEffect(() => {
    // Fetch data from the API
    const handleFetchData = async ()=>{
      try {
        const {data} = await axiosInstance.get(`${baseUrl}/admin/wallpaper/background`)
        if(data.success === 1){
          toast.success(data.message);
          setBackgroundData(data.data);
        }else {
          toast.error(data.message);
        }
      } catch (error) {
        toast.error(error.message)
      }
    }
    handleFetchData();
    // fetch(`${baseUrl}/admin/wallpaper/background`)
    //   .then((response) => response.json())
    //   .then((data) => {
    //     setBackgroundData(data.data);
    //     console.log(data)
    //   })
    //   .catch((error) => {
    //     console.error("Error fetching background data:", error);
    //   });
  }, []); // Ensure useEffect runs only when baseUrl changes

  return (
    <>
      <section className="content-header">
        <h1>Manage Banner</h1>
        <ol className="breadcrumb">
          <li>
            <Link to="/admin">
              <i className="fa fa-dashboard"></i> Home
            </Link>
          </li>
          <li className="active">Manage Banner</li>
        </ol>
      </section>
      <section className="content">
        <div className="row">
          <div className="col-xs-12">
            <div className="box-header">
              <h3 className="box-title">
                {/* <Link
                  to="/admin/addBanner"
                  style={{ fontSize: "14px" }}
                  className="btn btn-block btn-success btn-xs"
                >
                  Add Banner
                </Link> */}
              </h3>
            </div>
            <div className="box">
              <div className="box-body">
                <div className="row " style={{ padding: "16px 0px" }}>
                  <div className="entries-per-page col-md-6 ">
                    <label htmlFor="entries">Entries per page:</label>
                    <select
                      id="entries"
                     
                    >
                      <option value="5">5</option>
                      <option value="10">10</option>
                      <option value="20">20</option>
                      <option value="50">50</option>
                    </select>
                  </div>
                  <div
                    className="col-md-5"
                    style={{ display: "flex", justifyContent: "center" }}
                  >
                    <div
                      className="main-data-single-field"
                      style={{ width: "fit-content" }}
                    >
                      <span style={{ fontWeight: "bold", marginRight: "8px" }}>
                        Search
                      </span>
                      <span>
                        <input
                          type="text"
                          id="pname"
                          name="pname"
                          style={{
                            borderRadius: "4px",
                            borderStyle: "groove",
                            width: "150px",
                          }}
                        />
                      </span>
                    </div>
                  </div>
                </div>
                {/* Success message */}
                <div
                  className="success-message"
                  style={{ display: "none" }}
                ></div>
                <table
                  id="example1"
                  className="table table-bordered table-striped"
                >
                  <thead>
                    <tr>
                      <th>Sr.</th>
                      <th>Banner Image</th>
                      <th>Action</th>
                    </tr>
                  </thead>
                  <tbody id="ts">
                    {/* Sample data rows */}
                    {/* Repeat this block for each data row */}
                    {Array.isArray(backgroundData) && backgroundData.map((item)=>{
                        return (
                            <>
                              <tr>
                            <td>1</td>
                            <td>
                              <img
                                src={`${baseUrl}${item.image}`}
                                style={{
                                  width: "80px",
                                  aspectRatio: "2/1",
                                  objectFit: "contain",
                                }}
                              />
                            </td>

                            <td>
                              <ul
                                className="admin-action btn btn-default"
                                style={{
                                  backgroundColor: "#f4f4f4",
                                  color: "#fff !important",
                                }}
                              >
                                <li className="dropdown">
                                  <a
                                    className="dropdown-toggle"
                                    style={{ color: "black" }}
                                    data-toggle="dropdown"
                                    href="#"
                                    aria-expanded="false"
                                  >
                                    Action <span className="caret" />
                                  </a>
                                  <ul className="dropdown-menu dropdown-menu-right">
                                    <li role="presentation">
                                      <Link
                                        role="menuitem"
                                        tabIndex={-1}
                                        to={`/admin/editbackgroundtheme?id=${item._id}`}
                                      >
                                        Edit
                                      </Link>
                                    </li>
                                  </ul>
                                </li>
                              </ul>
                            </td>
                          </tr>
                            </>
                    )}
                      
                    )}
                    
                  </tbody>
                </table>
                {/* Pagination links */}
                {/* <!-- Pagination links here --> */}
                <div className="row">
                  {/* Pagination */}
                  <div className="col-md-5">
                    <div
                      className="total-entries"
                      style={{ float: "right", marginTop: "20px" }}
                    >
                      Total entries: <b>1</b>
                    </div>
                  </div>
                </div>
              </div>
              {/* /.box-body */}
            </div>
            {/* /.box */}
          </div>
          {/* /.col */}
        </div>
        {/* /.row */}
      </section>
    </>
  );
};

export default BackgroundTheme;
