import React , { useContext, useEffect } from 'react'
import { Link ,useNavigate } from "react-router-dom";
import $ from 'jquery';
import {toast} from 'react-toastify'
import { global } from '../../Context/Context';
import axiosInstance from '../../config/axiosInterceptor';

const EditTag = () => {

    const { fetchTag, fetchTagS } = useContext(global)

    useEffect(() => {
        const queryString = window.location.search;
        const params = new URLSearchParams(queryString);
        const userId = params.get('id');

        $('#form')[0].reset(); // Reset the form using jQuery
        fetchTag(userId);
    }, []);

    const navigate = useNavigate();

    const subbbb = async (e) => {
        e.preventDefault();
        let formData = new FormData(e.target); // Use e.target to access the form element

        await axiosInstance.post(`${process.env.REACT_APP_BASEURL5}/admin/tag/updateTag` , {data:formData}).then((res)=>{ // niru - 26/2
            if(res.data.success===1){
                toast.success(res.data.message)
                setTimeout(()=>{ 
                        navigate('/admin/manageTag')
                },1000)
            }else{
                toast.error(res.data.message)
            }
        }).catch((e)=> toast.error(e.message));

        // $.ajax({
        //     url: `${process.env.REACT_APP_BASEURL}/admin/tag/updateTag`,
        //     type: "post",
        //     dataType: "json",
        //     data: formData,
        //     contentType: false,
        //     processData: false,
        //     success: function(res) {
        //     if(res.success===1){
        //         toast.success(res.message)
        //         setTimeout(()=>{ 
        //                 navigate('/admin/manageTag')
        //         },1000)
        //     }else{
        //         toast.error(res.message)
        //     }
        //     }
        // });
    
        
    }

    return (
        <>
            <section className="content-header">
                <h1>Edit Tag</h1>
                <ol className="breadcrumb">
                    <li><Link to="/admin"><i className="fa fa-dashboard"></i> Home</Link></li>
                    <li><Link to="/admin/manageTag">Manage Tag</Link></li>
                    <li className="active">Edit Tag</li>
                </ol>
            </section>
            <section className="content">
                <div className="row">
                    <div className="col-md-12">
                        <form role="form" method="post" onSubmit={subbbb} encType="multipart/form-data" id="form">
                            <div className="box box-warning">
                                <div className="box-header with-border">
                                    <h3 className="box-title">Edit</h3>
                                </div>
                                <div className="box-body">
                                    <input type="hidden" name='id' defaultValue={fetchTagS._id} />
                                    <div className="form-group">
                                        <label>Title*</label>
                                        <input type="text" className="form-control" name="title" placeholder="Title" defaultValue={fetchTagS.title}/>
                                        <div className="form-error1 text-danger"></div>
                                    </div>
                                    <div className="form-group">
                                        <label>Image*</label>
                                        <input accept="image/*" type="file" className="form-control" name="image" />
                                        <div className="form-error2 text-danger"></div>
                                    </div>
                                    <div className="form-group">
                                        <label>Old Icon*</label><br />
                                        <img src={fetchTagS.image} alt="" height={"80px"}/>
                                    </div>
                                    <div className="form-group">
                                        <button type="reset" className="btn btn-danger">Cancel</button>
                                        <input type="submit" className="btn btn-success pull-right" name="submit" defaultValue="Submit" />
                                    </div>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </section>
        </>
    )
}

export default EditTag