import React, { useState } from "react";
import { Link } from "react-router-dom";
import axios from "axios";
import { toast } from "react-toastify";
import axiosInstance from "../../config/axiosInterceptor";
function AddWorkFlowUser() {
    const baseUrl = process.env.REACT_APP_BASEURL5;
    const [formData , setFormData] = useState({
        name:"",
        email:"",
        number:"",
        bio:"",
        password:""
    });

const handleSubmit = async (event) => {
    event.preventDefault();
    await axiosInstance.post(`${baseUrl}/admin/workflowUser/addWorkflowUser`,formData).then((res)=>{
        if(res.data.success == 1){
            toast.success(res.data.message)
        }else{
            toast.error(res.data.message);
        }
    }).catch((e)=>toast.error(e.message))
    setFormData({
        name:"",
        email:"",
        number:"",
        bio:"",
        password:""
    });
};

const handleChange = (e)=>{
    setFormData((prev)=>{
        return {...prev , [e.target.name]:e.target.value}
    })
}

    return ( 
        <>
        <section className="content-header">
                <h1>Add WorkFlowUser</h1>
                <ol className="breadcrumb">
                    <li>
                    <Link to="/admin">
                    <i className="fa fa-dashboard"></i> Home
                    </Link>
                    </li>
                    <li>
                    <Link to="/admin/manageWorkflowUser">Manage WorkFlowUser</Link>
                    </li>
                    <li className="active">Add WorkFlowUser</li>
                </ol>
        </section>
            <section className="content">
                <div className="row">
                    <div className="col-md-10">
                    <form
                      onSubmit={handleSubmit}
                    >
                      <div className="box box-warning">
                        <div className="box-header with-border">
                          <h3 className="box-title">Add</h3>
                        </div>
                        <div className="box-body">
                          <div className="form-group">
                            <label>user Name*</label>
                            <input
                              type="text"
                              className="form-control"
                              name="name"
                              placeholder="user Name"
                              value={formData.name}
                              onChange={handleChange}
                            />
                          </div>
                          <div className="form-group">
                            <label>user Email*</label>
                            <input
                              type="text"
                              className="form-control"
                              name="email"
                              placeholder="user Email"
                              value={formData.email}
                              onChange={handleChange}
                            />
                          </div>
                          <div className="form-group">
                            <label>user Mobile*</label>
                            <input
                              type="number"
                              className="form-control"
                              name="number"
                              placeholder="user Mobile"
                              value={formData.number}
                              onChange={handleChange}
                            />
                          </div>
                          <div className="form-group">
                            <label>user bio*</label>
                            <input
                              type="text"
                              className="form-control"
                              name="bio"
                              placeholder="user BIO"
                              value={formData.bio}
                              onChange={handleChange}
                            />
                          </div>
                          <div className="form-group">
                            <label>user Password*</label>
                            <input
                              type="text"
                              className="form-control"
                              name="password"
                              placeholder="user Password"
                              value={formData.password}
                              onChange={handleChange}
                            />
                          </div>
                          <div className="form-group">
                            <button type="reset" className="btn btn-danger">
                              Cancel
                            </button>
                            <button
                              type="submit"
                              className="btn btn-success pull-right"
                            >
                              Submit
                            </button>
                          </div>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </section>
        </>
    );
}

export default AddWorkFlowUser;