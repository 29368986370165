import React , { useContext, useEffect } from 'react'
import { Link ,useNavigate } from "react-router-dom";
import $ from 'jquery';
import {toast} from 'react-toastify'
import { global } from '../../Context/Context';
import axiosInstance from '../../config/axiosInterceptor';

const EditTheme = () => {

    const { fetchwallpaper, fetchwallpaperS } = useContext(global)

    useEffect(() => {
        const queryString = window.location.search;
        const params = new URLSearchParams(queryString);
        const userId = params.get('id');

        $('#form')[0].reset(); // Reset the form using jQuery
        fetchwallpaper(userId);
    }, []);
 
    const navigate = useNavigate();

    const subbbb = async (e) => {
        e.preventDefault();
    
        let formData = new FormData(e.target); // Use e.target to access the form element
        // niru - 27/2

        await axiosInstance.post(`${process.env.REACT_APP_BASEURL5}/admin/wallpaper/updatewallpaper`,formData).then((res)=>{
            if(res.data.success===1){
                toast.success(res.data.message)
                setTimeout(()=>{
                    navigate('/admin/manageTheme')
                },1000)
            }else{
                toast.error(res.data.message)
            }
        }).catch((e)=>toast.error(e.message));
        // $.ajax({
        //     url: `${process.env.REACT_APP_BASEURL}/admin/wallpaper/updatewallpaper`,
        //     type: "post",
        //     dataType: "json",
        //     data: formData,
        //     contentType: false,
        //     processData: false,
        //     success: function(res) {
        //     if(res.success===1){
        //         toast.success(res.message)
        //         setTimeout(()=>{
        //                 navigate('/admin/manageTheme')
        //         },1000)
        //     }else{
        //         toast.error(res.message)
        //     }
        //     }
        // });
    
    }

    return (
        <>
            <section className="content-header">
                <h1>Edit Theme</h1>
                <ol className="breadcrumb">
                    <li><Link to="/admin"><i className="fa fa-dashboard"></i> Home</Link></li>
                    <li><Link to="/admin/manageTheme">Manage Theme</Link></li>
                    <li className="active">Edit Theme</li>
                </ol>
            </section>
            <section className="content">
                <div className="row">
                    <div className="col-md-12">
                        <form role="form" method="post" id="form" onSubmit={subbbb} encType="multipart/form-data">
                            <div className="box box-warning">
                                <div className="box-header with-border">
                                    <h3 className="box-title">Title</h3>
                                </div>
                                <div className="box-body">
                                    <input type="hidden" name="id" defaultValue={fetchwallpaperS._id} />
                                    <div className="form-group">
                                        <label>Type*</label>
                                        <select type="text" className="form-control" name="type" >
                                            <option value="">Select Type</option>
                                            <option value="PAID" selected={fetchwallpaperS.type === 'PAID'}>PAID</option>
                                            <option value="FREE" selected={fetchwallpaperS.type === 'FREE'}>FREE</option>
                                        </select>
                                    </div>
                                    <div className="form-group">
                                        <label>Price*</label>
                                        <input type="text" className="form-control" name="price" placeholder="price" defaultValue={fetchwallpaperS.price} />
                                    </div>
                                    <div className="form-group">
                                        <label>valid Days*</label>
                                        <input type="text" className="form-control" name="valid" placeholder="valid" defaultValue={fetchwallpaperS.valid} />
                                    </div>
                                    <div className="form-group">
                                        <label>Image*</label>
                                        <input accept="image/*" type="file" className="form-control" name="image" />
                                    </div> 
                                    <div className="form-group">
                                        <label>Old Icon*</label><br />
                                        <img src={fetchwallpaperS.image} alt="" height={"80px"}/>
                                    </div>
                                    <div className="form-group">
                                        <button type="reset" className="btn btn-danger">Cancel</button>
                                        <input type="submit" className="btn btn-success pull-right" name="submit" defaultValue="Submit" />
                                    </div>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </section>
        </>
    )
}

export default EditTheme