import React, { useState, useContext, useEffect } from "react";
import { Link } from "react-router-dom";
import { global } from "../../Context/Context";
import $ from "jquery";
import { toast } from "react-toastify";
import axiosInstance from "../../config/axiosInterceptor";

const ManageGarage = () => {
  const { viewEntryEffects, viewEntryEffect } = useContext(global);
  const [currentPage, setCurrentPage] = useState(1);
  const [entriesPerPage, setEntriesPerPage] = useState(5);

  const [de, setDe] = useState();
  useEffect(() => {
    viewEntryEffects();
  }, [de]);

  // Logic to paginate the users
  const indexOfLastUser = currentPage * entriesPerPage;
  const indexOfFirstUser = indexOfLastUser - entriesPerPage;
  const currentUsers = viewEntryEffect.slice(indexOfFirstUser, indexOfLastUser);

  // Change page
  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  // Pagination
  const totalPages = Math.ceil(viewEntryEffect.length / entriesPerPage);

  const renderPageNumbers = () => {
    const pageNumbers = [];
    const startPage = Math.max(1, currentPage - 2);
    const endPage = Math.min(totalPages, currentPage + 2);

    for (let i = startPage; i <= endPage; i++) {
      pageNumbers.push(
        <li
          key={i}
          className={`page-item ${currentPage === i ? "active" : ""}`} // Apply active style conditionally
        >
          <button onClick={() => paginate(i)} className="page-link">
            {i}
          </button>
        </li>
      );
    }

    return pageNumbers;
  };

  //niru - 27/2
  const handleDelEntryEffects = async (id)=>{
    await axiosInstance.delete(`${process.env.REACT_APP_BASEURL5}/admin/entryeffects/deleteentryeffects/${id}`).then((res)=>{
      if (res.success === 1) {
        toast.success(res.message);
        setDe(1);
      } else {
        toast.error(res.message);
      }
    }).catch((e)=>toast.error(e.message));
  };
  return (
    <>
      {/* Content Header (Page header) */}
      <section className="content-header">
        <h1>Title</h1>
        <ol className="breadcrumb">
          <li>
            <Link to="/admin">
              <i className="fa fa-dashboard" /> Home
            </Link>
          </li>
          <li className="active">Title</li>
        </ol>
      </section>
      {/* Main content */}
      <section className="content">
        <div className="row">
          <div className="col-xs-12">
            <div className="box">
              <div className="box-header">
                <h3 className="box-title">
                  <Link
                    to="/admin/addEntryEffect"
                    style={{ fontSize: "14px" }}
                    className="btn btn-block btn-success btn-xs"
                  >
                    Add EntryEffect
                  </Link>
                </h3>
              </div>
              <div className="box-body">
                <div className="row " style={{ padding: "16px 0px" }}>
                  <div className="entries-per-page col-md-6 ">
                    <label htmlFor="entries">Entries per page:</label>
                    <select
                      id="entries"
                      value={entriesPerPage}
                      onChange={(e) =>
                        setEntriesPerPage(parseInt(e.target.value))
                      }
                    >
                      <option value="5">5</option>
                      <option value="10">10</option>
                      <option value="20">20</option>
                      <option value="50">50</option>
                    </select>
                  </div>
                </div>
                <table
                  id="example1"
                  className="table table-bordered table-striped"
                >
                  <thead>
                    <tr>
                      <th>Sr.</th>
                      <th>Title</th>
                      <th>Type</th>
                      <th>Thumbnail</th>
                      <th>Price</th>
                      <th>Valid For</th>
                      <th>Action</th>
                    </tr>
                  </thead>
                  <tbody id="ts">
                    {/* Hard-coded data for demonstration */}

                    {Array.isArray(currentUsers) &&
                      currentUsers.map((item, index) => {
                        let i = indexOfFirstUser + index + 1;
                        return (
                          <tr>
                            <td>{i}</td>
                            <td>{item.title}</td>
                            <td>{item.type}</td>
                            <td>
                              <img
                                src={item.thumbnail}
                                style={{ width: "80px", height: "70px" }}
                              />
                            </td>
                            <td>{item.price}</td>
                            <td>{item.valid}</td>
                            <td>
                              <ul
                                className="admin-action btn btn-default"
                                style={{
                                  backgroundColor: "#f4f4f4",
                                  color: "#fff !important",
                                }}
                              >
                                <li className="dropdown">
                                  <a
                                    className="dropdown-toggle"
                                    style={{ color: "black" }}
                                    data-toggle="dropdown"
                                    href="#"
                                    aria-expanded="false"
                                  >
                                    Action <span className="caret" />
                                  </a>
                                  <ul className="dropdown-menu dropdown-menu-right">
                                    <li role="presentation">
                                      <Link
                                        role="menuitem"
                                        tabIndex={-1}
                                        to={`/admin/editEntryEffect?id=${item._id}`}
                                      >
                                        Edit
                                      </Link>
                                    </li>
                                    <li role="presentation">
                                      <Link
                                        role="menuitem"
                                        tabIndex={-1}
                                        onClick={() => {
                                          handleDelEntryEffects(item._id);
                                          // $.ajax({
                                          //   url: `${process.env.REACT_APP_BASEURL}/admin/entryeffects/deleteentryeffects/${item._id}`,
                                          //   type: "delete",
                                          //   dataType: "json",
                                          //   success: function (res) {
                                          //     if (res.success === 1) {
                                          //       toast.success(res.message);
                                          //       setDe(1);
                                          //     } else {
                                          //       toast.error(res.message);
                                          //     }
                                          //   },
                                          // });
                                        }}
                                      >
                                        Delete
                                      </Link>
                                    </li>
                                  </ul>
                                </li>
                              </ul>
                            </td>
                          </tr>
                        );
                      })}
                  </tbody>
                </table>
                {/* Pagination section can be added here (if applicable) */}
                <div className="row">
                  {/* Pagination */}

                  <div className="col-md-5">
                    <ul className="pagination">
                      <li
                        className={`page-item ${
                          currentPage === 1 ? "disabled" : ""
                        }`}
                      >
                        <button
                          onClick={() => paginate(currentPage - 1)}
                          className="page-link dfsdfasdf"
                          disabled={currentPage === 1}
                        >
                          Previous
                        </button>
                      </li>
                      {currentPage > 2 && (
                        <li className="page-item disabled"></li>
                      )}
                      {renderPageNumbers()}
                      {currentPage < totalPages - 1 && (
                        <li className="page-item disabled"></li>
                      )}
                      <li
                        className={`page-item ${
                          currentPage === totalPages ? "disabled" : ""
                        }`}
                      >
                        <button
                          onClick={() => paginate(currentPage + 1)}
                          className="page-link dfsdfasdf"
                          disabled={currentPage === totalPages}
                        >
                          Next
                        </button>
                      </li>
                    </ul>
                  </div>
                  <div className="col-md-5">
                    <div
                      className="total-entries"
                      style={{ float: "right", marginTop: "20px" }}
                    >
                      Total entries: <b>{viewEntryEffect.length}</b>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default ManageGarage;
