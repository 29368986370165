import { useEffect,useState } from 'react';
import '../Css/AdminHistory.css';
import axiosInstance from '../../config/axiosInterceptor';
import { toast } from 'react-toastify';

function AdminHistory() {
    const [histories , setHistories] = useState([]);
    const [allowed , setAllowed] = useState(false);
    const [page, setPage] = useState(1);
    const [limit, setLimit] = useState(10);
    const [totalPages , setTotalPages] = useState(10);
    
    // function to fetch histories 
    useEffect(()=>{
            getAdminHistory(page,limit )
            const data = window.sessionStorage.getItem('data');
            const adminAccess = data ? JSON.parse(data) : null ;
            adminAccess.access === '1' && setAllowed(true);
    },[page,limit]);

    // const fetchData = async()=>{
    //     setHistories([]);
    //     await axiosInstance.get(`${process.env.REACT_APP_BASEURL5}/admin/adminHistories`).then((res)=>{
    //         console.log(res);
    //         if(res.data.status === 1){
    //             toast.success(res.data.message);
    //             setHistories((prev)=>{
    //                 return [...prev , ...res.data.allHistories]
    //             });
    //         }else{
    //             toast.error(res.data.message);
    //         }
    //     })
    // }

    const getAdminHistory = async(page,limit,sort)=>{
        setHistories([]);
        try{
            await axiosInstance.get(
                `${process.env.REACT_APP_BASEURL5}/admin/adminHistories`,{
                    params:{
                        page,
                        limit,
                    },
                }
            ).then((res)=>{
                console.log(res);
                if(res.data.status === 1){
                    toast.success(res.data.message);
                    setHistories((prev)=>{
                        return [...prev , ...res.data.allHistories]
                    });
                }else{
                    toast.error(res.data.message);
                }
            })
        }catch(e){
            toast.error(e.message);
        }
    }

    const handlePrev = () => {
        if (page > 1) setPage(page - 1);
    };

    const handleNext = () => {
        if (page < totalPages) setPage(page + 1);
    };

    // Handler for limit change
    const handleLimitChange = (e) => {
    setLimit(parseInt(e.target.value));
    setPage(1); // Reset to the first page when limit changes
    };


    return ( 
        <>  
        {allowed==true?(
            <section>
        <div>

        <div className="form-group">
                  <label htmlFor="itemsPerPage">Items per page:</label>
                  <select
                    id="itemsPerPage"
                    className="form-control"
                    style={{
                      width: "auto",
                      display: "inline-block",
                      marginLeft: "10px",
                    }}
                    value={limit}
                    onChange={handleLimitChange}
                  >
                    <option value={10}>10</option>
                    <option value={20}>20</option>
                    <option value={50}>50</option>
                  </select>
                </div>

            <table id="example1" className="table table-striped adHs-cont">
                <thead>
                <tr>
                    <th>Name</th>
                    <th>Mobile Number</th>
                    <th>Action</th>
                    <th>Perform</th>
                    <th>Time</th>
                </tr>
                </thead>
                {histories?.map((data,idx)=>(
                    <tr key={data._id}>
                        <td>{(page - 1) * limit + idx + 1}</td>
                        <td>{data.adminName}</td>
                        <td>{data.mobileNumber}</td>
                        <td>{data.action}</td>
                        <td>{data.performMessage}</td>
                        <td>{new Date(data.timestamp).toLocaleString()}</td>
                    </tr>
                ))}
            </table>

            <div className="pagination">
                  <button
                    className="btn btn-default"
                    disabled={page === 1}
                    onClick={handlePrev}
                  >
                    Prev
                  </button>
                  <button
                    className="btn btn-default"
                    disabled={page === totalPages}
                    onClick={handleNext}
                  >
                    Next
                  </button>
                </div>

        </div>
            </section>
            ):(
                <p>You don't have Access to this page !</p>
            )}
        </>
    );
}

export default AdminHistory;