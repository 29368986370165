import React from 'react'
import { Link, useNavigate } from "react-router-dom";
import $ from 'jquery';
import { toast } from 'react-toastify'
import axiosInstance from '../../config/axiosInterceptor';

const AddLevel = () => {

  const navigate = useNavigate();

  const subbbb = async (e) => {
    e.preventDefault();

    var a = $("input[name='level']").val();
    var b = $("input[name='expFrom']").val();
    var c = $("input[name='expTo']").val();
    var d = $("input[name='image']").val();
    let text = "This field cannot be empty";

    if (a == '') {
      $('.form-error1').html(text);
    }
    if (b == '') {
      $('.form-error2').html(text);
    }
    if (c == '') {
      $('.form-error3').html(text);
    }
    if (d == '') {
      $('.form-error4').html(text);
    }
    else if (a != '' && b != '' && c != '' && d != '') {
      let formdata = new FormData(e.target);
      console.log(formdata);
      //niru 27/2
      await axiosInstance.post(`${process.env.REACT_APP_BASEURL5}/admin/level/addlevel`,formdata).then((res)=>{
        if (res.data.success === 1) {
          toast.success(res.data.message)
          setTimeout(() => {
            navigate('/admin/manageLevel')
          }, 1000);
        } else {
          toast.error(res.data.message)
        }
      }).catch((e)=>toast.error(e.message));
      // $.ajax({
      //   url: `${process.env.REACT_APP_BASEURL}/admin/level/addlevel`,
      //   type: "post",
      //   dataType: "json",
      //   data: formdata,
      //   processData: false,
      //   contentType: false,
      //   success: function (res) {
      //     if (res.success === 1) {
      //       toast.success(res.message)
      //       setTimeout(() => {
      //         navigate('/admin/manageLevel')
      //       }, 1000);
      //     } else {
      //       toast.error(res.message)
      //     }
      //   }
      // });
    }
  }


  return (
    <>
      <section className="content-header">
        <h1 style={{ marginBottom: '20px' }}>
          Add Level
        </h1>
        <ol className="breadcrumb" style={{ marginBottom: '20px' }}>
          <li><Link to="/admin"><i className="fa fa-dashboard"></i> Home</Link></li>
          <li><Link to="/admin/manageLevel">Manage Level</Link></li>
          <li className="active">Add Level</li>
        </ol>
      </section>
      <section className="content" style={{ padding: '0 15px' }}>
        <div className="row">
          <div className="col-md-12">
            <form role="form" method="post" onSubmit={subbbb} encType="multipart/form-data">
              <div className="box box-warning">
                <div className="box-header with-border">
                  <h3 className="box-title">Add</h3>
                </div>
                <div className="box-body">
                  <div className="form-group" style={{ marginBottom: '20px' }}>
                    <label style={{ marginBottom: '10px' }}>Level*</label>
                    <input type="number" className="form-control" name="level" placeholder="level eg.(1 , 2 , 3...)" style={{ width: '100%' }} onChange={() => {
                      $('.form-error1').html('');
                    }} />
                    <div className="form-error1 text-danger"></div>
                  </div>
                  <div className="form-group" style={{ marginBottom: '20px' }}>
                    <label style={{ marginBottom: '10px' }}>Experience*</label>
                    <input type="number" className="form-control" name="expTo" placeholder="ExpTo" style={{ width: '100%' }} onChange={() => {
                      $('.form-error3').html('');
                    }} /> 
                    <div className="form-error3 text-danger"></div>
                  </div>
                  <div className="form-group" style={{ marginBottom: '20px' }}>
                    <label style={{ marginBottom: '10px' }}>Icon*</label>
                    <input accept="image/*" type="file" className="form-control" name="icon" style={{ width: '100%' }} onChange={() => {
                      $('.form-error4').html('');
                    }} />
                    <div className="form-error4 text-danger"></div>
                  </div>
                  <div className="form-group" style={{ marginBottom: '20px' }}>
                    <button type="reset" className="btn btn-danger" style={{ marginRight: '10px' }}>Cancel</button>
                    <input type="submit" className="btn btn-success pull-right" name="submit" defaultValue="Submit" />
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </section>
    </>
  )
}

export default AddLevel