import React, { useState, useEffect } from 'react';
import { Link, useLocation, useNavigate } from "react-router-dom";
import axios from 'axios';
import axiosInstance from '../config/axiosInterceptor';
import { toast } from 'react-toastify';
const baseUrl = process.env.REACT_APP_BASEURL5;

const EditBackgroundtheme = () => {
    const [image, setImage] = useState(null);
    const [id, setId] = useState('');
    const location = useLocation();
    const navigate = useNavigate();

    useEffect(() => {
        const searchParams = new URLSearchParams(location.search);
        const idParam = searchParams.get('id');
        if (idParam) {
            setId(idParam);
        }
    }, [location.search]);

    const handleFileChange = (e) => {
        setImage(e.target.files[0]);
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            const formData = new FormData();
            formData.append('id', id); // Append id to form data
            formData.append('image', image);

            const response = await axiosInstance.post(`${baseUrl}/admin/wallpaper/updatebackground`, formData, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            });
            if (response.data.success === 1) {
                // Navigate to background theme page
                navigate('/admin/backgroundtheme');
            }
            console.log(response.data);
            // Show success message or redirect to another page
        } catch (error) {
            console.error('Error updating background:', error);
            toast.error(error.message);
            navigate('/admin/backgroundtheme');
            // Show error message to the user
        }
    };

    return (
        <>
            <section className="content-header">
                <h1>Edit Theme</h1>
                <ol className="breadcrumb">
                    <li><Link to="/admin"><i className="fa fa-dashboard"></i> Home</Link></li>
                    <li><Link to="/admin/manageTheme">Manage Theme</Link></li>
                    <li className="active">Edit Theme</li>
                </ol>
            </section>
            <section className="content">
                <div className="row">
                    <div className="col-md-12">
                        <form onSubmit={handleSubmit} encType="multipart/form-data">
                            <div className="box box-warning">
                                <div className="box-header with-border">
                                    <h3 className="box-title">Title</h3>
                                </div>
                                <div className="box-body">
                                    <div className="form-group">
                                        <label>Image*</label>
                                        <input
                                            accept="image/*"
                                            type="file"
                                            className="form-control"
                                            name="image"
                                            onChange={handleFileChange}
                                            required
                                        />
                                    </div> 
                                    
                                    <input type="hidden" name="id" value={id} /> {/* Hidden input for id */}
                                    
                                    <div className="form-group">
                                        <button type="button" className="btn btn-danger">Cancel</button>
                                        <input type="submit" className="btn btn-success pull-right" name="submit" defaultValue="Submit" />
                                    </div>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </section>
        </>
    );
};

export default EditBackgroundtheme;
