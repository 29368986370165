import React, { useEffect, useState } from "react";
import Switch from "react-switch"; // You can install this using npm or yarn
import BarChart from "./BarGraph";
import axiosInstance from "../../config/axiosInterceptor"
import { toast } from "react-toastify";
const Balance = () => {
  const baseUrl = process.env.REACT_APP_BASEURL5;
  const [balance, setBalance] = useState([0]);
  const [withdrawalStatus, setWithdrawalStatus] = useState({
    coinsWithdrawal: "0",
    familyWithdrawal: "0",
  });

  // Fetch balance and withdrawal status on component mount
  useEffect(() => {
    const fetchBalance = async () => {
      const response = await axiosInstance.get(baseUrl + '/admin/getBalance');
      
      if (response?.data.succes === "1") {
        setBalance(response?.data.details);
      }
    };

    const fetchWithdrawalStatus = async () => {
      const response = await fetch(process.env.REACT_APP_BASEURL1 + '/admin/withdrawal/checkStatus');
      const data = await response.json();
      if (data.success == "1") {
        setWithdrawalStatus({
          coinsWithdrawal: data.details[0].coinsWithdrawal,
          familyWithdrawal: data.details[0].familyWithdrawal,
        });
      }
    };

    fetchBalance();
    fetchWithdrawalStatus();
  }, [baseUrl]);

  // Toggle the withdrawal status with confirmation
  const toggleWithdrawal = async (type) => {
    try {
      const toggleType = type === "1" ? "Coins Withdrawal" : "Family Withdrawal";
    const confirmToggle = window.confirm(`Are you sure you want to toggle ${toggleType}?`);

    if (confirmToggle) {
      const response = await axiosInstance.post(`${process.env.REACT_APP_BASEURL1}/admin/withdrawal/updateWithdrawal?type=${type}`);
      if (response.data.success === "1") {
        const updatedStatus = response.data.result;
        setWithdrawalStatus({
          coinsWithdrawal: updatedStatus.coinsWithdrawal,
          familyWithdrawal: updatedStatus.familyWithdrawal,
        });
      }
    }
    } catch (error) {
      toast.error(error.message)
    }
    
  };

  return (
    <>
      <section className="content-header">
        <h1>Balance</h1>
        <ol className="breadcrumb">
          <li>
            <a href="#">Home</a>
          </li>
          <li className="active">Balance</li>
        </ol>
      </section>

      <section className="content">
          <span className="bg-warning" style={{padding:"8px"}}>Note : Test Ids are excluded</span>
        <div className="row" style={{marginTop:"20px"}}>
          {/* Total Stars */}
          <div className="col-md-6 col-sm-6 col-xs-12">
            <div
              className="info-box green-bg"
              style={{ background: "#06690d", color: "#fff" }}
            >
              <span className="info-box-icon">
                <i className="fa-solid fa-coins"></i>
              </span>
              <div className="info-box-content">
                <span className="info-box-text">
                  <b>Total Stars</b>
                </span>
                <span className="info-box-number">{balance[0]?.coin}</span>
              </div>
            </div>
          </div>

          {/* Total M.Coins */}
          <div className="col-md-6 col-sm-6 col-xs-12">
            <div
              className="info-box green-bg"
              style={{ background: "#06690d", color: "#fff" }}
            >
              <span className="info-box-icon">
                <i className="fa-solid fa-coins"></i>
              </span>
              <div className="info-box-content">
                <span className="info-box-text">
                  <b>Total M.Coins</b>
                </span>
                <span className="info-box-number">{balance[0]?.recievingCoins}</span>
              </div>
            </div>
          </div>

          {/* Total M.Coins > 4999 */}
          <div className="col-md-6 col-sm-6 col-xs-12">
          </div>
          <div className="col-md-6 col-sm-6 col-xs-12">
            <div
              className="info-box green-bg"
              style={{ background: "#06690d", color: "#fff" }}
            >
              <span className="info-box-icon">
                <i className="fa-solid fa-coins"></i>
              </span>
              <div className="info-box-content">
                <span className="info-box-text">
                  <b>{"Total M.Coins > 4999"}</b>
                </span>
                <span className="info-box-number">
                  {balance[0]?.recievingCoinsAbove5000}
                </span>
              </div>
            </div>
          </div>

          {/* Switch for Coins Withdrawal */}
          <div className="col-md-6 col-sm-6 col-xs-12">
            <div className="info-box" style={{ background: "#f4f4f4", color: "#000" }}>
              <span className="info-box-content">
                <span className="info-box-text">
                  <b>Coins Withdrawal Status:</b> {withdrawalStatus.coinsWithdrawal === "1" ? "Enabled" : "Disabled"}
                </span>
                <Switch
                  checked={withdrawalStatus.coinsWithdrawal === "1"}
                  onChange={() => toggleWithdrawal("1")}
                  onColor="#00c853"
                  offColor="#ff1744"
                  uncheckedIcon={false}
                  checkedIcon={false}
                />
              </span>
            </div>
          </div>

          {/* Switch for Family Withdrawal */}
          <div className="col-md-6 col-sm-6 col-xs-12">
            <div className="info-box" style={{ background: "#f4f4f4", color: "#000" }}>
              <span className="info-box-content">
                <span className="info-box-text">
                  <b>Family Withdrawal Status:</b> {withdrawalStatus.familyWithdrawal === "1" ? "Enabled" : "Disabled"}
                </span>
                <Switch
                  checked={withdrawalStatus.familyWithdrawal === "1"}
                  onChange={() => toggleWithdrawal("0")}
                  onColor="#00c853"
                  offColor="#ff1744"
                  uncheckedIcon={false}
                  checkedIcon={false}
                />
              </span>
            </div>
          </div>
        </div>
        {/* <BarChart/> */}
      </section>
    </>
  );
};

export default Balance;
