import React, { useState, useCallback, useEffect } from "react";
import axios from "axios";
import _ from "lodash";
import axiosInstance from "../../config/axiosInterceptor"

const GetTargetWithdrawl = () => {
    const baseUrl = process.env.REACT_APP_BASEURL1;

    const [hostRecord, setHostRecord] = useState([]);
    const [totalPages, setTotalPages] = useState(1);
    const [page, setPage] = useState(1);
    const [entriesPerPage, setEntriesPerPage] = useState(10);
    const [count, setCount] = useState(0);
    const [searchQuery, setSearchQuery] = useState("");

    useEffect(() => {
        fetchAdminHostRecord();
    }, [entriesPerPage, page]);

    const renderSerialNumber = (index) => {
        return (page - 1) * entriesPerPage + index + 1;
    };

    const fetchAdminHostRecord = async () => {
        try {
            setHostRecord([]);
            const { data } = await axios.get(
                `${baseUrl}/admin/withdrawal/getTargetWithdrawalAdmin?page=${page}&limit=${entriesPerPage}`
            );

            if (data) {
                setHostRecord(data.details);
                setCount(data.total);
            }
        } catch (error) {
            console.log(error.message);
        }
    };

    const agencyadminlistsearch = async (query = "") => {
        try {
            setHostRecord([]);
            const response = await axios.get(
                `${baseUrl}/admin/bannedUserssearch`,
                {
                    params: {
                        search: query,
                    },
                }
            );
            setHostRecord(response.data.details);
            setCount(response.data.total);
        } catch (error) {
            console.error(error);
        }
    };

    const handleSearchChange = useCallback(
        _.debounce((value) => {
            setSearchQuery(value);
            if (value !== "") agencyadminlistsearch(value);
            else {
                fetchAdminHostRecord();
            }
        }, 1000),
        []
    );

    const handleDelete = async (id) => {
        const confirmDelete = window.confirm("Are you sure you want to delete this record?");
        if (!confirmDelete) return;

        try {
            await axiosInstance.post(`${process.env.REACT_APP_BASEURL1}/admin/withdrawal/deleteTargetWithdrawal`, { id: id });
            alert("Record deleted successfully.");
            fetchAdminHostRecord(); // Refresh the data after deletion
        } catch (error) {
            console.error("Error deleting record:", error.message);
            alert("Failed to delete the record. Please try again.");
        }
    };

    return (
        <>
            <section className="content-header">
                <h1>Target Withdrawal</h1>
            </section>
            <section className="content">
                <div className="row">
                    <div className="col-xs-12">
                        <div className="box">
                            <div className="box-body">
                                <div className="row" style={{ padding: "16px 0px" }}>
                                    <div className="entries-per-page col-md-6">
                                        <label htmlFor="entries">Entries per page:</label>
                                        <select
                                            id="entries"
                                            value={entriesPerPage}
                                            onChange={(e) =>
                                                setEntriesPerPage(parseInt(e.target.value))
                                            }
                                        >
                                            <option value="10">10</option>
                                            <option value="100">100</option>
                                            <option value="500">500</option>
                                            <option value="1000">1000</option>
                                        </select>
                                    </div>
                                    {/* <div className="col-xs-4"></div>
                                    <div className="form-group col-xs-4">
                                        <label htmlFor="search">Search:</label>
                                        <input
                                            type="text"
                                            id="search"
                                            className="form-control"
                                            onChange={(e) => handleSearchChange(e.target.value)}
                                        />
                                    </div> */}
                                </div>

                                <table id="example1" className="table table-bordered table-striped">
                                    <thead>
                                        <tr>
                                            <th>Sr.</th>
                                            <th>CreatedBy</th>
                                            <th>BD</th>
                                            <th>CreatedFor</th>
                                            <th>Ag. Admin</th>
                                            <th>Month</th>
                                            <th>Amount</th>
                                            <th>CreatedAt</th>
                                            <th>Action</th>
                                        </tr>
                                    </thead>
                                    <tbody id="ts">
                                        {hostRecord.length === 0
                                            ? "No data..."
                                            : hostRecord.map((host, i) => (
                                                <tr key={host._id}>
                                                    <td>{renderSerialNumber(i)}</td>
                                                    <td>{host.createdBy}</td>
                                                    <td>{host.createdForName}</td>
                                                    <td>{host.createdFor}</td>
                                                    <td>{host.createdByName}</td>
                                                    <td>{host.month}</td>
                                                    <td>{host.amount}</td>
                                                    <td>{new Date(host.createdAt).toLocaleString()}</td>
                                                    <td>
                                                        <ul className="admin-action btn btn-default">
                                                            <li className="dropdown">
                                                                <a
                                                                    className="dropdown-toggle"
                                                                    style={{ color: "black" }}
                                                                    data-toggle="dropdown"
                                                                    href="#"
                                                                    aria-expanded="false"
                                                                >
                                                                    Action <span className="caret" />
                                                                </a>
                                                                <ul className="dropdown-menu dropdown-menu-right">
                                                                    <li
                                                                        role="presentation"
                                                                        style={{ color: "black", cursor: "pointer" }}
                                                                        onClick={() => handleDelete(host._id)}
                                                                    >
                                                                        Delete
                                                                    </li>
                                                                </ul>
                                                            </li>
                                                        </ul>
                                                    </td>
                                                </tr>
                                            ))}
                                    </tbody>
                                </table>

                                {/* Pagination links */}
                                <div>
                                    <ul className="pagination">
                                        <div>Total Entries : {count}</div>
                                        <li>
                                            <span
                                                style={{ cursor: "pointer" }}
                                                onClick={() => setPage((prev) => Math.max(prev - 1, 1))}
                                            >
                                                Previous
                                            </span>
                                        </li>
                                        <li>
                                            <span
                                                style={{ cursor: "pointer" }}
                                                onClick={() => {
                                                    if (Math.ceil(count / entriesPerPage) > page)
                                                        setPage((prev) => prev + 1);
                                                }}
                                            >
                                                Next
                                            </span>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
};

export default GetTargetWithdrawl;
