import React, { useState, useEffect, useContext } from "react";
import { Link } from "react-router-dom";
import axios from "axios";
import {global} from '../../Context/Context'
import {toast} from 'react-toastify'
import axiosInstance from "../../config/axiosInterceptor"

const Video = () => {
  const { getVulgarResult, getVulgar, aproveRejectVulgar } = useContext(global);
  const [id, setId] = useState("");

  const baseUrl = process.env.REACT_APP_BASEURL1;
  const [videos, setVideos] = useState([]);
  const [loading, setLoading] = useState(false);

  const [totalPages, setTotalPages] = useState(1);
  const [currentPage, setCurrentPage] = useState(1);
  const [entriesPerPage, setEntriesPerPage] = useState(5);
  const pageNumbers = [];
  const startPage = Math.max(1, currentPage - 2);
  const endPage = Math.min(totalPages, currentPage + 2);
  // for (let i = startPage; i <= endPage; i++) {
  //   pageNumbers.push(
  //     <li
  //       key={i}
  //       className={`page-item ${currentPage === i ? "active" : ""}`}
  //     >
  //       <button onClick={() => paginate(i)} className="page-link">
  //         {i}
  //       </button>
  //     </li>
  //   );
  // }
  useEffect(() => {
    const fetchData = async () => {
      setLoading(true)
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_BASEURL4}/admin/video?page=${currentPage}&limit=${entriesPerPage}&v=1`
        );
        setVideos(response.data.details);
        setTotalPages(response.data.totalPages);
        setLoading(false)
console.log(response.data.totalPages)
      } catch (error) {
        console.log("Error fetching data:", error);
      }
    };

    fetchData();
  }, [baseUrl, currentPage, entriesPerPage]);

  const handleDelete = async (videoId) => {
    try {
      await axiosInstance.delete(`${baseUrl}/admin/video/${videoId}`);
      setVideos(videos.filter((video) => video._id !== videoId));
      toast.success("done")
    } catch (error) {
      toast.error(error.message)
      console.log("Error deleting video:", error);
    }
  };

  const renderPageNumbers = () => {
    const pageNumbers = [];
    const startPage = Math.max(1, currentPage - 2);
    const endPage = Math.min(totalPages, currentPage + 2);

    for (let i = startPage; i <= endPage; i++) {
      pageNumbers.push(
        <li
          key={i}
          className={`page-item ${currentPage === i ? "active" : ""}`}
        >
          <button onClick={() => paginate(i)} className="page-link">
            {i}
          </button>
        </li>
      );
    }

    return pageNumbers;
  };

  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  return (
    <>
      <section className="content-header">
        <h1>Vulgar List</h1>
        <ol className="breadcrumb">
          <li>
            <Link to="/admin">
              <i className="fa fa-dashboard"></i> Home
            </Link>
          </li>
          <li className="active">Video List</li>
        </ol>
      </section>
      <section className="content">
        <div className="row">
          <div className="col-xs-12">
            <div className="box">
              <div className="box-body">
                <div className="row" style={{ padding: "16px 0px" }}>
                  <div className="entries-per-page col-md-6">
                    <label htmlFor="entries">Entries per page:</label>
                    <select
                      id="entries"
                      value={entriesPerPage}
                      onChange={(e) => setEntriesPerPage(parseInt(e.target.value))}
                    >
                      <option value="5">5</option>
                      <option value="10">10</option>
                      <option value="20">20</option>
                      <option value="50">50</option>
                    </select>
                  </div>
                </div>
                <table className="table table-bordered table-striped">
                  <thead>
                  <tr>
                      <th>Sr.</th>
                      <th>Username</th>
                      <th>Video</th>
                      <th>Safe %</th>
                      <th>Status</th>
                      <th>Action</th>
                    </tr>
                  </thead>
                  {loading?<>Loading...</>:<tbody>
                    {videos.map((item, index) => {
                      const i = index + 1 + (currentPage - 1) * entriesPerPage;
                      return (
                        <tr>
                            <td>{i}</td>
                            <td>{item?.username}</td>
                            <td>
                              <video
                                src={`${item?.video}`}
                                autoPlay
                                loop={true}
                                controls={false}
                                muted={true}
                                style={{
                                  height: "200px",
                                  width: "200px",
                                }}
                              ></video>
                            </td>
                            <td className=" d-flex border justify-content-center">
                              {item?.lastSafeValue.length > 0
                                ? item?.lastSafeValue * 100 + "%"
                                : "null"}
                            </td>
                            <td>
                              {item.status == "0" ? (
                                <td>
                                  <span className="label label-info">
                                    Pending
                                  </span>
                                </td>
                              ) : item.status == 1 ? (
                                <td>
                                  <span className="label label-success">
                                    Approved
                                  </span>
                                </td>
                              ) : (
                                <td>
                                  <span className="label label-danger">
                                    Rejected
                                  </span>
                                </td>
                              )}
                            </td>
                            <td>
                              <ul
                                className="admin-action btn btn-default"
                                style={{
                                  backgroundColor: "#f4f4f4",
                                  color: "#fff !important",
                                }}
                              >
                                <li className="dropdown">
                                  <a
                                    className="dropdown-toggle"
                                    style={{ color: "black" }}
                                    data-toggle="dropdown"
                                    href="#"
                                    aria-expanded="false"
                                  >
                                    Action <span className="caret" />
                                  </a>
                                  <ul className="dropdown-menu dropdown-menu-right">
                                    <>
                                      <li role="presentation">
                                        <Link
                                          role="menuitem"
                                          tabIndex={-1}
                                          to=""
                                          onClick={() => {
                                            const obj = {
                                              status: "0",
                                              id: item._id,
                                            };
                                            setId(item._id);
                                            aproveRejectVulgar(obj);
                                            // toast.success("Done");
                                          }}
                                        >
                                          Approve
                                        </Link>
                                      </li>
                                      <li role="presentation">
                                        <Link
                                          role="menuitem"
                                          tabIndex={-1}
                                          to=""
                                          onClick={() => {
                                            const obj = {
                                              status: "3",
                                              id: item._id,
                                            };
                                            setId(item._id);
                                            handleDelete(item._id);

                                          }}
                                        >
                                          Delete
                                        </Link>
                                      </li>
                                      <li role="presentation">
                                        <Link
                                          role="menuitem"
                                          tabIndex={-1}
                                          to=""
                                          onClick={() => {
                                            const obj = {
                                              status: "2",
                                              id: item._id,
                                            };
                                            setId(item._id);
                                            aproveRejectVulgar(obj);
                                            // toast.success("Done");

                                          }}
                                        >
                                          Reject
                                        </Link>
                                      </li>
                                    </>
                                  </ul>
                                </li>
                              </ul>
                            </td>
                          </tr>
                      );
                    })}
                  </tbody>}
                </table>
                <div className="row">
                  <div className="col-md-12">
                    <ul className="pagination">
                      <li
                        className={`page-item ${currentPage === 1 ? "disabled" : ""}`}
                      >
                        <button
                          onClick={() => paginate(currentPage - 1)}
                          className="page-link"
                          disabled={currentPage === 1}
                        >
                          Previous
                        </button>
                      </li>
                      {renderPageNumbers()}
                      <li className="page-item disabled">
        <span className="page-link">Page {currentPage} of {totalPages}</span>
      </li>
      {pageNumbers}
                      <li
                        className={`page-item ${currentPage === totalPages ? "disabled" : ""}`}
                      >
                        <button
                          onClick={() => paginate(currentPage + 1)}
                          className="page-link"
                          disabled={currentPage === totalPages}
                        >
                          Next
                        </button>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Video;
