import { toast } from "react-toastify";
import axiosInstance from "../../config/axiosInterceptor";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
function ManageWorkflowUser() {
    const [data, setData] = useState([]);
      const [page, setPage] = useState(1);
      const [totalPages, setTotalPages] = useState(10);
      const [limit, setLimit] = useState(10);
      const baseUrl = process.env.REACT_APP_BASEURL1;
    
      useEffect(() => {
        manageBdApi(page , limit);
      }, [page , limit]);

      const manageBdApi = async (page, limit) => {
        try {
        const response = await axiosInstance.post(`${baseUrl}/admin/workflowUser/showWorkflowUser`,{
            page,
            limit,
        });
          if (response && response.data && response.data.data) {
            setData(response.data.data);
            toast.success(response.data.message);
          } else {
            toast.error(response.data.message);
          }
        } catch (error) {
          toast.error(error.message);
        }
      };
    
      const deleteBd = async (id) => {
        try {
            console.log(id);
        const response = await axiosInstance.delete(`${baseUrl}/admin/workflowUser/deleteWorkflowUser/${id}`);
          if (response.data.success === 1) {
            toast.success(response.data.message);
            manageBdApi(); // Refresh BD list after deletion
          } else {
            toast.error(response.data.message);
          }
        } catch (error) {
            console.log(error)
          toast.error(error.message);
        }
      };
    

      const handlePrev = () => {
        if (page > 1) setPage(page - 1);
    };

    const handleNext = () => {
        if (page < totalPages) setPage(page + 1);
    };

    // Handler for limit change
    const handleLimitChange = (e) => {
    setLimit(parseInt(e.target.value));
    setPage(1); // Reset to the first page when limit changes
    };

    return ( 
    <>
        <section className="content-header">
                <h1>Manage workflow User</h1>
                <ol className="breadcrumb">
                  <li>
                    <Link to="/admin">
                      <i className="fa fa-dashboard"></i> Home
                    </Link>
                  </li>
                  <li className="active">Manage workflow User</li>
                </ol>
              </section>
              <section className="content">
                <div className="row">
                  <div className="col-xs-12">
                    <div className="box-header">
                      <h3 className="box-title">
                        <Link
                          to="/admin/addWorkFlowUser"
                          style={{ fontSize: "14px" }}
                          className="btn btn-block btn-success btn-xs"
                        >
                          Add workflow User
                        </Link>
                      </h3>
                    </div>
                    <div className="box">
                      <div className="box-body">
                        <div className="row" style={{ padding: "16px 0px" }}>
                          <div className="entries-per-page col-md-5 ">
                            <label htmlFor="entries">Entries per page:</label>
                            <select
                              id="entries"
                              value={limit}
                              onChange={handleLimitChange}
                            >
                              <option value={10}>10</option>
                              <option value={20}>20</option>
                              <option value={50}>50</option>
                            </select>
                          </div>
                        </div>
        
                        <div className="success-message"></div>
                        <table className="table table-bordered table-striped">
                          <thead>
                            <tr>
                              <th>Sr.</th>
                              <th>Wk user Name</th>

                              <th>Phone</th>
                              <th>OTP</th>
                              <th>Action</th>
                            </tr>
                          </thead>
                          <tbody>
                            {data.map((item, index) => (
                              <tr key={index}>
                                <td>{(page - 1) * limit + index + 1}</td>
                                <td>{item.name}</td>
                                <td>{item.number}</td>
                                <td>{item.otp}</td>
                                <td>
                                  <div className="dropdown">
                                    <button
                                      className="btn btn-default dropdown-toggle"
                                      type="button"
                                      data-toggle="dropdown"
                                      aria-haspopup="true"
                                      aria-expanded="false"
                                    >
                                      Action
                                      <span className="caret"></span>
                                    </button>
                                    <ul className="dropdown-menu ">
                                      <li>
                                        <Link
                                          to={`/admin/editWorkflowUser?id=${item._id}`}
                                          role="menuitem"
                                          tabIndex={-1}
                                        >
                                          Edit
                                        </Link>
                                      </li>
                                      <li>
                                        <Link
                                          onClick={() => {
                                            const confirmDelete = window.confirm(
                                              "Are you sure you want to delete this user ?"
                                            );
                                            if(confirmDelete){
                                              deleteBd(item._id)
                                            }
                                          }}
                                          role="menuitem"
                                          tabIndex={-1}
                                        >
                                          Delete
                                        </Link>
                                      </li>
                                    </ul>
                                  </div>
                                </td>
                              </tr>
                            ))}
                          </tbody>
                        </table>
                        <div className="row">
                          <div className="col-md-5">
                            
                                <button
                                  className="page-link"
                                  onClick={handlePrev}
                                  disabled={page === 1}
                                >
                                  Previous
                                </button>
                                <button
                                  className="page-link"
                                  onClick={handleNext}
                                  disabled={
                                    page === totalPages
                                  }
                                >
                                  Next
                                </button>
                              
                            
                          </div>
                          <div className="col-md-7 text-right">
                            <p className="total-entries">
                              Total entries: <b>{data.length}</b>
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </section>
    </>
    );
}

export default ManageWorkflowUser;