import React, { useContext, useEffect, useState, useCallback } from "react";
import { Link, useParams } from "react-router-dom";
import { global } from "../../Context/Context";
import axios from "axios";
import { toast } from "react-toastify";
import _ from "lodash";
import axiosInstance from "../../config/axiosInterceptor";

const CoinPanelTransactionLists = () => {

  const [rechargeHistory, setRechargeHistory] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [loading, setLoading] = useState(false);
  const [itemsPerPage, setItemsPerPage] = useState(10); // Default items per page
  const [totalItems, setTotalItems] = useState(0); // Total number of items
  const [search, setSearch] = useState("");
  const handlePrevPage = () => {
    setCurrentPage((prevPage) => prevPage - 1);
  };

  const handleNextPage = () => {
    setCurrentPage((prevPage) => prevPage + 1);
  };

  const handleChangeItemsPerPage = (e) => {
    setItemsPerPage(parseInt(e.target.value, 10));
    setCurrentPage(1); // Reset to first page when changing items per page
  };
  const rechargeHistoryssearch = async (value) => {
    setLoading(true)
    try {

      let data = await fetch(
        `${process.env.REACT_APP_BASEURL1}/coin/recharge/searchsendCoinHistory?userId=${uniqueId}&search=${value}`, // Include page and limit in URL
        {
          method: "GET",
        }
      );
      let response = await data.json();

      setRechargeHistory(response.history);
      setTotalItems(response.total); // Assuming response contains total count
      setLoading(false)

    } catch (err) {
      toast.error(err.message);
    }
  };
  const handleSearchChange = useCallback(
    _.debounce((value) => {
      setSearch(value);
      if (value !== "")
        rechargeHistoryssearch(value);
      else {
        rechargeHistorys({ userId: uniqueId, page: currentPage, limit: itemsPerPage });
      }
    }, 1000),
    [currentPage]
  );
  const rechargeHistorys = async (value) => {
    try {
      setLoading(true)

      let data = await fetch(`${process.env.REACT_APP_BASEURL3}/coin/recharge/history?page=${currentPage}&limit=${itemsPerPage}`, {
        method: "POST",
        body: JSON.stringify(value),
        headers: {
          "Content-Type": "application/json",
        },
      });
      let a = await data.json();

      setRechargeHistory(a.history);
      setTotalItems(a.total); // Assuming response contains total count
      setLoading(false)

    } catch (err) {
      toast.error(err.message);
    }
  };
  const { uniqueId } = useParams();
  // console.log(p);
  // console.log(rechargeHistory);
  useEffect(() => {
    rechargeHistorys({ userId: uniqueId });
    // rechargeRecievedHistorys({ userId: coinDetails.uniqueId });
  }, [currentPage, itemsPerPage]);

  const submitHandler = async (dataDetails) => {
    // e.preventDefault();
    try {
      const { data } = await axiosInstance.post(
        `${process.env.REACT_APP_BASEURL2}/admin/return/coins`,
        dataDetails
      );
      // console.log(dataDetails);
      // console.log(data);
      if (data.success == "1") {
        toast.success(data.message);
        // console.log(data);
      }
      else{
        toast.error(data.message);
      }
      
    } catch (error) {
      toast.error(error.message);
    }
  };
  const totalPages = Math.ceil(totalItems / itemsPerPage);
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  return (
    <>
      {/* Content Header (Page header) */}
      <section className="content-header">
        <h1>Transaction Lists Sent To User</h1>
        <ol className="breadcrumb">
          <li>
            <Link to="/admin">
              <i className="fa fa-dashboard"></i> Home
            </Link>
          </li>
          <li className="active">Title</li>
        </ol>
      </section>
      {/* Main content */}
      <section className="content">
        <div className="row">
          <div className="pagination-options col-xs-6">
            <select
              className="form-control pagination-select"
              value={itemsPerPage}
              onChange={handleChangeItemsPerPage}
            >
              <option value="10">10 per page</option>
              <option value="50">50 per page</option>
              <option value="100">100 per page</option>
              <option value="1000">1000 per page</option>
            </select>
          </div>
          <div className="main-data-single-field col-xs-6" style={{ width: "fit-content" }}>
            <span style={{ fontWeight: "bold", marginRight: "8px" }}>Search</span>
            <input
              type="text"
              id="pname"
              name="pname"
              style={{ borderRadius: "4px", borderStyle: "groove", width: "150px" }}
              onChange={(e) => handleSearchChange(e.target.value)}
              placeholder="Search User"
            />
          </div>
          <div className="col-xs-12">
            {/* /.box */}
            <div className="box">
              {/* /.box-header */}
              <div className="box-body">
                <table
                  id="example1"
                  className="table table-bordered table-striped"
                >
                  <thead>
                    <tr>
                      <th>Sr.</th>
                      <th>Username</th>
                      <th>Coin</th>
                      <th>Date/Time</th>
                      <th>Action</th>
                    </tr>
                  </thead>
                 {loading?<>Loading...</>: <tbody>
                    {Array.isArray(rechargeHistory) &&
                      rechargeHistory?.map((item, index) => {
                        let i = index + indexOfFirstItem + 1; // Calculate actual index
                        return (
                          <tr>
                            <td>{i}</td>
                            <td>{item?.userId}</td>
                            <td>
                              <label htmlFor="" className="label label-success">
                                {item?.coin}
                              </label>
                            </td>
                            <td>{new Date(item?.createdAt).toLocaleString()}</td>
                            <td
                              className=""
                              onClick={() => {
                                const res = window.confirm(
                                  "Are you sure you want to return coin"
                                );
                                if (res == true) {
                                  const data = {
                                    id: item._id
                                  };
                                  submitHandler(data);
                                  // console.log(data);
                                }
                              }}
                            >
                              <button
                                style={{
                                  backgroundColor: "red",
                                  color: "white",
                                  fontWeight: "bold",
                                  border: "none",
                                  padding: ".3rem",
                                  paddingLeft: ".5rem",
                                  paddingRight: ".5rem",
                                }}
                              >
                                Return
                              </button>
                            </td>
                          </tr>
                        );
                      })}
                  </tbody>}
                </table>
                <div className="pagination-container">
                  <button
                    className="btn btn-default"
                    onClick={handlePrevPage}
                    disabled={currentPage === 1}
                  >
                    Previous
                  </button>

                  <button
                    className="btn btn-default"
                    onClick={handleNextPage}
                    disabled={currentPage === totalPages}
                  >
                    Next
                  </button>
                  <div className="">
                    <div className="total-entries" style={{ float: "right", marginTop: "20px" }}>
                      Total entries: <b>{totalItems}</b>
                    </div>
                  </div>
                </div>
                {/* PHP session message removed */}
              </div>
              {/* /.box-body */}
            </div>
            {/* /.box */}
          </div>
          {/* /.col */}
        </div>
        {/* /.row */}
      </section>
      {/* /.content */}
    </>
  );
};

export default CoinPanelTransactionLists;
