import axios from 'axios';

// Create an Axios instance
const axiosInstance = axios.create({
  baseURL: process.env.REACT_APP_BASEURL5, // Replace with your API's base URL
});

// Add a request interceptor
axiosInstance.interceptors.request.use(
  (config) => {
    // Retrieve the token from local storage or any secure storage
    const token = sessionStorage.getItem('_TOKEN_'); // Replace with your token retrieval logic

    // If a token exists, add it to the Authorization header
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }

    return config;
  },
  (error) => {
    // Handle request errors
    return Promise.reject(error);
  }
);
axiosInstance.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    if (error.response && error.response.status === 401) {
      // Remove all session data
      sessionStorage.clear();
      
      // Navigate to Picture page
      
      window.location.href = '/'; // Replace with your desired route
    }
    return Promise.reject(error);
  }
);


// Export the Axios instance
export default axiosInstance;
