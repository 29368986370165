import React from "react";
import { Link, useNavigate } from "react-router-dom";
import $ from "jquery";
import { toast } from "react-toastify";
import axiosInstance from "../../config/axiosInterceptor";

const AddAgency = () => {
  const navigate = useNavigate();
  const subbbb = async(ev) => {
    ev.preventDefault();

    var a = $("input[name='name']").val();
    var b = $("input[name='email']").val();
    var c = $("input[name='number']").val();
    var d = $("input[name='password']").val();
    var e = $("input[name='image']")[0].files[0];
    var f = $("input[name='bd']").val();
    var g = $("input[name='admin']").val();

    let text = "This field cannot be empty";
    if (a === "") {
      $(".form-error1").html(text);
    }
    if (b === "") {
      $(".form-error2").html(text);
    }
    if (c === "") {
      $(".form-error3").html(text);
    }
    if (d === "") {
      $(".form-error4").html(text);
    }
    if (e === "") {
      $(".form-error5").html(text);
    }
    if (f === "") {
      $(".form-error5").html(text);
    }
    if (g === "") {
      $(".form-error5").html(text);
    } else {
      let formData = new FormData();
      if(ag){
        
        formData.append("type", "1");
      }
      formData.append("name", a);
      formData.append("email", b);
      formData.append("number", c);
      formData.append("password", d);
      formData.append("image", e);
      formData.append("bd", f);
      formData.append("admin", g);
      ev.target.reset()
      await axiosInstance.post(`${process.env.REACT_APP_BASEURL1}/admin/agencyPanel/addAgencyPanel`, formData, {
        headers: {
            "Content-Type": "multipart/form-data",
        },
    })
    .then((res) => {
        if (res.data.success === 1) {
            toast.success(res.data.message);
            if (bd) {
                navigate("/bd/manageAgency");
            } else if (ag) {
                navigate("/agencyadmin/manageAgency");
            } else if (aa) {
                navigate("/admin/manageAgency");
            }
        } else {
            toast.error(res.data.message);
        }
    })
    .catch((error) => {
        toast.error(error.message);
        console.error(error);
    });
    }
  };

  let ag = sessionStorage.getItem('agencyadmin');
  let bd = sessionStorage.getItem('bd');
  let aa = sessionStorage.getItem('data');
  let uniqueId;
  let bdId;
  
  if(ag)
  {
      let ad = JSON.parse(ag);
      uniqueId = ad.uniqueId;
      bdId = ad.createBy;
  }

  return (
    <>
      <section className="content-header">
        <h1>Add Agency</h1>
       
      </section>
      <section className="content">
        <div className="row">
          <div className="col-md-10">
            <form
              role="form"
              method="post"
              onSubmit={subbbb}
              encType="multipart/form-data"
            >
              <div className="box box-warning">
                <div className="box-header with-border">
                  <h3 className="box-title">Add</h3>
                </div>
                <div className="box-body">
                  <div className="form-group">
                    <label>Agency Name*</label>
                    <input
                      type="text"
                      className="form-control"
                      name="name"
                      placeholder="Agency Name"
                      onChange={() => {
                        $(".form-error1").html("");
                      }}
                    />
                    <div className="form-error1 text-danger"></div>
                  </div>
                  <div className="form-group">
                    <label>Agency Email*</label>
                    <input
                      type="text"
                      className="form-control"
                      name="email"
                      placeholder="Agency Email"
                      onChange={() => {
                        $(".form-error2").html("");
                      }}
                    />
                    <div className="form-error2 text-danger"></div>
                  </div>
                  <div className="form-group">
                    <label>Agency Mobile*</label>
                    <input
                      type="text"
                      className="form-control"
                      name="number"
                      placeholder="Agency Mobile"
                      onChange={() => {
                        $(".form-error3").html("");
                      }}
                    />
                    <div className="form-error3 text-danger"></div>
                  </div>
                  <div className="form-group">
                    <label>BD*</label>
                    <input
                      type="text"
                      className="form-control"
                      name="bd"
                      placeholder="BD uniqueId"
                      value={bdId}
                      readOnly = {!aa?true:false}
                      onChange={() => {
                        $(".form-error3").html("");
                      }}
                    />
                    <div className="form-error3 text-danger"></div>
                  </div>
                  <div className="form-group">
                    <label>Agency Admin*</label>
                    <input
                      type="text"
                      className="form-control"
                      name="admin"
                      placeholder="Admin uniqueId"
                      value={uniqueId}
                      readOnly = {!aa?true:false}
                      onChange={() => {
                        $(".form-error3").html("");
                      }}
                    />
                    <div className="form-error3 text-danger"></div>
                  </div>
                  <div className="form-group">
                    <label>Agency Password*</label>
                    <input
                      type="text"
                      className="form-control"
                      name="password"
                      placeholder="Agency Password"
                      onChange={() => {
                        $(".form-error4").html("");
                      }}
                    />
                    <div className="form-error4 text-danger"></div>
                  </div>
                  <div className="form-group">
                    <label>Agency Image*</label>
                    <input
                      type="file"
                      className="form-control"
                      name="image"
                      onChange={() => {
                        $(".form-error5").html("");
                      }}
                    />
                    <div className="form-error5  text-danger"></div>
                  </div>
                  {/* Repeat other form fields as needed */}
                  <div className="form-group">
                    <button type="reset" className="btn btn-danger">
                      Cancel
                    </button>
                    <input
                      type="submit"
                      className="btn btn-success pull-right"
                      name="submit"
                      defaultValue="Submit"
                    />
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </section>
    </>
  );
};

export default AddAgency;
