import React, { useRef, useState } from 'react';
import html2canvas from 'html2canvas';
import axios from 'axios';
import axiosInstance from '../../config/axiosInterceptor';


const FamilyPKBattleRoundFinal = () => {
  let maxLetter = 12;
  const componentRef = useRef(null);
  const [leftUsername, setLeftUsername] = useState('');
  const [rightUsername, setRightUsername] = useState('');
  const [leftImage, setLeftImage] = useState(null);
  const [rightImage, setRightImage] = useState(null);
  const [battleDate, setBattleDate] = useState('');
  const [battleTime, setBattleTime] = useState('');
  const [leftFamilyName, setLeftFamilyName] = useState('');
  const [rightFamilyName, setRightFamilyName] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [loading, setLoading] = useState(false);
  const [loadingD, setLoadingD] = useState(false);

  const handleImageUpload = (e, setImage) => {
    const file = e.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setImage(reader.result);
      };
      reader.readAsDataURL(file);
    }
  };

  const formatTime = (time) => {
    const [hours, minutes] = time.split(':');
    const formattedHours = ((+hours % 12) || 12).toString();
    const ampm = +hours >= 12 ? 'PM' : 'AM';
    return `${formattedHours}:${minutes} ${ampm}`;
  };

  const fetchFamilyName = async (username) => {
    try {
      const response = await axiosInstance.get(`${process.env.REACT_APP_BASEURL1}/admin/familyImage/getForBanner?username=${username}`);
      if (response.data.success) {
        return response.data || null;
      } else {
        return null;
      }
    } catch (error) {
      console.error('Error fetching family name:', error);
      return null;
    }
  };

  const findFamilyNames = async () => {
    setLoading(true);
    const leftFamily = await fetchFamilyName(leftUsername);
    const rightFamily = await fetchFamilyName(rightUsername);

    if (!leftFamily || !rightFamily) {
      setErrorMessage('Please check usernames, family not found for one or both users.');
    } else {
      setLeftFamilyName(leftFamily?.familyName);
      setRightFamilyName(rightFamily?.familyName);
      setLeftImage(leftFamily?.userImage)
      setRightImage(rightFamily?.userImage)
      setErrorMessage('');
    }
    setLoading(false);
  };
  const capitalizeFirstLetter = (text) => {
    return text.charAt(0).toUpperCase() + text.slice(1).toLowerCase();
  };
  const handleDownload = () => {
    setLoadingD(true)
    if (componentRef.current) {
      html2canvas(componentRef.current, { useCORS: true }).then((canvas) => {
        const link = document.createElement('a');
        link.href = canvas.toDataURL('image/png');
        link.download = 'Family_PK_Battle_Overlay.png';
        link.click();
      });
    }
    setLoadingD(false)
  };
  return (
    <div className="container mt-5">
      <h2 className="text-center mb-4">Create Family PK Battle Banner</h2>

      {/* Input Form */}
      <form className="mb-4">
        <div className="row mb-3">
          <div className="col-md-6">
            <label className="form-label">Left Username</label>
            <input
              type="text"
              className="form-control"
              value={leftUsername}
              onChange={(e) => setLeftUsername(e.target.value)}
              placeholder="Enter left username"
            />
          </div>
          <div className="col-md-6">
            <label className="form-label">Right Username</label>
            <input
              type="text"
              className="form-control"
              value={rightUsername}
              onChange={(e) => setRightUsername(e.target.value)}
              placeholder="Enter right username"
            />
          </div>
        </div>
        {/* <div className="row mb-3">
          <div className="col-md-6">
            <label className="form-label">Left Image</label>
            <input
              type="file"
              className="form-control"
              accept="image/*"
              onChange={(e) => handleImageUpload(e, setLeftImage)}
            />
          </div>
          <div className="col-md-6">
            <label className="form-label">Right Image</label>
            <input
              type="file"
              className="form-control"
              accept="image/*"
              onChange={(e) => handleImageUpload(e, setRightImage)}
            />
          </div>
        </div> */}
        <div className="row mb-3">
          <div className="col-md-6">
            <label className="form-label">Battle Date</label>
            <input
              type="date"
              className="form-control"
              value={battleDate}
              onChange={(e) => setBattleDate(e.target.value)}
            />
          </div>
          <div className="col-md-6">
            <label className="form-label">Battle Time</label>
            <input
              type="time"
              className="form-control"
              value={battleTime}
              onChange={(e) => setBattleTime(e.target.value)}
            />
          </div>
        </div>
      </form>
      <button
        onClick={findFamilyNames}
        disabled={loading}
        className={`btn ${loading ? 'btn-warning' : 'btn-success'} mb-3`}
        style={{ display: 'block', margin: '12px auto' }}
      >
        {loading ? "Finding Families..." : "Find Family"}
      </button>

      {errorMessage && (
        <div className="alert alert-danger text-center" role="alert">
          {errorMessage}
        </div>
      )}

      {/* Overlay Component */}
      <div
        ref={componentRef}
        style={{
          position: 'relative',
          width: '600px',
          height: '600px',
          margin: '0 auto',
          backgroundColor: '#333',
        }}
      >
        <img
          src="/pk_legend.jpg"
          alt="Background"
          style={{ width: '100%', height: '100%' }}
        />


        {leftImage && (
          <div
            style={{
              position: 'absolute',
              bottom: '138px',
              left: '34px',
              width: '210px',
              height: '212px',
              padding: '5px',
              //   backgroundColor: '#FFD700',
              borderRadius: '5%',
              //   transform: 'rotate(-6deg)',
              boxShadow: '0px 0px 15px rgba(0, 0, 0, 0.5)',
            }}
          >
            <img
              src={leftImage}
              alt="Left User"
              style={{
                width: '100%',
                height: '100%',
                borderRadius: '5%',
              }}
            />
          </div>
        )}

        {/* Right User Image */}
        {rightImage && (
          <div
            style={{
              position: 'absolute',
              bottom: '138px',
              right: '34px',
              width: '210px',
              height: '212px',
              padding: '5px',
              //   backgroundColor: '#FFD700',
              borderRadius: '5%',
              //   transform: 'rotate(-6deg)',
              boxShadow: '0px 0px 15px rgba(0, 0, 0, 0.5)',
            }}
          >
            <img
              src={rightImage}
              alt="Right User"
              style={{
                width: '100%',
                height: '100%',
                borderRadius: '5%',
              }}
            />
          </div>
        )}

        {/* Left Family */}
        <div
          style={{
            position: 'absolute',
            bottom: '94px',
            left: '84px',
            color: '#000000',
            fontWeight: '900',
            fontSize: '18px',
          }}
        >
          {leftFamilyName && leftFamilyName.length > maxLetter
            ? `${capitalizeFirstLetter(leftFamilyName.slice(0, maxLetter))}...`
            : capitalizeFirstLetter(leftFamilyName) || 'Left Family'}
        </div>

        {/* Right Family */}
        <div
          style={{
            position: 'absolute',
            bottom: '94px',
            right: '84px',
            color: '#000000',
            fontWeight: '900',
            fontSize: '18px',
          }}
        >
          {rightFamilyName && rightFamilyName.length > maxLetter
            ? `${capitalizeFirstLetter(rightFamilyName.slice(0, maxLetter))}...`
            : capitalizeFirstLetter(rightFamilyName) || 'Right Family'}
        </div>
        {/* Left Username */}
        <div
          style={{
            position: 'absolute',
            bottom: '60px',
            left: '94px',
            color: '#000000',
            fontWeight: '900',
            fontSize: '14px',
          }}
        >
          {leftUsername && leftUsername.length > maxLetter - 2
            ? `${leftUsername.slice(0, maxLetter - 2)}...`
            : leftUsername || 'Left User'}
        </div>

        {/* Right Username */}
        <div
          style={{
            position: 'absolute',
            bottom: '60px',
            right: '95px',
            color: '#000000',
            fontWeight: '900',
            fontSize: '14px',
          }}
        >
          {rightUsername && rightUsername.length > maxLetter - 2
            ? `${rightUsername.slice(0, maxLetter - 2)}...`
            : rightUsername || 'Right User'}
        </div>

        {/* Date */}
        {/* <div
          style={{
            position: 'absolute',
            top: '44px',
            left: '74px', // Positions the date from the left
            fontSize: '16px',
            fontWeight: 'bold',
            color: 'white',
          }}
        >
          <text fontSize="22" fontWeight="bold" fill="white">
            {battleDate &&
              `${new Date(battleDate).toLocaleDateString('en-US', { day: '2-digit', month: 'short', year: '2-digit' })}`}
          </text>
        </div> */}

        {/* Time */}
        {/* <div
          style={{
            position: 'absolute',
            top: '44px',
            right: '76px', // Positions the time from the right
            fontSize: '16px',
            fontWeight: 'bold',
            color: 'white',
            textAlign: 'right', // Optional, aligns text inside the div to the right
          }}
        >
          <text fontSize="22" fontWeight="bold" fill="white">
            {battleTime && `${formatTime(battleTime)}`}
          </text>
        </div> */}
        {/* Date and Time */}
        <div
          style={{
            position: 'absolute',
            bottom: '-18px',
            width: '100%',
            textAlign: 'center',
            fontSize: '14px',
            fontWeight: 'bold',
            color: 'white',
          }}
        >
          {/* <svg width="100%" height="80">
            <defs>
              <path
                id="curvePath"
                d="M 50,50 C 200,10 400,10 550,50"
              />
            </defs>
            <text fontSize="14" fontWeight="bold" fill="white">
              <textPath href="#curvePath" startOffset="50%" textAnchor="middle">
                {battleTime && battleDate &&
                  `Time: ${formatTime(battleTime)}, Date: ${new Date(battleDate).toLocaleDateString('en-US', { day: '2-digit', month: 'short', year: '2-digit' })}`}
              </textPath>
            </text>
          </svg> */}
          <svg width="100%" height="80">
            <text x="50%" y="50%" fontSize="14" fontWeight="bold" fill="white" textAnchor="middle" dominantBaseline="middle">
              {battleTime && battleDate &&
                `Time: ${formatTime(battleTime)}, Date: ${new Date(battleDate).toLocaleDateString('en-US', { day: '2-digit', month: 'short', year: '2-digit' })}`}
            </text>
          </svg>
        </div>


      </div>

      {/* Download Button */}
      <button
        onClick={handleDownload}
        className="btn btn-primary mt-3"
        disabled={loadingD}
        style={{ display: 'block', margin: '0 auto' }}
      >
        {loadingD ? "Downloading..." : "Download Image"}
      </button>
    </div>
  );
};

export default FamilyPKBattleRoundFinal;
