import React, { useState } from "react";
import { Link } from "react-router-dom";
import $ from "jquery";
import { toast } from "react-toastify";
import axios from "axios";
import axiosInstance from "../../config/axiosInterceptor";

const ReturnCoins = () => {
  const coinData = sessionStorage.getItem("coin");
  const coinDetails = JSON.parse(coinData);
  const [dataDetails, setDataDetails] = useState({
    username: "",
    coins: "",
    senderName: "",
  });
  // console.log(coinDetails);
  // fetch user details
  const checkuser = async () => {
    var userId = $("input[name='username']").val();

    $("#form-error1").remove();
    await axiosInstance.get(`${process.env.REACT_APP_BASEURL5}/coin/recharge/sendCoinfetchuser?userId=${userId}`).then((res)=>{
        if (res.data.success === 1) {
          if (res.data.details.username != "") {
            var div = `
                  <div className="form-error1" id="form-error1" style="display:flex; color:gray; margin-top: 15px;">
                    <img src=${res.data.details.picture} style='border-radius:50% ; height:50px; width:50px;' alt="User Profile" />
                    <span style='margin-left: 15px; '>${res.data.details.username} <br /> ${res.data.details.name}</span>
                  </div>
                `;
          }
          $(".form-error4").html("");
          $("#user").append(div);
        } else {
          $(".form-error4").html("no user found");
        }
      })
  };
  const checkCoinPanel = async () => {
    var userId = $("input[name='senderName']").val();
    if (userId.startsWith("coin@")) {
      $("#form-error1").remove();
      await axiosInstance.get(`${process.env.REACT_APP_BASEURL5}/coin/recharge/sendCoinfetchagency?userId=${userId}`).then((res)=>{
        if (res.data.success === 1) {
          if (res.data.details.username != "") {
            var div = `
                      <div className="form-error1" id="form-error1" style="display:flex; color:gray; margin-top: 15px;">
                        <img src=${res.data.details.image} style='border-radius:50% ; height:50px; width:50px;' alt="User Profile" />
                        <span style='margin-left: 15px; '>${res.data.details.uniqueId} <br /> ${res.data.details.name}</span>
                      </div>
                    `;
          }
          $(".form-error4").html("");
          $("#coin").append(div);
        } else {
          $(".form-error4").html("no user found");
        }
      })
    }
  };

  const onChangeSubmit = (e) => {
    setDataDetails({ ...dataDetails, [e.target.name]: e.target.value });
  };

  const submitHandler = async (e) => {
    e.preventDefault();
    await axiosInstance.post(
      `${process.env.REACT_APP_BASEURL1}/admin/return/coins`,
      dataDetails
    ).then((data)=>{
      // console.log(dataDetails);
    if (data.data.success == "1") {
      toast.success(data.data.message);
      // console.log(data);
    }else{
      toast.error(data.data.message);
    }
    })
  };

  return (
    <>
      <section className="content-header">
        <h1>Return Coins</h1>
        <ol className="breadcrumb">
          <li>
            <Link to="/coin/dashboard">
              <i className="fa fa-dashboard"></i> Home
            </Link>
          </li>
          <li className="active">Return Coins</li>
        </ol>
      </section>
      <section className="content">
        <div className="row">
          <div className="col-md-12">
            <form>
              <div className="box box-warning">
                <div className="box-header with-border">
                  <h3 className="box-title">Return</h3>
                </div>
                <div className="box-body">
                  <div className="form-group" id="user">
                    <label>Username*</label>
                    <input
                      type="text"
                      className="form-control"
                      name="username"
                      placeholder="username"
                      onChange={(e) => {
                        $(".form-error4").html("");
                        checkuser();
                        onChangeSubmit(e);
                      }}
                    />
                    <div className="form-error4 text-danger"></div>
                  </div>
                  <div className="form-group" id="coin">
                    <label>Sender Name*</label>
                    <input
                      type="text"
                      className="form-control"
                      name="senderName"
                      placeholder="If admin then type admin or coin panel uniqueId"
                      onChange={(e) => {
                        $(".form-error4").html("");
                        checkCoinPanel();
                        onChangeSubmit(e);
                      }}
                    />
                  </div>
                  <div className="form-group">
                    <label>Coins*</label>
                    <input
                      type="text"
                      className="form-control"
                      name="coins"
                      placeholder="Coins"
                      onChange={(e) => {
                        $(".form-error5").html("");
                        onChangeSubmit(e);
                      }}
                    />
                    <div className="form-error5 text-danger"></div>
                  </div>

                  <div className="form-group d-flex justify-content-center">
                    <button type="reset" className="btn btn-danger">
                      Cancel
                    </button>

                    <span onClick={submitHandler}>
                      <input
                        type="submit"
                        className="btn btn-success pull-right"
                        name="submit"
                        defaultValue="Submit"
                      />
                    </span>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </section>
    </>
  );
};

export default ReturnCoins;
