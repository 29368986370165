import React, { useState } from "react";
import { Link } from "react-router-dom";
import axios from "axios";
import { toast } from "react-toastify";
import axiosInstance from "../../config/axiosInterceptor";

const AgencAdminAdd = () => {
  const baseUrl = process.env.REACT_APP_BASEURL1;
  const [bdId, setbdId] = useState("")
  // const bdId = JSON.parse(sessionStorage.getItem("bd"));
  // const uniqueId = bdId ? bdId.uniqueId : "";
  // const creatBy = bdId ? bdId.createBy : "";
  // console.log(uniqueId);
  const handleSubmit = async (event) => {
    event.preventDefault();

    const formData = new FormData(event.target);
console.log(formData)
    try {
      const response = await axiosInstance.post(
        `${baseUrl}/agencadminpanel/createagencyadmin`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );
      console.log(response);
      console.log("API Response:", response.data);
      if(response.data.success==1)
      toast.success("AgencyAdmin panel successfully added");
    else
    toast.error(response.data.message);

      event.target.reset();
    } catch (error) {
      console.error("Error fetching data:", error);
      toast.error(error.message);
    }
  };
  return (
    <>
      <section className="content-header">
        <h1>Add Agencyadmin</h1>
        <ol className="breadcrumb">
          <li>
            <Link to="/admin">
              <i className="fa fa-dashboard"></i> Home
            </Link>
          </li>
          <li>
            <Link to="/admin/manageagencyadmin">Manage Agencyadmin</Link>
          </li>
          <li className="active">Add Agencyadmin</li>
        </ol>
      </section>
      <section className="content">
        <div className="row">
          <div className="col-md-10">
            <form
              role="form"
              onSubmit={handleSubmit}
              encType="multipart/form-data"
            >
              <div className="box box-warning">
                <div className="box-header with-border">
                  <h3 className="box-title">Add</h3>
                </div>
                <div className="box-body">
                  <div className="form-group">
                    <label>Agencyadmin Name*</label>
                    <input
                      type="text"
                      className="form-control"
                      name="name"
                      placeholder="Agencyadmin Name"
                    />
                  </div>
                  <div className="form-group">
                    <label>Agencyadmin Email*</label>
                    <input
                      type="text"
                      className="form-control"
                      name="email"
                      placeholder="Agencyadmin Email"
                    />
                  </div>
                  <div className="form-group">
                    <label>Agencyadmin Mobile*</label>
                    <input
                      type="text"
                      className="form-control"
                      name="number"
                      placeholder="Agencyadmin Mobile"
                    />
                  </div>
                  <div className="form-group">
                    <label>BD Unique Id*</label>
                    <input
                      type="text"
                      className="form-control"
                      name="createBy"
                      placeholder="BD Unique Id"
                    />
                  </div>

                  <div className="form-group">
                    <label>Agencyadmin Password*</label>
                    <input
                      type="text"
                      className="form-control"
                      name="password"
                      placeholder="Agencyadmin Password"
                    />
                  </div>
               
                  <div className="form-group">
                    <label>Agencyadmin Image*</label>
                    <input type="file" className="form-control" name="image" />
                  </div>

                  <div className="form-group">
                    <button type="reset" className="btn btn-danger">
                      Cancel
                    </button>
                    <button
                      type="submit"
                      className="btn btn-success pull-right"
                    >
                      Submit
                    </button>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </section>
    </>
  );
};
export default AgencAdminAdd;
