
import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import axios from "axios"; // corrected import statement
import axiosInstance from "../config/axiosInterceptor"
import {toast} from "react-toastify"

const baseUrl = process.env.REACT_APP_BASEURL;
const LiveTime = () => {
  const [editable, setEditable] = useState(false);
  const [days, setDays] = useState("");
  const [timeInMinutes, setTimeInMinutes] = useState("");
  const [id, setid] = useState("");
  const [coinTime , setCoinTime] = useState(0);
  const [coinAmount , setCoinAmount] = useState(0)
  useEffect(() => {
    // Fetch data from the backend API when the component mounts
    const fetchData = async () => {
      try {
        const response = await axiosInstance.get(
          `${process.env.REACT_APP_BASEURL5}/admin/livetime/getlivetime`
        )
        // Adjust the API endpoint as needed
        const responseData = response.data;
        setid(responseData.details._id);
        console.log(responseData);
        if (responseData.success) {
          
          // Update state with the fetched data
          setCoinAmount(responseData.details.coinAmount);
          setCoinTime(responseData.details.coinTime);
          setDays(responseData.details.days); // Assuming the days data is received as responseData.days
          setTimeInMinutes(responseData.details.time); // Assuming the timeInMinutes data is received as responseData.timeInMinutes
        } else {
          console.error("Error:", responseData.message);
        }
      } catch (error) {
        console.error("Error:", error);
      }
    };

    fetchData();
  }, []); // Empty dependency array to only run this effect once on component mount

  const handleEditClick = () => {
    setEditable(true);
  };

  const handleCancelClick = () => {
    setEditable(false);
    // Reset input values on cancel
    setDays("");
    setTimeInMinutes("");
    setCoinAmount(0);
    setCoinTime(0);
  };
  const handleChange = (e) => {
    const { name, value } = e.target;
    // Update state based on input field name
    if (name === "days") {
      setDays(value);
    } else if (name === "timeInMinutes") {
      setTimeInMinutes(value);
    } else if (name === "coinTime"){
      setCoinTime(value)
    } else if(name === "coinAmount"){
      setCoinAmount(value);
    }
  };
  const handleSaveClick = async () => {
    setEditable(false);

    try {
      const response = await axiosInstance.put(
        `${process.env.REACT_APP_BASEURL5}/admin/livetime/editlivetime?id=${id}`,{days:days,time:timeInMinutes,coinTime:coinTime,coinAmount:coinAmount}
      );
      const responseData = response.data;
      // console.log(responseData);
      const data1 = responseData.data;
      // console.log(data1);
      if (responseData.success) {
        toast.success("Done")
        // Assuming responseData contains updated data
        const updatedDays = data1.days;
        const updatedTime = data1.time;
        setDays(updatedDays); // Update days state
        setTimeInMinutes(updatedTime); // Update timeInMinutes state
        setCoinAmount(data1.coinAmount);
        setCoinTime(data1.coinTime);
      } else {
        toast.error(responseData.message)
      }
    } catch (error) {
      toast.error(error.message)
    }
  };

  // const handleChange = (e) => {
  //   const { name, value } = e.target;
  //   // Update state based on input field name
  //   if (name === "days") {
  //     setDays(value);
  //   } else if (name === "timeInMinutes") {
  //     setTimeInMinutes(value);
  //   }
  // };

  return (
    <>
      <section className="content-header">
        <h1>Manage Live Time</h1>
        <ol className="breadcrumb">
          <li>
            <Link to="/admin">
              <i className="fa fa-dashboard"></i> Home
            </Link>
          </li>
        
        </ol>
      </section>
      <section className="content">
        <div className="row">
          <div className="col-md-12">
            <form
              role="form"
              method="post"
              encType="multipart/form-data"
              id="form"
            >
              <div className="box box-warning">
                <div className="box-header with-border">
                  <h3 className="box-title">Manage</h3>
                </div>
                <div className="box-body">
                  <div className="form-group">
                    <label htmlFor="days">Like Amount</label>
                    <input
                      type="text"
                      className="form-control"
                      name="days"
                      value={days}
                      onChange={handleChange}
                      disabled={!editable}
                    />
                  </div>
                </div>
                <div className="box-body">
                  <div className="form-group">
                    <label htmlFor="timeInMinutes">Live Time In Minutes</label>
                    <input
                      type="text"
                      className="form-control"
                      name="timeInMinutes"
                      value={timeInMinutes}
                      onChange={handleChange}
                      disabled={!editable}
                    />
                  </div>
                </div>
                <div className="box-body">
                  <div className="form-group">
                    <label htmlFor="timeInMinutes">Coin Time In Minutes</label>
                    <input
                      type="number"
                      className="form-control"
                      name="coinTime"
                      value={coinTime}
                      onChange={handleChange}
                      disabled={!editable}
                    />
                  </div>
                </div>
                <div className="box-body">
                  <div className="form-group">
                    <label htmlFor="timeInMinutes">Coin Amount</label>
                    <input
                      type="number"
                      className="form-control"
                      name="coinAmount"
                      value={coinAmount}
                      onChange={handleChange}
                      disabled={!editable}
                    />
                  </div>
                </div>
              </div>
              {editable ? (
                <>
                  <button
                    type="button"
                    className="btn btn-primary"
                    style={{ marginLeft: "20px" }}
                    onClick={handleSaveClick}
                  >
                    Save
                  </button>
                  <button
                    type="button"
                    className="btn btn-danger"
                    style={{ marginLeft: "20px" }}
                    onClick={handleCancelClick}
                  >
                    Cancel
                  </button>
                </>
              ) : (
                <button
                  type="button"
                  className="btn btn-primary"
                  style={{ marginLeft: "20px" }}
                  onClick={handleEditClick}
                >
                  Edit
                </button>
              )}
            </form>
          </div>
        </div>
      </section>
      <hr />
      <LiveData/>
    </>
  );
};
const LiveData = () => {
  const [transactions, setTransactions] = useState([]);
  const [totalAmount, setTotalAmount] = useState(0);
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(10); // Default limit value
  const [loading, setLoading] = useState(false);
  const [totalPages, setTotalPages] = useState(0);

  // Date filters
  const today = new Date().toISOString().split("T")[0]; // YYYY-MM-DD
  const [startDate, setStartDate] = useState(today);
  const [endDate, setEndDate] = useState(today);

  const fetchTransactions = async () => {
      setLoading(true);
      try {
          const response = await axiosInstance.get(`${process.env.REACT_APP_BASEURL5}/admin/withdrawal/liveLikeData`, {
              params: {
                  page,
                  limit, // Sending the selected limit to backend
                  startDate,
                  endDate,
              },
          });
          const { data, totalAmount, pagination } = response.data;
          setTransactions(data);
          setTotalAmount(totalAmount);
          setTotalPages(Math.ceil(pagination.total / limit));
      } catch (error) {
          console.error("Error fetching transactions:", error);
      } finally {
          setLoading(false);
      }
  };

  useEffect(() => {
      fetchTransactions();
  }, [ page, startDate, endDate, limit]); // Adding limit to dependency array

  const handleLimitChange = (e) => {
      setLimit(Number(e.target.value)); // Update the limit when the dropdown value changes
      setPage(1); // Reset to the first page when limit changes
  };

  return (
      <div className="container mt-12" style={{ marginTop:"40px"}}>
          {/* Filters */}
          <div className="mb-4">
          

              <div className="d-flex justify-content-start mb-4">
                  <div className="form-group mx-2">
                      <label className="form-label" htmlFor="startDate">Start Date</label>
                      <input
                          type="date"
                          id="startDate"
                          value={startDate}
                          onChange={(e) => setStartDate(e.target.value)}
                          className="form-control"
                      />
                  </div>
                  <div className="form-group mx-2">
                      <label className="form-label" htmlFor="endDate">End Date</label>
                      <input
                          type="date"
                          id="endDate"
                          value={endDate}
                          onChange={(e) => setEndDate(e.target.value)}
                          className="form-control"
                      />
                  </div>
                  <div className="form-group mx-2">
                      <label className="form-label" htmlFor="limit">Entries per page</label>
                      <select
                          id="limit"
                          value={limit}
                          onChange={handleLimitChange}
                          className="form-control"
                      >
                          <option value={10}>10</option>
                          <option value={50}>50</option>
                          <option value={100}>100</option>
                          <option value={500}>500</option>
                      </select>
                  </div>
              </div>
          </div>
          {/* Pagination */}
          <div className="d-flex justify-content-center mt-4">
              <button
                  disabled={page === 1}
                  onClick={() => setPage((prev) => prev - 1)}
                  className="btn btn-secondary mx-2"
              >
                  Previous
              </button>
              <span className="align-self-center">{`Page ${page} of ${totalPages}`}</span>
              <button
                  disabled={page === totalPages}
                  onClick={() => setPage((prev) => prev + 1)}
                  className="btn btn-secondary mx-2"
              >
                  Next
              </button>
          </div>
          {/* Transactions Table */}
          {loading ? (
              <div className="text-center"><p>Loading...</p></div>
          ) : (
              <div>
                  <h2 className="h5 font-weight-bold mb-2">Total Amount: {totalAmount}</h2>
                  <table className="table table-bordered">
                      <thead className="thead-light">
                          <tr>
                              <th className="text-center">SNo</th>
                              <th>Date</th>
                              <th>UserName</th>
                              <th>Amount</th>
                          </tr>
                      </thead>
                      <tbody>
                          {transactions?.map((transaction, index) => (
                              <tr key={transaction._id}>
                                  <td className="text-center">{(page - 1) * limit + index + 1}</td>
                                  <td>{new Date(transaction.createdAt).toLocaleString()}</td>
                                  <td>{transaction?.userId?.username}</td>
                                  <td>{transaction.coins}</td>
                              </tr>
                          ))}
                      </tbody>
                  </table>
              </div>
          )}


      </div>
  );
};
export default LiveTime;
