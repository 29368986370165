import React, { useContext, useState } from "react";
import $ from "jquery";
import { toast } from "react-toastify";
import { global } from "../../Context/Context";
import axiosInstance from "../../config/axiosInterceptor";

const Profile = () => {
  const { AgencyResetPassword } = useContext(global);
  const BaseUrl = process.env.REACT_APP_BASEURL1;
  let storedName = sessionStorage.getItem("data");
  let details = JSON.parse(storedName);

  const [credentials, setCredentails] = useState({});
  const changePassword = async () => {
      // console.log(credentials)
      setCredentails({
        currentpassword: document.getElementById("currp").value,
        newpassword: document.getElementById("newp").value,
        confirmpassword: document.getElementById("confirmp").value,
      });
      console.log(credentials);
      await axiosInstance.put(`${BaseUrl}/agency/passwordReset/${details._id}`, {
        body: JSON.stringify({
          currentpassword: document.getElementById("currp").value,
          newpassword: document.getElementById("newp").value,
          confirmpassword: document.getElementById("confirmp").value,
          // oldPassword: document.getElementById("currp").value,
          // newPassword: document.getElementById("newp").value,
          // confirmPassword: document.getElementById("confirmp").value,
        })
      }).then((res)=>{
        if (res.data.success === 1) {
          toast.success(res.data.message);
          sessionStorage.setItem("data", JSON.stringify(res.data.details));
        } else {
          toast.error(res.data.message);
        }
      }).catch ((err)=>{
      toast.error(err.message);
    })
  };

  const [readOnly, setReadOnly] = useState(true); // State variable to track readonly status

  const toggleReadOnly = () => {
    setReadOnly(!readOnly); // Toggle readonly status
  };

  const subbbb = async(e) => {
    e.preventDefault();
    let formData = new FormData(e.target); // Use e.target to access the form element

    // niru 27/2 

    await axiosInstance.post(`${process.env.REACT_APP_BASEURL5}/admin/profile/editprofile`,formData).then((res)=>{
      if (res.data.success === 1) {
        toast.success(res.data.message);
        sessionStorage.setItem("data", JSON.stringify(res.data.details));
        toggleReadOnly();
      } else {
        toast.error(res.data.message);
      }
    }).catch((e)=>toast.error(e.message));
    
    // $.ajax({
    //   url: `${process.env.REACT_APP_BASEURL}/admin/profile/editprofile`,
    //   type: "post",
    //   dataType: "json",
    //   data: formData,
    //   contentType: false,
    //   processData: false,
    //   success: function (res) {
    //     if (res.success === 1) {
    //       toast.success(res.message);
    //       sessionStorage.setItem("data", JSON.stringify(res.details));
    //       toggleReadOnly();
    //     } else {
    //       toast.error("Unbl to update");
    //     }
    //   },
    // });
  };

  return (
    <>
      <section className="content-header">
        <h1>User Profile</h1>
        <ol className="breadcrumb">
          <li>
            <a href="#">
              <i className="fa fa-dashboard" /> Home
            </a>
          </li>
          <li className="active">User profile</li>
        </ol>
      </section>

      <section className="content">
        <div className="row">
          <div className="col-md-3">
            <div className="box box-primary">
              <div className="box-body box-profile">
                <img
                  className="profile-user-img img-responsive img-circle"
                  style={{ height: "150px", width: "150px" }}
                  src={`${process.env.REACT_APP_BASEURL1}${details.image}`}
                  alt="User profile picture"
                />
                <h3 className="profile-username text-center">{details.name}</h3>
                <p className="text-muted text-center">{details.bio}</p>
              </div>
            </div>
            <div className="box box-primary">
              <div className="box-header with-border">
                <h3 className="box-title">About Me</h3>
              </div>
              <div className="box-body">
                <strong>
                  <i className="fa fa-phone margin-r-5" /> Phone Number
                </strong>
                <p className="text-muted">{details.number}</p>
                <hr />
                <strong>
                  <i className="fa fa-envelope margin-r-5" /> Email
                </strong>
                <p className="text-muted">{details.email}</p>
                <hr />
              </div>
            </div>
          </div>
          <div className="col-md-9">
            <div className="nav-tabs-custom">
              <ul className="nav nav-tabs">
                <li className="active">
                  <a href="#settings" data-toggle="tab">
                    Settings
                  </a>
                </li>
                <li>
                  <a href="#changePassword" data-toggle="tab">
                    Change Password
                  </a>
                </li>
              </ul>
              <div className="tab-content">
                <div className="active tab-pane" id="settings">
                  {/* form  */}
                  <form
                    className="form-horizontal"
                    method="post"
                    onSubmit={subbbb}
                    encType="multipart/form-data"
                  >
                    <div className="form-group">
                      <label
                        htmlFor="inputName"
                        className="col-sm-2 control-label"
                      >
                        Name
                      </label>
                      <input
                        type="hidden"
                        defaultValue={details._id}
                        name="id"
                      />
                      <div className="col-sm-10">
                        <input
                          type="text"
                          name="name"
                          defaultValue={details.name}
                          className="form-control"
                          id="inputName"
                          placeholder="Master Panel"
                          readOnly={readOnly}
                        />
                        <div className="form-error1" />
                      </div>
                    </div>
                    <div className="form-group">
                      <label
                        htmlFor="inputEmail"
                        className="col-sm-2 control-label"
                      >
                        Email
                      </label>
                      <div className="col-sm-10">
                        <input
                          type="text"
                          name="email"
                          defaultValue={details.email}
                          className="form-control"
                          id="inputEmail"
                          placeholder="Email"
                          readOnly={readOnly}
                        />
                        <div className="form-error1" />
                      </div>
                    </div>
                    <div className="form-group">
                      <label
                        htmlFor="inputPhone"
                        className="col-sm-2 control-label"
                      >
                        Phone
                      </label>
                      <div className="col-sm-10">
                        <input
                          type="text"
                          name="number"
                          defaultValue={details.number}
                          className="form-control"
                          id="inputPhone"
                          placeholder="Phone"
                          readOnly={readOnly}
                        />
                        <div className="form-error1" />
                      </div>
                    </div>
                    <div className="form-group">
                      <label
                        htmlFor="inputPhone"
                        className="col-sm-2 control-label"
                      >
                        Bio
                      </label>
                      <div className="col-sm-10">
                        <input
                          type="text"
                          name="bio"
                          defaultValue={details.bio}
                          className="form-control"
                          id="inputPhone"
                          placeholder="Phone"
                          readOnly={readOnly}
                        />
                        <div className="form-error1" />
                      </div>
                    </div>
                    <div className="form-group">
                      <label
                        htmlFor="inputImage"
                        className="col-sm-2 control-label"
                      >
                        Image
                      </label>
                      <div className="col-sm-10">
                        <input
                          type="file"
                          name="image"
                          className="form-control"
                          id="inputImage"
                          readOnly={readOnly}
                        />
                        <div className="form-error1" />
                      </div>
                    </div>
                    <div className="form-group">
                      <div className="col-sm-offset-2 col-sm-10">
                        <div>
                        {!readOnly ? "You will be logged out after saved changes" : null}
                        </div>
                        <button
                          type="button"
                          className="btn btn-success"
                          onClick={toggleReadOnly}
                        >
                          {readOnly ? "Edit Profile" : "Cancel"}
                        </button>
                        {!readOnly ? (
                          <button
                            type="submit"
                            className="btn btn-success"
                            style={{ marginLeft: "20px" }}
                              // onClick={() => {
                              //   sessionStorage.removeItem("data");
                              //   window.location.href = "/";
                              // }}
                          >
                            Save Changes
                          </button>
                        ) : (
                          <></>
                        )}
                      </div>
                    </div>
                  </form>
                </div>
                <div className="tab-pane" id="changePassword">
                  <form
                    className="form-horizontal"
                    onSubmit={(e) => {
                      e.preventDefault();
                    }}
                  >
                    <div className="form-group">
                      <label
                        htmlFor="inputOld"
                        className="col-sm-2 control-label"
                      >
                        Old Password
                      </label>
                      <div className="col-sm-10">
                        <input
                          type="password"
                          name="oldPassword"
                          className="form-control"
                          id="currp"
                          placeholder="Old Password"
                        />
                        <div className="form-error1" />
                      </div>
                    </div>
                    <div className="form-group">
                      <label
                        htmlFor="inputNew"
                        className="col-sm-2 control-label"
                      >
                        New Password
                      </label>
                      <div className="col-sm-10">
                        <input
                          type="password"
                          name="newPassword"
                          className="form-control"
                          id="newp"
                          placeholder="New Password"
                        />
                        <div className="form-error1" />
                      </div>
                    </div>
                    <div className="form-group">
                      <label
                        htmlFor="inputNewC"
                        className="col-sm-2 control-label"
                      >
                        Confirm Password
                      </label>
                      <div className="col-sm-10">
                        <input
                          type="password"
                          name="cnewPassword"
                          className="form-control"
                          id="confirmp"
                          placeholder="Confirm Password"
                        />
                        <div className="form-error1" />
                      </div>
                    </div>
                    <div className="form-group">
                      <div className="col-sm-offset-2 col-sm-10">
                        <input
                          type="submit"
                          className="btn btn-success"
                          name="submit"
                          value="Update Profile"
                          onClick={() => {
                            changePassword();
                          }}
                        />
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Profile;
