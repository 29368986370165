import React, { useContext, useEffect, useRef, useState } from "react";
import { Link, useSearchParams } from "react-router-dom";
import { global } from "../../Context/Context";
import axios from "axios";
import { toast } from "react-toastify";
import $ from "jquery";
import axiosInstance from "../../config/axiosInterceptor";

const ViewUser = () => {
  const baseUrl = process.env.REACT_APP_BASEURL1;
  const baseUrl2 = process.env.REACT_APP_BASEURL2;
  const baseUrl5 = process.env.REACT_APP_BASEURL5;
  const [userData, setUserData] = useState("");
  const [coinH, setCoinH] = useState([]);
  // const [p,setP] = useState(1)
  // let p = 1;
  const [userDetails, setUserDetails] = useState({});
  const [sign, setSign] = useState({ plus: true, minus: false });
  const [tag, setTag] = useState([]);
  const [Bd, setBd] = useState("");
  const [type, setType] = useState(1);
  const { viewTagg, viewTag, viewadminframes, viewadminentryEffects } =
    useContext(global);

  const [readOnly, setReadOnly] = useState(true);
  const [searchParams] = useSearchParams();
  const id = searchParams.get("id");
  const [isLoading, setIsLoading] = useState();
  const prevIdRef = useRef();
  const prevBaseUrlRef = useRef();
  const isMountedRef = useRef(true);
  const [p, setP] = useState(1);
  const [imageFile, setImageFile] = useState(null); // New state for image
  const [broadBanType, setBroadBanType] = useState(null);

  const toggleReadOnly = () => {
    setReadOnly(!readOnly);
    setUserDetails({
      name: userData?.name,
      gender: userData?.gender,
      bd: userData?.bd,
      agency: userData?.agency,
      agencyAdmin: userData?.agencyAdmin,
      bio: userData?.bio,
      coinTime: userData?.coinTime,
      coinAmount: userData?.coinAmount
    });
    // console.log(userDetails)
  };

  const handleFormSubmit = async (e) => {
    e.preventDefault();
    try {
      const formData = new FormData();
      formData.append("userDetails", JSON.stringify(userDetails)); // Add user details
      if (imageFile) {
        formData.append("profileImage", imageFile); // Add image file if present
      }

      const response = await axiosInstance.put(
        `${process.env.REACT_APP_BASEURL3}/admin/edituser?id=${id}`,
        formData,
        {
          headers: { "Content-Type": "multipart/form-data" }, // Important for file upload
        }
      );

      if (response.data.success == "1") {
        fetchData();
        toast.success(response.data.message);
        toggleReadOnly();
      } else {
        fetchData();
        toast.error(response.data.message);
      }
    } catch (error) {
      console.error("Error updating user data:", error);
      toast.error(error.message);
    }
  };
  const handlechange = (e) => {
    // console.log(e.target.value)
    setUserDetails({ ...userDetails, [e.target.name]: e.target.value });
    // console.log(userDetails)
  };
  // console.log(userDetails)

  const settag = async (tag) => {
    if (tag !== null) {
      try {
        let { data } = await axiosInstance.post(`${baseUrl}/admin/usertag?id=${id}`, { myTag: tag });
        if (data.success == "1") {
          toast.success("Tag updated")
        }
      } catch (error) {
        toast.error(error.message)
        console.error("Error updating user tag:", error);
      }
    } else {
      console.error("Tag value is null, skipping update");
    }
  };
  const handleImageChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      setImageFile(file);
      setUserData({ ...userData, picture: URL.createObjectURL(file) }); // Preview the image
    }
  };
  const updateUserBanStatus = async (banStatusValue) => {
    try {
      const val = { ...userData, status: banStatusValue };
      const d = await axiosInstance.post(
        `${process.env.REACT_APP_BASEURL3}/admin/userban?id=${id}&banStatus=${banStatusValue}`,
        val
      );
      if (d.data.success == "1") {
        toast.success("Successfully Done");
      }
    } catch (error) {
      toast.error(error.message)
      console.error("Error updating user ban status:", error);
    }
  };
  const updateGameBanStatus = async (status)=>{
    console.log(status)
    try {
      const val = {...userData , gameStatus:status};
      await axiosInstance.post(`${process.env.REACT_APP_BASEURL5}/admin/gameUserBan?id=${id}&status=${status}`,val).then((res)=>{
        if(res.data.success == 1){
          toast.success(res.data.message)
        }else{
          toast.error(res.data.message)
        }
      })
    } catch (error) {
      toast.error(error.message)
    }
  }

  const updateGameDeviceBanStatus = async (status)=>{
    await axiosInstance.post(`${process.env.REACT_APP_BASEURL5}/admin/deviceGameBan?id=${id}&status=${status}`,userData).then((res)=>{
      if(res.data.success == 1){
        toast.success(res.data.message)
      }else{
        toast.error(res.data.message)
      }
    }).catch((error)=>{
      toast.error(error.message)
    })
  }

  const updateUserBanDeviceStatus = async (banStatusValue) => {
    try {
      const val = { ...userData, banStatus: banStatusValue };
      const d = await axiosInstance.post(
        `${process.env.REACT_APP_BASEURL3}/admin/userbanDeviceId?id=${id}&status=${banStatusValue}`,
        val
      );
      if (d.data.success == "1") {
        toast.success(d.data.message);
      } else {
        toast.error(d.data.message);
      }
    } catch (error) {
      toast.error(error.message)
      console.error("Error updating user ban status:", error);
    }
  };
  // bd and agencyname's api
  const bdname = async () => {
    try {
      const response = await axios.get(`${baseUrl}/admin/host/findbd?id=${id}`);
    } catch (error) {
      console.error("Error fetch:", error);
    }
  };
  const CoinsTransaction = async (pg, type) => {
    try {
      setCoinH("");
      // setP(1)
      setSign({ plus: "-", minus: "+" });
      // const data1 = await axios.get(`${baseUrl}/admin/view_user?id=${id}`);
      const data1 = await axios.get(
        `${baseUrl2}/admin/coinHistory?id=${id}&page=${pg}&type=${type}`
      );
      if (data1.data.success === 1) {
        setCoinH(data1.data.details);
      }
    } catch (error) {
      console.error("Error fetching user data:", error);
    }
  };
  const RecCoinsTransaction = async (pg, type) => {
    try {
      setCoinH("");
      // setP(1)
      setSign({ plus: "+", minus: "-" });

      // const data1 = await axios.get(`${baseUrl}/admin/view_user?id=${id}`);
      const data1 = await axios.get(
        `${baseUrl2}/admin/recCoinHistory?id=${id}&page=${pg}&type=${type}`
      );
      if (data1.data.success === 1) {
        setCoinH(data1.data.details);
      }
    } catch (error) {
      console.error("Error fetching user data:", error);
    }
  };
  const fetchData = async () => {
    try {
      // const data1 = await axios.get(`${baseUrl}/admin/view_user?id=${id}`);
      const data1 = await axios.get(
        `${process.env.REACT_APP_BASEURL3}/admin/view_user?id=${id}`
      );
      if (isMountedRef.current && data1.data.success === 1) {
        setUserData(data1.data.details);
        setUserDetails(data1.data.details);
        toast.success(data1.data.message)
        // console.log(data1.data)
        // Set the ban status of the user
        const banStatusValue = data1.data.details.banStatus;
        $("#o-tagggg").val(banStatusValue);
      }
    } catch (error) {
      toast.error(error.message)
      console.error("Error fetching user data:", error);
    }
  };

  const broadBanUnban = async (userId, type) => {
    try {

      const { data } = await axiosInstance.post(
        `${baseUrl5}/admin/broadBanUnban?userId=${userId}&type=${type}`
      );
      if (data.success == 1) {
        toast.success(data.message);
      } else {
        toast.error(data.message);
      }
    } catch (error) {
      toast.error(error.message)

    }
  };
  useEffect(() => {
    // Create a ref to track mount status

    fetchData();
    // CoinsTransaction()

    // Cleanup function, executed when component unmounts
    return () => {
      isMountedRef.current = false; // Set isMounted to false when unmounting
    };
  }, []); // Include id and baseUrl in the dependency array

  useEffect(() => {
    // bdname()
    viewTagg();
    viewadminframes();
    viewadminentryEffects();
  }, []);

  const createdAtDate = userData?.createdAt
    ? new Date(userData.createdAt)
    : null;
  const formattedDate = createdAtDate
    ? createdAtDate.toISOString().split("T")[0]
    : "";
  // console.log(userData)
  return (
    <>
      {userData == "" ? (
        <>Loading</>
      ) : (
        <>
          <section className="content-header">
            <h1>View User</h1>
            <ol className="breadcrumb">
              <li>
                <Link to="/admin">
                  <i className="fa fa-dashboard"></i> Home
                </Link>
              </li>
              <li>
                <Link to="/admin/manageUser">
                  <i className="fa fa-user"></i> User
                </Link>
              </li>
              <li className="active">View User</li>
            </ol>
          </section>
          <section className="content">
            <div
              className="row"
              style={{ display: "flex", justifyContent: "center" }}
            >
              <div className="col-md-10 col-12">
                <form
                  role="form"
                  method="post"
                  id="specialitiesForm"
                  encType="multipart/form-data"
                // onSubmit={() => {
                // console.log(userDetails)
                // handleFormSubmit()
                // }}
                >
                  <div className="box box-warning">
                    <div
                      className="box-header with-border"
                      style={{ display: "flex" }}
                    >
                      <div style={{ position: "relative" }}>
                        <img
                          src={`${baseUrl}${userData?.picture}`}
                          style={{
                            width: "80px",
                            height: "80px",
                            borderRadius: "50%",
                          }}
                          id="pro_img"
                        />
                        <input
                          type="file"
                          id="image-upload"
                          style={{ display: "none" }}
                          accept="image/*"
                          onChange={handleImageChange}
                        />
                        <button
                          type="button"
                          onClick={() =>
                            document.getElementById("image-upload").click()
                          }
                          style={{
                            position: "absolute",
                            top: "-5px",
                            right: "-5px",
                            background: "transparent",
                            border: "none",
                          }}
                        >
                          <i className="fa-solid fa-pen-to-square" />
                        </button>
                      </div>
                      <div>
                        <div style={{ marginLeft: "15px", fontSize: "20px" }}>
                          {userData?.username}{" "}
                          <span
                            className=" label label-warning"
                            style={{ fontSize: "10px" }}
                            aria-label="user type"
                          >
                            Normal User
                          </span>
                        </div>
                        <div style={{ marginLeft: "15px", fontSize: "20px" }}>
                          {userData.bio != "" ? <>{userData.bio}</> : <>{ }</>}
                        </div>
                        <div
                          style={{
                            borderRadius: "4px",
                            padding: "3px",
                            width: "5.6rem",
                            marginRight: "15px",
                            fontSize: "15px",
                            backgroundColor:
                              userData.banStatus == 1 ? "red" : "",
                          }}
                        >
                          {userData.banStatus == 0 ? "" : "Banned"}
                        </div>
                      </div>
                    </div>
                    <div className="box-body">
                      <div className="col-sm-6">
                        <div className="form-group">
                          <label>Username*</label>
                          <input
                            type="text"
                            name="username"
                            className="form-control"
                            defaultValue={userData?.username}
                            onChange={handlechange}
                            readOnly={readOnly}
                          />
                        </div>
                      </div>
                      <div className="col-sm-6">
                        <div className="form-group">
                          <label>Name*</label>
                          <input
                            type="text"
                            name="name"
                            className="form-control"
                            defaultValue={userData?.name}
                            onChange={handlechange}
                            readOnly={readOnly}
                          />
                        </div>
                      </div>
                      <div className="col-sm-6">
                        <div className="form-group">
                          <label>Bio*</label>
                          <input
                            type="text"
                            name="bio"
                            className="form-control"
                            defaultValue={userData?.bio}
                            onChange={handlechange}
                            readOnly={readOnly}
                          />
                        </div>
                      </div>
                      <div className="col-sm-6">
                        <div className="form-group">
                          <label>Gender*</label>
                          <input
                            type="text"
                            name="gender"
                            className="form-control"
                            defaultValue={userData?.gender}
                            onChange={handlechange}
                            readOnly={readOnly}
                          />
                        </div>
                      </div>
                      <div className="col-sm-6">
                        <div className="form-group">
                          <label>Number*</label>
                          <input
                            type="text"
                            className="form-control"
                            name="number"
                            defaultValue={userData?.number}
                            onChange={handlechange}
                            readOnly={readOnly}
                          />
                        </div>
                      </div>
                      <div className="col-sm-6">
                        <div className="form-group">
                          <label>Level*</label>
                          <input
                            type="text"
                            className="form-control"
                            name="level"
                            defaultValue={userData?.level}
                            onChange={handlechange}
                            readOnly={readOnly}
                          />
                        </div>
                      </div>
                      <div className="col-sm-6">
                        <div className="form-group">
                          <label>Stars*</label>
                          <input
                            type="text"
                            className="form-control"
                            name="coin"
                            defaultValue={userData?.coin}
                            onChange={handlechange}
                            readOnly={readOnly}
                          />
                        </div>
                      </div>
                      <div className="col-sm-6">
                        <div className="form-group">
                          <label>M-Coins*</label>
                          <input
                            type="number"
                            className="form-control"
                            name="recievingCoins"
                            defaultValue={userData?.recievingCoins}
                            onChange={handlechange}
                            readOnly={readOnly}
                          />
                        </div>
                      </div>
                      <div className="col-sm-6">
                        <div className="form-group">
                          <label>Coin Time in minutes</label>
                          <input
                            type="number"
                            className="form-control"
                            name="coinTime"
                            defaultValue={userData?.coinTime}
                            onChange={handlechange}
                            readOnly={readOnly}
                          />
                        </div>
                      </div>
                      <div className="col-sm-6">
                        <div className="form-group">
                          <label>Coin Amount</label>
                          <input
                            type="number"
                            className="form-control"
                            name="coinAmount"
                            defaultValue={userData?.coinAmount}
                            onChange={handlechange}
                            readOnly={readOnly}
                          />
                        </div>
                      </div>
                      <div className="col-sm-6">
                        <div className="form-group">
                          <label>Email*</label>
                          <input
                            type="email"
                            className="form-control"
                            name="email"
                            defaultValue={userData?.email}
                            onChange={handlechange}
                            readOnly
                          />
                        </div>
                      </div>
                      <div className="col-sm-6">
                        <div className="form-group">
                          <label>Join Date*</label>
                          <input
                            type="text"
                            className="form-control"
                            defaultValue={formattedDate}
                            onChange={handlechange}
                            readOnly
                          />
                        </div>
                      </div>
                      <div className="col-sm-6">
                        <div className="form-group">
                          <label>Videos*</label>
                          <input
                            type="text"
                            className="form-control"
                            defaultValue={userData?.videos}
                            // onChange={handlechange}
                            readOnly
                          />
                        </div>
                      </div>
                      <div className="col-sm-6">
                        <div className="form-group">
                          <label>Password</label>
                          <input
                            type="text"
                            name="password"
                            className="form-control"
                            defaultValue={userData?.password}
                            onChange={handlechange}
                            readOnly={readOnly}
                          />
                        </div>
                      </div>
                      <div className="col-sm-6">
                        <div className="form-group">
                          <label>BD Code</label>
                          <input
                            type="text"
                            name="bd"
                            id="bd"
                            className="form-control"
                            defaultValue={userData?.bd}
                            onChange={handlechange}
                            readOnly={readOnly}
                          />
                        </div>
                      </div>
                      <div className="col-sm-6">
                        <div className="form-group">
                          <label>BD Name</label>
                          <input
                            type="text"
                            name="bdname"
                            id="bdname"
                            className="form-control"
                            defaultValue={userData?.bdName}
                            onChange={handlechange}
                            readOnly
                          />
                        </div>
                      </div>
                      <div className="col-sm-6">
                        <div className="form-group">
                          <label>AgencyAdmin Code</label>
                          <input
                            type="text"
                            id="agencyadmin"
                            name="agencyAdmin"
                            className="form-control"
                            defaultValue={userData?.agencyAdmin}
                            onChange={handlechange}
                            readOnly={readOnly}
                          />
                        </div>
                      </div>
                      <div className="col-sm-6">
                        <div className="form-group">
                          <label>AgencyAdmin Name</label>
                          <input
                            type="text"
                            name="aaname"
                            id="aaname"
                            className="form-control"
                            defaultValue={userData?.agencyAdminName}
                            onChange={handlechange}
                            readOnly
                          />
                        </div>
                      </div>
                      <div className="col-sm-6">
                        <div className="form-group">
                          <label>Agency Code</label>
                          <input
                            type="text"
                            id="agency"
                            name="agency"
                            className="form-control"
                            defaultValue={userData?.agency}
                            onChange={handlechange}
                            readOnly={readOnly}
                          />
                        </div>
                      </div>
                      <div className="col-sm-6">
                        <div className="form-group">
                          <label>Agency Name</label>
                          <input
                            type="text"
                            id="agencyname"
                            name="agencyname"
                            className="form-control"
                            defaultValue={userData?.agencyName}
                            onChange={handlechange}
                            readOnly
                          />
                        </div>
                      </div>
                      <div className="col-sm-3">
                        <div className="form-group">
                          <label>Followers*</label>
                          <input
                            type="text"
                            className="form-control"
                            defaultValue={userData?.following}
                            // onChange={handlechange}
                            readOnly
                          />
                        </div>
                      </div>
                      <div className="col-sm-3">
                        <div className="form-group">
                          <label>Following*</label>
                          <input
                            type="text"
                            className="form-control"
                            defaultValue={userData?.follow}
                            // onChange={handlechange}
                            readOnly
                          />
                        </div>
                      </div>
                      {/* Official Tag */}
                      <div className="col-md-6">
                        <div className="form-group">
                          <label
                            htmlFor="o-tag"
                            style={{ color: "#ef5d84", fontSize: "15px" }}
                          >
                            Official Tags
                          </label>
                          <select
                            className="form-control"
                            name="status"
                            id="o-tag"
                            style={{ border: "1px solid #ef5d84" }}
                            onChange={(e) => {
                              const selectedTagId = e.target.value;
                              setTag(selectedTagId);
                              settag(selectedTagId);
                            }}
                          >
                            <option disabled selected>
                              Select Tag
                            </option>
                            {Array.isArray(viewTag) &&
                              viewTag.map((item) => {
                                return (
                                  <option value={item._id}>{item.title}</option>
                                );
                              })}
                          </select>
                        </div>
                      </div>
                      {/* UserId(Ban/ Unban) */}
                      <div className="col-md-6">
                        <div className="form-group">
                          <label
                            htmlFor="o-tagggg"
                            style={{ color: "#ef5d84", fontSize: "15px" }}
                          >
                            UserId(Ban/ Unban)
                          </label>
                          <select
                            className="form-control"
                            name="status"
                            id="o-tagggg"
                            style={{ border: "1px solid #ef5d84" }}
                            onChange={() => {
                              const selectedBanStatus = $("#o-tagggg").val();
                              updateUserBanStatus(selectedBanStatus);

                              // setBanStatus(selectedBanStatus);
                            }}
                          >
                            <option disabled>UserId(Ban/ Unban)</option>
                            <option
                              value={0}
                              selected={
                                userDetails?.banStatus === "0" ||
                                userDetails?.banStatus === ""
                              }
                            >
                              unbanned
                            </option>
                            <option
                              value={1}
                              selected={userDetails?.banStatus === "1"}
                            >
                              banned
                            </option>
                          </select>
                        </div>
                      </div>
                      {/* Broad ban  */}
                      <div
                        className="col-md-6"
                      // onClick={() => {
                      //   alert("This features is on under process");
                      // }}
                      >
                        <div className="form-group">
                          <label
                            htmlFor="o-tagggg"
                            style={{ color: "#ef5d84", fontSize: "15px" }}
                          >
                            Broad(Ban/ Unban)
                          </label>
                          <select
                            className="form-control"
                            name="status"
                            id="o-tagggg"
                            style={{ border: "1px solid #ef5d84" }}
                            onChange={(e) => {
                              const selectedBanStatus = $("#o-tagggg").val();
                              // console.log();
                              // updateUserBanStatus(selectedBanStatus);

                              // setBanStatus(selectedBanStatus);
                              let typeS = e.target.value;
                              broadBanUnban(id, typeS);
                            }}
                          >
                            <option
                              disabled
                              selected={userDetails?.banStatus === ""}
                            >
                              Broad (Ban/Unban)
                            </option>
                            <option
                              value={0}
                              selected={
                                userDetails?.isBroadBan === "0" ||
                                userDetails?.isBroadBan === ""
                              }
                            >
                              Broad Unbanned
                            </option>
                            <option
                              value={1}
                              selected={userDetails?.isBroadBan === "1"}
                            >
                              Broad Banned
                            </option>
                          </select>
                        </div>
                      </div>
                      {/* Broad ban end  */}
                      {/* Device Id(Ban/ Unban) */}
                      <div className="col-md-6">
                        <div className="form-group">
                          <label
                            htmlFor="o-tag"
                            style={{ color: "#ef5d84", fontSize: "15px" }}
                          >
                            Device Id(Ban/ Unban)
                          </label>
                          <select
                            className="form-control"
                            name="status"
                            id="o-tagdiv"
                            style={{ border: "1px solid #ef5d84" }}
                            onChange={() => {
                              const selectedBanStatus1 = $("#o-tagdiv").val();
                              updateUserBanDeviceStatus(selectedBanStatus1);
                            }}
                          >
                            <option value={"1"}>Device Id Banned</option>
                            <option value={"0"} selected>
                              Device Id Not Banned
                            </option>
                          </select>
                        </div>
                      </div>

                      {/* game user id ban */}
                      <div className="col-md-6">
                        <div className="form-group">
                          <label
                            htmlFor="o-tagggg"
                            style={{ color: "#ef5d84", fontSize: "15px" }}
                          >
                            Game UserId(Ban/ Unban)
                          </label>
                          <select
                            className="form-control"
                            name="gameStatus"
                            id="o-tagggg"
                            style={{ border: "1px solid #ef5d84" }}
                            onChange={(e) => {
                              const selectedBanStatus = e.target.value;
                              updateGameBanStatus(selectedBanStatus);
                            }}
                          >
                            <option
                              value={0}
                              selected={
                                userDetails?.gameBanStatus === "0" ||
                                userDetails?.gameBanStatus === ""
                              }
                            >
                              unbanned
                            </option>
                            <option
                              value={1}
                              selected={userDetails?.gameBanStatus === "1"}
                            >
                              banned
                            </option>
                          </select>
                        </div>
                      </div>

                      {/* Game device id bann */}
                      <div className="col-md-6">
                        <div className="form-group">
                          <label
                            htmlFor="o-tag"
                            style={{ color: "#ef5d84", fontSize: "15px" }}
                          >
                            Game Device Id(Ban/ Unban)
                          </label>
                          <select
                            className="form-control"
                            name="GameDeviceStatus"
                            id="o-tagdiv"
                            style={{ border: "1px solid #ef5d84" }}
                            onChange={(e) => {
                              const selectedBanStatus1 = e.target.value;
                              updateGameDeviceBanStatus(selectedBanStatus1);
                            }}
                          >
                            <option value={"1"}>Device Id Banned</option>
                            <option value={"0"}>
                              Device Id Not Banned
                            </option>
                          </select>
                        </div>
                      </div>

                    </div>
                  </div>
                  <div className="form-group">
                    <div className="col-sm-offset-2 col-sm-10">
                      <button
                        type="button"
                        className="btn btn-success"
                        onClick={toggleReadOnly}
                      >
                        {readOnly ? "Edit Profile" : "Cancel"}
                      </button>
                      {!readOnly ? (
                        <button
                          type="submit"
                          className="btn btn-success"
                          style={{ marginLeft: "20px" }}
                          onClick={(e) => {
                            toggleReadOnly();
                            handleFormSubmit(e);
                          }}
                        >
                          Save Changes
                        </button>
                      ) : (
                        <></>
                      )}
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </section>
          <>
            <div className="d-flex" style={{ marginTop: "50px" }}>
              <button
                type="button"
                className="btn btn-success"
                onClick={() => {
                  setType(1)
                  RecCoinsTransaction(1, 1);
                }}
                style={{ marginLeft: "50px" }}
              >
                Star History
              </button>
              <button
                type="button"
                className="btn btn-success"
                onClick={() => {
                  setType(1)
                  CoinsTransaction(1, 1);
                }}
                style={{ marginLeft: "20px" }}
              >
                M-Coins History
              </button>
              <button
                type="button"
                className="btn btn-success"
                onClick={() => {
                  setType(0)
                  RecCoinsTransaction(1, 0);
                }}
                style={{ marginLeft: "50px" }}
              >
                Recharge
              </button>
              <button
                type="button"
                className="btn btn-success"
                onClick={() => {
                  setType(0)
                  CoinsTransaction(1, 0);
                }}
                style={{ marginLeft: "20px" }}
              >
                Withdrawal
              </button>
            </div>
            <div className="container ">
              <section className="content">
                <div
                  className="row"
                  style={{ display: "flex", justifyContent: "center" }}
                >
                  <div className="col-md-10 col-12">
                    <table className="table table-bordered table-hover">
                      <thead>
                        <tr>
                          <th>Sr.</th>
                          <th>Amount</th>
                          <th>Other</th>
                          <th>Available Balance</th>
                          <th>Date</th>
                        </tr>
                      </thead>
                      {coinH.length == 0 ? (
                        <>No Data</>
                      ) : (
                        <tbody>
                          {coinH.map((item, index) => (
                            <tr key={index}>
                              <td>{index + 1}</td>
                              <td>
                                {item.type == "0" ? (
                                  <>
                                    {sign.plus}
                                    {item.amount}
                                  </>
                                ) : (
                                  <>
                                    {sign.minus}
                                    {item.amount}
                                  </>
                                )}
                              </td>

                              <td>{item.other}</td>
                              <td>{item.availabeBalance}</td>
                              <td>{item.createdAt}</td>
                            </tr>
                          ))}
                        </tbody>
                      )}
                    </table>
                  </div>
                </div>
                <div
                  className="container"
                  style={{ display: "flex", justifyContent: "flex-end" }}
                >
                  <div className="col-md-10 col-12">
                    {/* <button>Prev</button> */}
                  </div>
                  <div className="col-md-10 col-12">
                    <button
                      onClick={async () => {
                        if (sign.plus == "-") {
                          setP(p - 1);
                          await CoinsTransaction(p - 1, type);
                        } else {
                          setP(p - 1);
                          await RecCoinsTransaction(p - 1, type);
                        }
                      }}
                    >
                      Prev
                    </button>
                  </div>
                  <div className="col-md-10 col-12">
                    <button
                      onClick={async () => {
                        if (sign.plus == "-") {
                          setP(p + 1);
                          await CoinsTransaction(p + 1, type);
                        } else {
                          setP(p + 1);
                          await RecCoinsTransaction(p + 1, type);
                        }
                      }}
                    >
                      Next
                    </button>
                  </div>
                </div>
              </section>
            </div>
          </>
        </>
      )}
    </>
  );
};

export default ViewUser;
