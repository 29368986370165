import React, { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import axios from "axios";
import { toast } from "react-toastify";

const EditAgencyAdmin = () => {
  const [agencyadmindata, setAgencyAdmindata] = useState({
    name: "",
    email: "",
    number: "",
    password: "",
    image: "",
  });

  const baseUrl = process.env.REACT_APP_BASEURL1;
  const location = useLocation();
  const userId = location.search.split("?")[1];

  const edit = async (id) => {
    try {
      const { data } = await axios.get(
        `${baseUrl}/agencadminpanel/getone?${id}`
      );
      setAgencyAdmindata(data.data);
      toast.success(data.message);
    } catch (error) {
      console.error("Error edit Agency Admin:", error);
      toast.error("Failed to edit Agency Admin");
    }
  };

  useEffect(() => {
    edit(userId);
  }, []);

  const handleChange = (e) => {
    if (e.target.name === "image") {
      setAgencyAdmindata((prevState) => ({
        ...prevState,
        image: e.target.files[0],
      }));
    } else {
      const { name, value } = e.target;
      setAgencyAdmindata((prevState) => ({
        ...prevState,
        [name]: value,
      }));
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const formData = new FormData();
      formData.append("name", agencyadmindata.name);
      formData.append("email", agencyadmindata.email);
      formData.append("number", agencyadmindata.number);
      formData.append("password", agencyadmindata.password);
      formData.append("image", agencyadmindata.image);

      const editdata = await axios.put(
        `${baseUrl}/agencadminpanel/updateagencyadminForBd?id=${agencyadmindata._id}`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );
      console.log(editdata);
      toast.success("Agency Admin details updated successfully");
    } catch (error) {
      console.log(error);
      toast.error("Agency Admin details update failed");
    }
  };
  return (
    <>
      <section className="content-header">
        <h1>Edit Bd</h1>
        <ol className="breadcrumb">
          <li>
            <Link to="/admin">
              <i className="fa fa-dashboard"></i> Home
            </Link>
          </li>
          <li>
            <Link to="/bd/manageagencyadmin">Manage Agency Admin</Link>
          </li>
          <li className="active">Edit Agency Admin</li>
        </ol>
      </section>
      <section className="content">
        <div className="row">
          <div className="col-md-10">
            <form
              role="form"
              method="post"
              id="form"
              onSubmit={handleSubmit}
              encType="multipart/form-data"
            >
              <div className="box box-warning">
                <div className="box-header with-border">
                  <h3 className="box-title">Edit</h3>
                </div>
                <div className="box-body">
                  <input type="hidden" name="id" value={agencyadmindata._id} />
                  <div className="form-group">
                    <label>Agency Admin Name*</label>
                    <input
                      type="text"
                      className="form-control"
                      name="name"
                      placeholder="Bd Name"
                      value={agencyadmindata.name}
                      onChange={handleChange}
                    />
                    <div className="form-error1" />
                  </div>
                  <div className="form-group">
                    <label>Agency Admin Email*</label>
                    <input
                      type="text"
                      className="form-control"
                      name="email"
                      placeholder="Bd Email"
                      value={agencyadmindata.email}
                      onChange={handleChange}
                    />
                    <div className="form-error1" />
                  </div>
                  <div className="form-group">
                    <label>Agency Admin Mobile*</label>
                    <input
                      type="text"
                      className="form-control"
                      name="number"
                      placeholder="Bd Mobile"
                      value={agencyadmindata.number}
                      onChange={handleChange}
                    />
                    <div className="form-error1" />
                  </div>
                  <div className="form-group">
                    <label>Agency Admin Password*</label>
                    <input
                      type="text"
                      className="form-control"
                      name="password"
                      placeholder="Bd Password"
                      value={agencyadmindata.password}
                      onChange={handleChange}
                    />
                    <div className="form-error1" />
                  </div>
                  <div className="form-group">
                    <label>Agency Admin Image*</label>
                    <input
                      type="file"
                      className="form-control"
                      name="image"
                      onChange={handleChange}
                    />
                    <div className="form-error1" />
                  </div>
                  <div className="form-group">
                    <label>Old Icon*</label>
                    <br />

                    <img
                      src={`${baseUrl}${agencyadmindata.image}`}
                      alt=""
                      height={"80px"}
                    />
                  </div>
                  <div className="form-group">
                    <button type="reset" className="btn btn-danger">
                      Cancel
                    </button>
                    <input
                      type="submit"
                      className="btn btn-success pull-right"
                      name="submit"
                      value="Submit"
                    />
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </section>
    </>
  );
};

export default EditAgencyAdmin;
