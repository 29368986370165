import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import axios from "axios";
import { toast } from "react-toastify";
import axiosInstance from "../../config/axiosInterceptor";

const AddFrameLevel = () => {
  const navigate = useNavigate();
  const [level, setLevel] = useState("");
  const [image, setImage] = useState(null);
  const [image1, setImage1] = useState(null);

  const subbbb = async (e) => {
    e.preventDefault();

    try {
      const formData = new FormData();
      formData.append("level", level);
      formData.append("image", image);
      formData.append("svga", image1);

      const response = await axiosInstance.post( // niru 27/2
        `${process.env.REACT_APP_BASEURL5}/admin/frame/addframelevel`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );

      if (response.data.succes == 1) {
        toast.success(response.data.message);
        setTimeout(() => {
          navigate("/admin/manageLevel");
        }, 1000);
      } else {
        toast.error(response.data.message);
      }
    } catch (error) {
      toast.error(error.message);
    }
  };

  const handleImageChange = (e) => {
    setImage(e.target.files[0]); // Update image state when a new image is selected
  };

  const handleImageChange1 = (e) => {
    setImage1(e.target.files[0]); // Update image state when a new image is selected
  };

  return (
    <>
      <section className="content-header">
        <h1 style={{ marginBottom: "20px" }}>Add Level</h1>
        <ol className="breadcrumb" style={{ marginBottom: "20px" }}>
          <li>
            <Link to="/admin">
              <i className="fa fa-dashboard"></i> Home
            </Link>
          </li>
          <li>
            <Link to="/admin/manageLevel">Manage Level</Link>
          </li>
          <li className="active">Add Frame Level</li>
        </ol>
      </section>
      <section className="content" style={{ padding: "0 15px" }}>
        <div className="row">
          <div className="col-md-12">
            <form
              role="form"
              method="post"
              onSubmit={subbbb}
              encType="multipart/form-data"
            >
              <div className="box box-warning">
                <div className="box-header with-border">
                  <h3 className="box-title">Add</h3>
                </div>
                <div className="box-body">
                  <div className="form-group" style={{ marginBottom: "20px" }}>
                    <label style={{ marginBottom: "10px" }}>Level*</label>
                    <input
                      type="number"
                      className="form-control"
                      name="level"
                      placeholder="level eg.(1 , 2 , 3...)"
                      style={{ width: "100%" }}
                      value={level}
                      onChange={(e) => setLevel(e.target.value)}
                    />
                  </div>
                  <div className="form-group" style={{ marginBottom: "20px" }}>
                    <label style={{ marginBottom: "10px" }}>Image*</label>
                    <input
                      accept="image/*"
                      type="file"
                      className="form-control"
                      name="image"
                      style={{ width: "100%" }}
                      onChange={handleImageChange} // Call handleImageChange when a new image is selected
                    />
                  </div>
                  <div className="form-group" style={{ marginBottom: "20px" }}>
                    <label style={{ marginBottom: "10px" }}>Svga*</label>
                    <input
                      type="file"
                      className="form-control"
                      name="svga"
                      style={{ width: "100%" }}
                      onChange={handleImageChange1} // Call handleImageChange when a new image is selected
                    />
                  </div>
                  <div className="form-group" style={{ marginBottom: "20px" }}>
                    <button
                      type="reset"
                      className="btn btn-danger"
                      style={{ marginRight: "10px" }}
                    >
                      Cancel
                    </button>
                    <input
                      type="submit"
                      className="btn btn-success pull-right"
                      name="submit"
                      defaultValue="Submit"
                    />
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </section>
    </>
  );
};

export default AddFrameLevel;
