import React , { useContext, useEffect } from 'react'
import { Link ,useNavigate } from "react-router-dom";
import $ from 'jquery';
import {toast} from 'react-toastify'
import { global } from '../../Context/Context';
import axiosInstance from '../../config/axiosInterceptor';

const EditCoin = () => {


    const { fetchcoinPanel, fetchCoinPanel } = useContext(global)

    useEffect(() => {
        const queryString = window.location.search;
        const params = new URLSearchParams(queryString);
        const userId = params.get('id');

        $('#form')[0].reset(); // Reset the form using jQuery
        fetchcoinPanel(userId);
    }, []);



    const navigate = useNavigate();
    const subbbb = async(e) => {
        e.preventDefault();
        let formData = new FormData(e.target); // Use e.target to access the form element
        await axiosInstance.post(`${process.env.REACT_APP_BASEURL1}/admin/coinPanel/updateCoinPanel`, formData, {
            headers: {
                "Content-Type": "multipart/form-data",
            },
        })
        .then((res) => {
            if (res.data.success === 1) {
                toast.success(res.data.message);
                setTimeout(() => {
                    navigate('/admin/manageCoin');
                }, 1000);
            } else {
                toast.error(res.data.message);
            }
        })
        .catch((error) => {
            toast.error(error.message);
            console.error(error);
        });
    }

    return (
        <>
            <section className="content-header">
                <h1>Edit Coin Agency</h1>
                <ol className="breadcrumb">
                    <li><Link to="/admin"><i className="fa fa-dashboard"></i> Home</Link></li>
                    <li><Link to="/admin/manageCoin">Manage Agency Coin</Link></li>
                    <li className="active">Edit Coin Agency</li>
                </ol>
            </section>
            <section className="content">
                <div className="row">
                    <div className="col-md-10">
                        <form role="form" method="post"  id="form" onSubmit={subbbb} encType="multipart/form-data">
                            <div className="box box-warning">
                                <div className="box-header with-border">
                                    <h3 className="box-title">Edit</h3>
                                </div>
                                <div className="box-body">
                                <input type="hidden" name="id" defaultValue={fetchCoinPanel._id} />
                                    <div className="form-group">
                                        <label>Coin Agency Name*</label>
                                        <input type="text" className="form-control" name="name" placeholder="Coin Agency Name" defaultValue={fetchCoinPanel.name}/>
                                        <div className="form-error1" />
                                    </div>
                                    <div className="form-group">
                                        <label>Coin Agency Email*</label>
                                        <input type="text" className="form-control" name="email" placeholder="Coin Agency Email" defaultValue={fetchCoinPanel.email}/>
                                        <div className="form-error1" />
                                    </div>
                                    <div className="form-group">
                                        <label>Coin Agency Mobile*</label>
                                        <input type="text" className="form-control" name="number" placeholder="Coin Agency Mobile" defaultValue={fetchCoinPanel.number}/>
                                        <div className="form-error1" />
                                    </div>
                                    <div className="form-group">
                                        <label>Coin Agency Password*</label>
                                        <input type="text" className="form-control" name="password" placeholder="Coin Agency Password" defaultValue={fetchCoinPanel.password}/>
                                        <div className="form-error1" />
                                    </div>
                                    <div className="form-group">
                                        <label>Coin Agency Image*</label>
                                        <input type="file" className="form-control" name="image" />
                                        <div className="form-error1" />
                                    </div>
                                    <div className="form-group">
                                        <label>Old Icon*</label><br />
                                        <img src={fetchCoinPanel.image} alt="" height={"80px"}/>
                                    </div>
                                    {/* Repeat other form fields as needed */}
                                    <div className="form-group">
                                        <button type="reset" className="btn btn-danger">Cancel</button>
                                        <input type="submit" className="btn btn-success pull-right" name="submit" defaultValue="Submit" />
                                    </div>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </section>
        </>
    )
}

export default EditCoin